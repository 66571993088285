import { clientApi } from 'api';
import { AxiosRequestConfig } from 'axios';
import { IRegisterFormValues } from 'client/containers/RegisterForm/useRegisterForm';
import { getFormData } from 'client/helpers/getFormData';
import { ISignInResponse } from 'types/Auth';

export const signUp = (isServer = false) => async (values: IRegisterFormValues) => {
  const reqBody = new FormData();

  getFormData(reqBody, values);
  const config: AxiosRequestConfig = {
    headers: {
      ...clientApi.defaults.headers,
      'Content-Type': 'multipart/form-data',
    },
  };

  const response = await clientApi.post<ISignInResponse>('/users', reqBody, config);

  return response;
};
