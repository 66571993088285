import { useForm, SubmitHandler } from 'react-hook-form';
import { RootState } from 'client/_redux/reducers';
import { useSelector } from 'react-redux';

export interface IRegisterFormValues {
  email: string;
  password: string;

  firstName: string;
  lastName: string;
  gender: string;
  country: string;
  address: string;
  phone: string;
  image: File;
}

export const useRegisterForm = (onSubmit: SubmitHandler<IRegisterFormValues>) => {
  const formMethods = useForm<IRegisterFormValues>({
    shouldFocusError: true,
  });
  const { handleSubmit, watch } = formMethods;

  const _onSubmit: SubmitHandler<IRegisterFormValues> = (data) => {
    onSubmit(data);
  };
  const password = watch('password') || '';

  const loading = useSelector(({ auth: { loading } }: RootState) => loading);

  const genderArray = [
    { label: 'MALE', value: 'MALE' },
    { label: 'FEMALE', value: 'FEMALE' },
  ];

  return {
    formMethods,
    handleSubmit,
    _onSubmit,
    password,
    loading,
    genderArray,
  };
};
