import Input from 'client/components/Form/Input';
import Select from 'client/components/Form/Select';
import { USER_ROLES_OPTIONS } from 'client/constants/userRolesOptions';
import useSearchParamsFilter from 'client/hooks/useSearchParamsFilter';
import React from 'react';

const UserFilterComponent = () => {
  const { handleFilterSearch, searchParams } = useSearchParamsFilter({});

  return (
    <div className="d-flex justify-content-start align-items-center w-100 flex-wrap pt-4 col-12">
      <Input
        type="text"
        label="Email / Phone"
        placeholder="Rechercher..."
        value={searchParams.get('keyword') || ''}
        onChange={(e) => handleFilterSearch('keyword', e.currentTarget.value)}
        name="keyword"
      />
      <Select
        options={USER_ROLES_OPTIONS}
        onChange={(e) => handleFilterSearch('role', e as string)}
        label="Role"
        className="col-3"
        value={searchParams.get('role') || ''}
        isClearable
      />
    </div>
  );
};

export default UserFilterComponent;
