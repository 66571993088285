import Invoice from 'client/components/Invoice';
import Modal from 'client/components/Modal';
import DataTable from 'client/containers/DataTableContainer';
import PackageForm from 'client/containers/PackageForm';
import { getAllPackagesExp } from 'client/_redux/actions/packages';
import * as React from 'react';
import ModalFooter from 'client/components/Modal/ModalFooter';
import PackageFilter from './PackageFilter';
import { useAdminPackages } from './useAdminPackages';
import ExpandedRows from './ExpandableRow';

const AdminPackagesPage: React.FC<{ isAdmin?: boolean }> = ({ isAdmin }) => {
  const {
    columns,
    dataTableRef,
    expeditorPackages,
    isUpdateModalVisible,
    handleCloseUpdateModal,
    itemToUpdateOrDelete,
    isPrintModalVisible,
    handleClosePrintModal,
    isDeleteModalVisible,
    setIsDeleteModalVisible,
    onDelete,
  } = useAdminPackages(isAdmin);

  return (
    <div className="d-flex justify-content-around flex-wrap">
      <DataTable
        myRef={dataTableRef}
        data={expeditorPackages}
        title="Tous les colis"
        columns={columns}
        fetchDataFn={getAllPackagesExp}
        subHeaderComponent={<PackageFilter />}
        expandableRows
        expandableIcon={{
          collapsed: <i className="fas fa-chevron-circle-down m-auto" />,
          expanded: <i className="fas fa-chevron-circle-up m-auto" />,
        }}
        ExpandedComponent={ExpandedRows}
      />

      {itemToUpdateOrDelete && (
        <>
          <Modal
            isOpen={isUpdateModalVisible}
            closeModal={handleCloseUpdateModal}
            modalHead="Modifier votre colis"
          >
            <PackageForm
              isUpdate
              itemToUpdateOrDelete={itemToUpdateOrDelete}
              setIsUpdateModalVisible={handleCloseUpdateModal}
              isAdmin
            />
          </Modal>
          <Modal
            isOpen={isPrintModalVisible}
            closeModal={handleClosePrintModal}
            modalHead="Imprimer colis"
          >
            <Invoice parcel={itemToUpdateOrDelete} />
          </Modal>
          <Modal
            childrenClassName="py-4"
            isOpen={isDeleteModalVisible}
            closeModal={() => setIsDeleteModalVisible(false)}
            modalHead="Supprimer ce colis"
            footer={ModalFooter({
              handleSubmitModal: onDelete,
              cancelModal: () => setIsDeleteModalVisible(false),
              modalButtonText: 'Confirmer',
            })}
          >
            <span>
              êtes-vous sûr de supprimer le colis de {itemToUpdateOrDelete?.client?.name}
            </span>
          </Modal>
        </>
      )}
    </div>
  );
};

export default AdminPackagesPage;
