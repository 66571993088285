import { errorHandler } from 'client/helpers/errorHandler';
import { getUserById } from 'client/_redux/services/user';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IUser } from 'server/models/User/types';

export const useUserDetails = () => {
  const { id } = useParams<string>();
  const [user, setUser] = useState<IUser>();

  useEffect(() => {
    getUserById()(id as string)
      .then((data) => setUser(data))
      .catch(errorHandler);
  }, [id]);

  return {
    user,
  };
};
