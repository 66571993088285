import * as React from 'react';
import { useFormContext, Controller, ValidationRules } from 'react-hook-form';
import SwitchBox, { IProps as IComponentProps } from 'client/components/Form/SwitchBox';

interface IProps extends Omit<IComponentProps, 'errors' | 'onChange'> {
  name: string;
  validate?: ValidationRules;
}

const FormSwitchBox: React.FC<IProps> = ({ name, validate, ...switchProps }) => {
  const { control } = useFormContext();

  return (
    <Controller
      rules={validate}
      name={name}
      control={control}
      defaultValue={[]}
      render={({ onChange }) => <SwitchBox {...switchProps} id={name} name={name} />}
    />
  );
};

export default FormSwitchBox;
