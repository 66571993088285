import { clientApi } from 'api';
import { IAddressDocument } from 'server/models/Address/types';
import { AddressFormType } from 'types/forms/AddressFormType';

const baseUrl = '/address';

export const getAddressById = (isServer = false) => (id: string) =>
  clientApi.get<IAddressDocument>(`${baseUrl}/${id}`);
export const updateAddress = (isServer = false) => (id: string, data: AddressFormType) =>
  clientApi.patch<IAddressDocument>(`${baseUrl}/${id}`, data);
export const createAddress = (isServer = false) => (data: AddressFormType) =>
  clientApi.post<IAddressDocument>(baseUrl, data);
