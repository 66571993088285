import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { RootState } from 'client/_redux/reducers';
import {
  createRunsheet,
  deleteRunsheet,
  getDrivers,
  getRunsheetById,
  updateRunsheet,
} from 'client/_redux/actions/runsheet';
import { runsheetFormType } from 'types/forms/runsheetFormType';
import { toast } from 'react-toastify';
import { IRunsheetDocument } from 'server/models/Runsheet/types';
import { scannedPackagesTableColumns } from 'client/pages/Manager/AcceptPackages/scannedPackagesTableColumns';
import { PackagesExpTypes } from 'client/_redux/types/packagesExpTypes';
import { useNavigate, useParams } from 'react-router-dom';
import { IUserDocument } from 'server/models/User/types';
import { deletePackagesFromRunsheet } from 'client/_redux/actions/packages';
import { useTableColumnsWithActions } from '../DataTableContainer/useTableColumnsWithActions';

export const useRunsheetForm = (isUpdate?: boolean) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [driversList, setDriversList] = useState<IUserDocument[]>([]);

  useEffect(() => {
    getDrivers().then((drivers) => setDriversList(drivers));
  }, []);
  const { id } = useParams();

  const scannedRunsheetPackages = useSelector(
    ({ packages: { scannedRunsheetPackages } }: RootState) => scannedRunsheetPackages,
  );

  const [itemToUpdateOrDelete, setItemToUpdateOrDelete] = useState<
    IRunsheetDocument | undefined
  >(undefined);

  useEffect(() => {
    if (id) {
      getRunsheetById(id).then((runsheet) => {
        setItemToUpdateOrDelete(runsheet);
      });
    } else dispatch({ type: PackagesExpTypes.CLEAR_SCANNED_PACKAGES_LIST });
  }, [dispatch, id]);

  useEffect(() => {
    isUpdate &&
      dispatch({
        type: PackagesExpTypes.ADD_TO_RUNSHEET_PACKAGES_LIST,
        payload: itemToUpdateOrDelete && itemToUpdateOrDelete.packagesList,
      });
  }, [dispatch, isUpdate, itemToUpdateOrDelete]);

  const loading = useSelector(({ loading: { loading } }: RootState) => loading);

  const formMethods = useForm<runsheetFormType>();
  const { handleSubmit } = formMethods;

  const runsheet = useSelector(({ runsheet: { runsheet } }: RootState) => runsheet);

  const [driver, setDriver] = useState('');

  const [toggleRunsheetModal, setToggleRunsheetModal] = useState(false);

  const handleDriverSelect = useCallback((selectedOption) => {
    setDriver(selectedOption);
  }, []);

  const packagesList = scannedRunsheetPackages?.map((pack) => pack._id);
  const onSubmit = (values: runsheetFormType) => {
    if (!driver && !itemToUpdateOrDelete?.driver) {
      toast('sélectionner un livreur pour cette runsheet', { type: 'error' });

      return;
    }

    values.packagesList = packagesList;
    values.driver = driver || itemToUpdateOrDelete?.driver?._id;
    dispatch(isUpdate ? updateRunsheet(values, id) : createRunsheet(values));
    setToggleRunsheetModal(true);
  };

  const handleDeletePackageFromRunsheet = (barCode: number) => {
    dispatch(deletePackagesFromRunsheet(barCode));
  };
  const columns = useTableColumnsWithActions({
    columns: scannedPackagesTableColumns(),
    buttons: [
      {
        className: (item) => `fa fa-trash`,
        fn: (item) => handleDeletePackageFromRunsheet(item.barCode),
        color: 'red',
      },
    ],
  });
  const options = driversList?.map((driver) => ({
    label: driver.firstName,
    value: driver._id,
  }));
  const selectedDriver = useMemo(
    () => driversList?.find((_driver) => _driver._id === driver),
    [driver, driversList],
  );

  const handleDelete = useCallback(() => {
    if (id) dispatch(deleteRunsheet(id, navigate));
  }, [dispatch, id, navigate]);

  return {
    handleSubmit,
    onSubmit,
    formMethods,
    options,
    handleDriverSelect,
    scannedRunsheetPackages,
    columns,
    selectedDriver,
    toggleRunsheetModal,
    setToggleRunsheetModal,
    runsheet,
    loading,
    packagesList,
    itemToUpdateOrDelete,
    handleDelete,
    id,
  };
};
