import { IUserPackageDocument } from 'server/models/Package/types';
import { TableColumn } from 'react-data-table-component';
import { format } from 'date-fns';
import Select from 'client/components/Form/Select';
import React from 'react';
import { adminPackagesStatesOptions } from 'client/constants/SelectOptions';
import { PackageStateEnum } from 'types/Enums/PackageStateEnum';
import { adminChangePackageState } from 'client/_redux/actions/runsheet';
export const TableColumns = (isAdmin?: boolean): TableColumn<IUserPackageDocument>[] => [
  {
    name: 'Expedituer',
    selector: (e) => `${e.expeditor.firstName} ${e.expeditor.lastName}`,
    center: true,
    sortable: true,
    sortField: 'client.name',
    style: {
      fontWeight: 'bold',
    },
  },
  {
    name: 'Nom du client',
    selector: (e) => `${e.client.name}`,
    center: true,
    sortable: true,
    sortField: 'client.name',
    style: {
      fontWeight: 'bold',
    },
  },
  {
    name: 'Telephone du client',
    selector: (e) => `${e.client.clientPhoneNumber}`,
    center: true,
    sortable: true,
    sortField: 'client.clientPhoneNumber',
  },
  {
    name: 'Fragile',
    selector: (e) => `${e.isDelicate ? 'Oui' : 'Non'}`,
    center: true,
    sortable: true,
    sortField: 'isDelicate',
    style: {
      fontWeight: 'bold',
    },
  },
  {
    name: 'Echange',
    selector: (e) => `${e.exchangePackage ? 'Oui' : 'Non'}`,
    center: true,
    sortable: true,
    sortField: 'isDelicate',
    style: {
      fontWeight: 'bold',
    },
  },
  {
    name: 'Status',
    cell: (e) =>
      isAdmin ? (
        <Select
          style={{ width: '90%', marginTop: '1rem' }}
          options={adminPackagesStatesOptions}
          defaultValue={{ label: e.state, value: e.state }}
          name="packageState"
          onChange={(event) => adminChangePackageState(e._id, event as PackageStateEnum)}
        />
      ) : (
        <span>{e.state}</span>
      ),
    center: true,
    sortable: true,
    sortField: 'state',
  },
  {
    name: 'Cuz',
    selector: (e) => `${e?.returnCuz ? e?.returnCuz : '---'}`,
    center: true,
  },
  {
    name: "Nom d'article",
    selector: (e) => `${e.articleName}`,
    center: true,
    sortable: true,
    sortField: 'articleName',
    style: {
      fontWeight: 'bold',
    },
  },
  {
    name: 'Prix',
    selector: (e) => `${e.price} DT`,
    center: true,
    sortable: true,
    sortField: 'price',
    style: {
      fontWeight: 'bold',
    },
  },
  {
    name: 'Destination',
    selector: (e) => `${e.destination.city}-${e.destination.address}`,
    center: true,
    sortable: true,
    sortField: 'destination.country',
    style: {
      fontWeight: 'bold',
    },
  },
  {
    sortField: 'createdAt',
    name: 'Date de creation',
    cell: (e) => e.createdAt && format(new Date(e.createdAt), 'MM/dd/yyyy HH:mm'),
    sortable: true,
    center: true,
  },
];
