import { IUserPackage } from 'server/models/Package/types';
import { IRunsheet, IRunsheetDocument } from 'server/models/Runsheet/types';
import { PackageStateEnum } from 'types/Enums/PackageStateEnum';

export const deliveryButtons = (
  redirectToUpdateRunsheetPage?: (item: IRunsheetDocument) => void,
  handlePrintRunsheet?: (item: IRunsheetDocument) => void,
  handlePrintDriverInvoice?: (item: IRunsheetDocument) => void,
  confirmRunsheet?: (item: IRunsheetDocument) => void,
  handleDeleteRunsheet?: (item: IRunsheetDocument) => void,
  isAdmin?: boolean,
) =>
  isAdmin
    ? [
        {
          className: () => `fa fa-pen`,
          fn: redirectToUpdateRunsheetPage,
          color: 'black',
          disabled: (e: IRunsheet) => e.isValid,
        },
        {
          className: () => `fas fa-print`,
          fn: (e: IRunsheetDocument) =>
            e.isValid
              ? handlePrintDriverInvoice && handlePrintDriverInvoice(e)
              : handlePrintRunsheet && handlePrintRunsheet(e),
          color: 'black',
          disabled: () => false,
        },
        {
          className: () => `fas fa-check`,
          fn: confirmRunsheet,
          color: 'black',
          disabled: (item: IRunsheetDocument) =>
            item.isValid
              ? item.isValid
              : (item.packagesList as IUserPackage[]).filter(
                  (pack) => pack.state !== PackageStateEnum.PACKAGE_HEADING_TO_CLIENT,
                ).length !== item.packagesList.length,
        },
        {
          className: () => `fas fa-trash`,
          fn: handleDeleteRunsheet,
          color: 'red',
        },
      ]
    : [
        {
          className: () => `fa fa-pen`,
          fn: redirectToUpdateRunsheetPage,
          color: 'black',
          disabled: (e: IRunsheet) => e.isValid,
        },
        {
          className: () => `fas fa-print`,
          fn: (e: IRunsheetDocument) =>
            e.isValid
              ? handlePrintDriverInvoice && handlePrintDriverInvoice(e)
              : handlePrintRunsheet && handlePrintRunsheet(e),
          color: 'black',
          disabled: () => false,
        },
        {
          className: () => `fas fa-check`,
          fn: confirmRunsheet,
          color: 'black',
          disabled: (item: IRunsheetDocument) =>
            item.isValid
              ? item.isValid
              : (item.packagesList as IUserPackage[]).filter(
                  (pack) => pack.state !== PackageStateEnum.PACKAGE_HEADING_TO_CLIENT,
                ).length !== item.packagesList.length,
        },
      ];

export const manifestButtons = (
  handleAddDriverToManifest?: (item: IRunsheetDocument) => void,
  handlePrintManifest?: (item: IRunsheetDocument) => void,
  handleDeleteRunsheet?: (item: IRunsheetDocument) => void,
  isAdmin?: boolean,
) =>
  isAdmin
    ? [
        {
          className: () => `fas fa-plus`,
          // eslint-disable-next-line no-console
          fn: handleAddDriverToManifest,
          color: 'black',
          disabled: (e: IRunsheet) => e.isValid,
        },
        {
          className: () => `fas fa-print`,
          // eslint-disable-next-line no-console
          fn: handlePrintManifest,
          color: 'black',
          disabled: () => false,
        },
        {
          className: () => `fas fa-trash`,
          // eslint-disable-next-line no-console
          fn: handleDeleteRunsheet,
          color: 'red',
          disabled: () => false,
        },
      ]
    : [
        {
          className: () => `fas fa-plus`,
          // eslint-disable-next-line no-console
          fn: handleAddDriverToManifest,
          color: 'black',
          disabled: (e: IRunsheet) => e.isValid,
        },
        {
          className: () => `fas fa-print`,
          // eslint-disable-next-line no-console
          fn: handlePrintManifest,
          color: 'black',
          disabled: () => false,
        },
      ];
export const pickupButtons = (
  handleDeleteRunsheet?: (item: IRunsheetDocument) => void,
  isAdmin?: boolean,
) =>
  isAdmin
    ? [
        {
          className: () => `fas fa-trash`,
          fn: handleDeleteRunsheet,
          color: 'red',
        },
      ]
    : [];
