import { TableColumn } from 'react-data-table-component';
import React from 'react';
import { IUser } from 'server/models/User/types';
import { UserRolesEnum } from 'types/Enums/UserRolesEnum';
import { format } from 'date-fns';
import { IUserPackage } from 'server/models/Package/types';
export const UserTableColumns = (): TableColumn<IUser>[] => [
  {
    name: 'Nom',
    selector: (e) => e.firstName,
    sortable: true,
    center: true,
    sortField: 'firstName',
  },
  {
    name: 'Prenom',
    selector: (e) => e.lastName,
    sortable: true,
    center: true,
    sortField: 'lastName',
  },
  {
    name: 'Phone',
    selector: (e) => e.phoneNumber,
    sortable: true,
    center: true,
    sortField: 'phoneNumber',
  },
  {
    name: 'Role',
    selector: (e) => e.role,
    sortable: true,
    center: true,
    sortField: 'role',
  },
  {
    name: 'Email',
    selector: (e) => e.email,
    sortable: true,
    center: true,
    sortField: 'email',
  },
  {
    name: 'Phone',
    selector: (e) => e.phoneNumber,
    sortable: true,
    center: true,
    sortField: 'phoneNumber',
  },
  {
    name: 'Balance',
    selector: (e) => (e.role === UserRolesEnum.ROLE_EXPEDITOR ? e.balance : '--'),
    sortable: true,
    center: true,
    sortField: 'balance',
  },
  {
    name: 'Frais',
    selector: (e) => (e.role === UserRolesEnum.ROLE_EXPEDITOR ? e.leveringFee : '--'),
    sortable: true,
    center: true,
    sortField: 'leveringFee',
  },
  {
    name: 'VIP',
    cell: (e) =>
      // eslint-disable-next-line no-nested-ternary
      e.role === UserRolesEnum.ROLE_EXPEDITOR ? (
        e.isVIP ? (
          <small className="badge badge-success">
            <i className="far fa-star" /> VIP
          </small>
        ) : (
          <small className="badge badge-warning">Non</small>
        )
      ) : (
        '--'
      ),
    sortable: true,
    center: true,
    sortField: 'isVIP',
  },
];
export const expeditorPackagesColumns = (): TableColumn<IUserPackage>[] => [
  {
    name: 'Nom du client',
    selector: (e) => `${e.client.name}`,
    center: true,
    sortable: true,
    sortField: 'client.name',
    style: {
      fontWeight: 'bold',
    },
  },
  {
    name: 'Telephone du client',
    selector: (e) => `${e.client.clientPhoneNumber}`,
    center: true,
    sortable: true,
    sortField: 'client.clientPhoneNumber',
  },
  {
    name: 'Fragile',
    selector: (e) => `${e.isDelicate}`,
    center: true,
    sortable: true,
    sortField: 'isDelicate',
    style: {
      fontWeight: 'bold',
    },
  },
  {
    name: 'Status',
    selector: (e) => `${e.state}`,
    center: true,
    sortable: true,
    sortField: 'state',
  },
  {
    name: 'Cuz',
    selector: (e) => `${e?.returnCuz ? e?.returnCuz : '---'}`,
    center: true,
  },
  {
    name: "Nom d'article",
    selector: (e) => `${e.articleName}`,
    center: true,
    sortable: true,
    sortField: 'articleName',
    style: {
      fontWeight: 'bold',
    },
  },
  {
    name: 'Prix',
    selector: (e) => `${e.price} DT`,
    center: true,
    sortable: true,
    sortField: 'price',
    style: {
      fontWeight: 'bold',
    },
  },
  {
    name: 'Destination',
    selector: (e) => `${e.destination.city}-${e.destination.address}`,
    center: true,
    sortable: true,
    sortField: 'destination.country',
    style: {
      fontWeight: 'bold',
    },
  },
  {
    sortField: 'createdAt',
    name: 'Date de creation',
    cell: (e) => e.createdAt && format(new Date(e.createdAt), 'MM/dd/yyyy HH:mm'),
    sortable: true,
    center: true,
  },
];
