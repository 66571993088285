import DriverInvoice from 'client/components/DriverInvoice';
import Manifest from 'client/components/Manifest';
import Modal from 'client/components/Modal';
import ModalFooter from 'client/components/Modal/ModalFooter';
import Runsheet from 'client/components/Runsheet';
import DataTable from 'client/containers/DataTableContainer';
import { getManagerRunsheet } from 'client/_redux/actions/runsheet';
import * as React from 'react';
import { IUser } from 'server/models/User/types';
import SelectDriverModal from '../SelectDriverModal';
import PackagesRows from './PackagesRows';
import RunsheetFilter from './RunsheetFilter';
import { useRunsheet } from './useRunsheet';
const RunsheetList: React.FC<{ isAdmin: boolean }> = ({ isAdmin }) => {
  const {
    columns,
    dataTableRef,
    runsheetList,
    redirectToAddRunsheetPage,
    isPrintModalVisible,
    itemToUpdateOrDelete,
    setIsPrintModalVisible,
    type,
    setType,
    manifestModal,
    manifest,
    setManifestModal,
    addDriverModal,
    setAddDriverModal,
    onSubmitAddDriverToManifest,
    setDriver,
    packagesList,
    toggleDriverInvoice,
    setToggleDriverInvoice,
    deleteModal,
    setDeleteModal,
    onDelete,
    itemToDelete,
  } = useRunsheet(isAdmin);

  return (
    <div className="d-flex justify-content-around flex-wrap">
      <DataTable
        myRef={dataTableRef}
        data={runsheetList}
        title="liste des runsheet"
        columns={columns}
        fetchDataFn={getManagerRunsheet}
        subHeaderComponent={<RunsheetFilter seType={setType} type={type} />}
        ExpandedComponent={({ data }) => <PackagesRows data={data} />}
        expandableRows
        expandableIcon={{
          collapsed: <i className="fas fa-chevron-circle-down m-auto" />,
          expanded: <i className="fas fa-chevron-circle-up m-auto" />,
        }}
        onAddButtonClick={() => redirectToAddRunsheetPage()}
      />
      {itemToUpdateOrDelete && (
        <>
          <Modal
            isOpen={isPrintModalVisible}
            closeModal={() => setIsPrintModalVisible(false)}
            modalHead="Imprimer votre colis"
          >
            <Runsheet runsheet={itemToUpdateOrDelete} />
          </Modal>
        </>
      )}
      {manifest && (
        <Modal
          isOpen={manifestModal}
          closeModal={() => setManifestModal(false)}
          modalHead="Imprimer votre colis"
        >
          <Manifest expeditor={manifest?.expeditor as IUser} manifest={manifest} />
        </Modal>
      )}

      <Modal
        isOpen={toggleDriverInvoice}
        closeModal={() => setToggleDriverInvoice(false)}
        modalHead="Facture livruer"
      >
        {packagesList.runsheet && <DriverInvoice runsheet={packagesList} />}
      </Modal>
      {deleteModal && (
        <>
          <Modal
            isOpen={deleteModal}
            closeModal={() => setDeleteModal(false)}
            modalHead="Imprimer votre colis"
            footer={ModalFooter({
              handleSubmitModal: onDelete,
              cancelModal: () => setDeleteModal(false),
              modalButtonText: 'Supprimer',
            })}
          >
            Supprimer {itemToDelete?.type}
          </Modal>
        </>
      )}
      <SelectDriverModal
        toggleModal={addDriverModal}
        setToggleModal={setAddDriverModal}
        onSubmit={onSubmitAddDriverToManifest}
        setDriver={setDriver}
      />
    </div>
  );
};

export default RunsheetList;
