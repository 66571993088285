import React from 'react';
import { IUserPackageDocument } from 'server/models/Package/types';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTableColumnsWithActions } from 'client/containers/DataTableContainer/useTableColumnsWithActions';
import { PackageStateEnum } from 'types/Enums/PackageStateEnum';
import { format } from 'date-fns';

export interface ExpandedOrderRowProps {
  data: IUserPackageDocument;
}
const ExpandedRows = ({ data: { packageHistory } }: ExpandedOrderRowProps) => {
  const packageHistoryColumns = (): TableColumn<{
    state: PackageStateEnum;
    date: Date;
  }>[] => [
    {
      name: 'Etat',
      selector: (e) => `${e.state}`,
      center: true,
      sortable: true,
      sortField: 'client.name',
      style: {
        fontWeight: 'bold',
      },
    },
    {
      name: 'Date',
      selector: (e) => format(new Date(e.date), 'dd-MM-yyyy hh:mm'),
      center: true,
      sortable: true,
      sortField: 'client.clientPhoneNumber',
    },
  ];
  const columns = useTableColumnsWithActions({
    columns: packageHistoryColumns(),
    buttons: [],
  });

  return (
    <div className="d-flex justify-content-around flex-wrap">
      <DataTable title="historique de colis" data={packageHistory} columns={columns} />
    </div>
  );
};

export default ExpandedRows;
