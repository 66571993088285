import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'client/_redux/reducers';
import { useTableColumnsWithActions } from 'client/containers/DataTableContainer/useTableColumnsWithActions';
import { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTableHandle } from 'client/containers/DataTableContainer/types';
import { deleteUserById, getAllUsers } from 'client/_redux/actions/user';
import { IUser, IUserDocument } from 'server/models/User/types';
import { UserRolesEnum } from 'types/Enums/UserRolesEnum';
import { payExpeditor } from 'client/_redux/actions/user/expiditeur';
import { IPaymentHistory } from 'server/models/PaymentHistory/types';
import { UserTableColumns } from './UserTableColumns';

export const useUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userList, loaded } = useSelector((state: RootState) => state.user);
  const dataTableRef = useRef<DataTableHandle>(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);
  const [isBanModalVisible, setIsBanModalVisible] = useState<boolean>(false);
  const [isPermitModalVisible, setIsPermitModalVisible] = useState<boolean>(false);
  const [itemToDelete, setItemToDelete] = useState<string>();
  const [itemToPrint, setItemToPrint] = useState<IPaymentHistory>();
  const [paymentModal, setPaymentModal] = useState(false);
  const [printModal, setPrintModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IUserDocument>();

  const handlePayExpeditor = ({
    _id,
    from,
    to,
  }: {
    _id: string;
    from: string;
    to: string;
  }) => {
    payExpeditor({
      _id,
      from,
      to,
    }).then((data) => {
      setItemToPrint(data);

      if (!data) {
        setPaymentModal(false);

        return;
      }
      setPaymentModal(false);
      setPrintModal(true);
      dispatch(getAllUsers({}));
    });
  };
  const onView = useCallback(
    (item: IUser) => {
      navigate(`/utilisateurs/details/${item._id}`);
    },
    [navigate],
  );

  const onDelete = useCallback(() => {
    if (!itemToDelete) return;
    dispatch(deleteUserById(itemToDelete));
    setIsDeleteModalVisible(false);
  }, [dispatch, itemToDelete]);

  const onEdit = useCallback(
    (item: IUser) => {
      navigate(`/utilisateurs/update/${item._id}`);
    },
    [navigate],
  );

  const onAdd = useCallback(() => {
    navigate(`/utilisateurs/add`);
  }, [navigate]);

  const columns = useTableColumnsWithActions({
    columns: UserTableColumns(),
    buttons: [
      {
        className: () => 'fas fa-edit cursor-pointer',
        fn: onEdit,
        color: 'black',
      },
      {
        className: () => 'fas fa-info-circle cursor-pointer',
        fn: onView,
        color: 'black',
      },
      {
        className: () => 'fas fa-trash cursor-pointer',
        fn: (item) => {
          setIsDeleteModalVisible(true);
          setItemToDelete(item._id);
        },
        color: 'black',
      },
      {
        className: (item) =>
          `${
            item.role === UserRolesEnum.ROLE_EXPEDITOR ? 'd-block' : 'd-none'
          } fas fa-money-check cursor-pointer`,
        fn: (item) => {
          setSelectedItem(item as IUserDocument);
          setPaymentModal(true);
        },
        color: 'black',
      },
    ],
  });

  return {
    userList,
    loaded,
    columns,
    dataTableRef,
    isDeleteModalVisible,
    setIsDeleteModalVisible,
    isBanModalVisible,
    isPermitModalVisible,
    setIsBanModalVisible,
    setIsPermitModalVisible,
    onDelete,
    onAdd,
    paymentModal,
    setPaymentModal,
    handlePayExpeditor,
    selectedItem,
    itemToPrint,
    printModal,
    setPrintModal,
  };
};
