/* eslint-disable react/no-unescaped-entities */
import Input from 'client/components/Form/Input';
import * as React from 'react';
import Validators from 'client/helpers/formValidator';
import { SubmitHandler, FormProvider } from 'react-hook-form';
import Loader from 'client/components/Loader';
import { ILoginFormValues, useLoginForm } from './useLoginForm';

interface LoginFormProps {
  onSubmit: SubmitHandler<ILoginFormValues>;
  isLoading: boolean;
}

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit, isLoading }) => {
  const { formMethods, handleSubmit, _onSubmit } = useLoginForm(onSubmit);

  return (
    <FormProvider {...formMethods}>
      <form className="mt-2" onSubmit={handleSubmit(_onSubmit)}>
        <Input
          type="email"
          name="email"
          placeholder="email"
          validate={Validators([{ validation: 'required' }, { validation: 'email' }])}
        />

        <Input
          type="password"
          name="password"
          placeholder="mot de pass"
          forgotPasswordLink="/"
          validate={Validators([
            { validation: 'required' },
            { minParams: 8, validation: 'minLength' },
          ])}
        />

        <div className="align-items-center mb-5">
          <div className="text-center">
            {isLoading ? (
              <Loader />
            ) : (
              <button
                className="btn btn-outline-primary transition-3d-hover btn-md btn-block mb-1"
                type="submit"
                disabled={isLoading}
              >
                S'athentifier
              </button>
            )}
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default LoginForm;
