/* eslint-disable no-underscore-dangle */
import 'react-toastify/dist/ReactToastify.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as React from 'react';
import Root from 'client/containers/AppRouter';
import configureStore from 'client/_redux/store/configureStore';
import { hydrate } from 'react-dom';
import { RootState } from 'client/_redux/reducers';
import { Suspense } from 'react';
import { ToastContainer } from 'react-toastify';

declare global {
  interface Window {
    __PRELOADED_STATE__?: RootState;
  }
}

const preloadedState = window.__PRELOADED_STATE__;

delete window.__PRELOADED_STATE__;
const store = configureStore(preloadedState);

const BaseApp = () => (
  <Suspense fallback={<div>Still loading i18n...</div>}>
    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
    {/* @ts-ignore */}
    <Provider store={store}>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
      />
      <BrowserRouter>
        <Root />
      </BrowserRouter>
    </Provider>
  </Suspense>
);

hydrate(
  <BaseApp />,

  document.getElementById('root'),
);

if (module.hot) {
  module.hot.accept();
}
