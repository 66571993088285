import { useForm, SubmitHandler } from 'react-hook-form';
export interface ILoginFormValues {
  email: string;
  password: string;
}

export const useLoginForm = (onSubmit: SubmitHandler<ILoginFormValues>) => {
  const formMethods = useForm<ILoginFormValues>({ shouldFocusError: true });
  const { handleSubmit } = formMethods;

  const _onSubmit: SubmitHandler<ILoginFormValues> = (data) => {
    onSubmit(data);
  };

  return {
    formMethods,
    handleSubmit,
    _onSubmit,
  };
};
