import { LoadingTypes } from 'client/_redux/types/loadingTypes';
import { IReduxAction } from 'types/IReduxAction';

export interface LoadingState {
  loadingProcesses: number;
  loading: boolean;

  initiated: boolean;
}
const initialState: LoadingState = {
  loadingProcesses: 0,
  loading: false,
  initiated: false,
};

export default (state: LoadingState = initialState, action: IReduxAction): LoadingState => {
  let { loadingProcesses, loading } = state;
  const { type } = action;

  switch (type) {
    case LoadingTypes.LOADING_START:
      loadingProcesses += 1;
      loading = true;

      return { ...state, loadingProcesses, loading };
    case LoadingTypes.LOADING_FINISHED:
      loadingProcesses -= 1;
      if (loadingProcesses === 0 || loadingProcesses <= 0) {
        loading = false;
        loadingProcesses = 0;
      }

      return { ...state, loadingProcesses, loading };
    case LoadingTypes.INITIATE:
      return { ...state, initiated: true };
    default:
      return state;
  }
};
