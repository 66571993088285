import React from 'react';

const NextPackSignature = () => (
  <div className="logo-information">
    <img
      alt="logo"
      src={(process.env.RAZZLE_PUBLIC_URL, '/assets/images/nextPackLogoBlack.png')}
      width={190}
      className="pb-3"
    />
    {/* <div>
      <span className="h5 pr-1">Telephone:</span>
      <span>22332211</span>
    </div>
    <div>
      <span className="h5 pr-1">E-mail:</span>
      <span>support@nextpack.tn m</span>
    </div>
    <div>
      <span className="h5 pr-1">Adresse:</span>
      <span>city,country,state,addresse</span>
    </div> */}
  </div>
);

export default NextPackSignature;
