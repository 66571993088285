import { getExchangePackage } from 'client/_redux/actions/runsheet';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactToPrint from 'react-to-print';
import { IExchangePackage } from 'server/models/ExchangePackage/types';
import { IUserPackageDocument } from 'server/models/Package/types';
import ExchangePackageInvoiceContent from './ExchangePackageInvoiceContent';

type ExchangePackageInvoiceProps = {
  mainPackage: IUserPackageDocument;
};
const ExchangePackageInvoice: React.FC<ExchangePackageInvoiceProps> = ({ mainPackage }) => {
  const componentRef = useRef(null);
  const dispatch = useDispatch();
  const [exchangePackage, setExchangePackage] = useState<IExchangePackage>();

  useEffect(() => {
    getExchangePackage(mainPackage._id).then((data) => {
      setExchangePackage(data);
    });
  }, [dispatch, mainPackage._id]);

  return (
    <>
      <div className="container overflow-auto pt-4 mt-5">
        {exchangePackage && (
          <ExchangePackageInvoiceContent
            mainPackage={mainPackage}
            parcel={exchangePackage}
            invoiceRef={componentRef}
          />
        )}
      </div>
      <ReactToPrint
        trigger={() => (
          <div className="col-12 d-flex justify-content-center">
            <button className="btn btn-soft-dark mt-6  mb-6" type="button">
              Imprimer le facture ↓ <i className="fa fa-print" />
            </button>
          </div>
        )}
        content={() => componentRef.current}
      />
    </>
  );
};

export default ExchangePackageInvoice;
