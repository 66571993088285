import { Route } from 'react-router-dom';
import * as React from 'react';
import { UserRolesEnum } from 'types/Enums/UserRolesEnum';
import UserDetailsPage from 'client/pages/Dashboard/UserDetailsPage';
import UserFormPage from 'client/pages/Dashboard/UserFormPage';
import UserList from 'client/pages/Dashboard/UserList';
import Balance from 'client/pages/Balance';
import { renderManagerRoutes } from './ManagerRoutes';
import { renderClassicRotes } from './DefaultRoutes';

export const renderAdminRoutes = (isLoggedIn: boolean, role: UserRolesEnum) => {
  if (isLoggedIn)
    return (
      <>
        {renderManagerRoutes(isLoggedIn, role)}
        {renderClassicRotes()}
        <Route
          path="/utilisateurs"
          element={
            <div className="pt-10">
              <UserList />
            </div>
          }
        />
        <Route
          path="/utilisateurs/add"
          element={
            <div className="pt-10">
              <UserFormPage />
            </div>
          }
        />
        <Route
          path="/utilisateurs/update/:id"
          element={
            <div className="pt-10">
              <UserFormPage isUpdate />
            </div>
          }
        />
        <Route
          path="/utilisateurs/details/:id"
          element={
            <div className="pt-10">
              <UserDetailsPage />
            </div>
          }
        />
        <Route
          path="/payment"
          element={
            <div className="pt-10">
              <Balance isAdmin={role === UserRolesEnum.ROLE_ADMIN} />
            </div>
          }
        />
      </>
    );

  return renderClassicRotes();
};
