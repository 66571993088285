import FormSelect from 'client/components/Form/FormSelect';
import Input from 'client/components/Form/Input';
import SwitchBox from 'client/components/Form/SwitchBox';
import Validators from 'client/helpers/formValidator';
import React from 'react';
import { FormFelidType } from 'types/FormFelidTypes';

type FormBlockProps = {
  fields: FormFelidType[];
};
const FormBlock: React.FC<FormBlockProps> = ({ fields }) => {
  const inputRender = (field: FormFelidType) => {
    switch (field.fieldType) {
      case 'input':
        return (
          <Input
            disabled={field.isDisable}
            type={field.type}
            name={field.name}
            label={field.label}
            placeholder={field.placeholder}
            validate={Validators([{ validation: 'required' }])}
          />
        );
      case 'switchBox':
        return (
          <SwitchBox
            name={field.name}
            label={field.label}
            placeholder={field.placeholder}
            id={field.id}
          />
        );
      case 'select':
        return (
          field.options && (
            <FormSelect
              options={field.options}
              name={field.name}
              label={field.label}
              placeholder={field.placeholder}
              id={field.id}
              validate={Validators([{ validation: 'required' }])}
            />
          )
        );

      default:
        return (
          <Input
            type={field.type}
            name={field.name}
            label={field.label}
            placeholder={field.placeholder}
            validate={Validators([{ validation: 'required' }])}
          />
        );
    }
  };

  return (
    <div className="row mt-4">
      {fields.map((field) => (
        <div key={field.name} className={`${field.col} col-sm-12`}>
          {inputRender(field)}
        </div>
      ))}
    </div>
  );
};

export default FormBlock;
