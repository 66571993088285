import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

export const useLoadingDispatch = () => {
  const dispatch = useDispatch();
  const [loadingProcesses, setIsLoading] = useState(0);
  const startLoading = useCallback(() => {
    setIsLoading((prev) => (prev += 1));
  }, []);
  const stopLoading = useCallback(() => {
    setIsLoading((prev) => Math.min(prev - 1, 0));
  }, []);
  const enhancedDispatch = useCallback(
    async (callback) => {
      startLoading();
      const response = await dispatch(callback);

      stopLoading();

      return response;
    },
    [dispatch, startLoading, stopLoading],
  );

  return {
    isLoading: loadingProcesses !== 0,
    enhancedDispatch,
    startLoading,
    stopLoading,
  };
};
