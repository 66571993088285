import * as React from 'react';
import Collapsible from 'client/components/Collapsible';
import UserForm from 'client/containers/Dashboard/UserForm';
import PasswordForm from 'client/containers/Dashboard/PasswordForm';
import { useUserFormPage } from './useUserFormPage';

type UserFormPageProps = {
  isUpdate?: boolean;
};
const UserFormPage: React.FC<UserFormPageProps> = ({ isUpdate }) => {
  const { onSubmit, onSubmitPassword, defaultValues } = useUserFormPage();

  return (
    <div className="d-flex">
      <div className="column card card-body">
        <UserForm onSubmit={onSubmit} defaultValues={defaultValues} isUpdate={isUpdate} />

        {isUpdate && (
          <Collapsible title="Password">
            <PasswordForm onSubmit={onSubmitPassword} />
          </Collapsible>
        )}
      </div>
    </div>
  );
};

export default UserFormPage;
