import React, { InputHTMLAttributes, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

export interface IProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  label?: string;
  checked?: boolean;
  noAutoReset?: boolean;
  name: string;
  onChange?: (checked: boolean) => void;
}

const SwitchBox: React.FC<IProps> = ({
  label,
  noAutoReset,
  checked,
  id,
  name,
  onChange,
  ...props
}) => {
  const formContext = useFormContext();

  const { setValue } = formContext;

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(name, event.target.checked);
  };

  useEffect(() => {
    if (!noAutoReset) onChange?.(!!checked); // eslint-disable-next-line
  }, []);

  return (
    <div>
      <label className="form-label w-100" htmlFor={id}>
        <span>{label}</span>
      </label>
      <label className="toggle-switch-1">
        <input
          {...{ ...props, id }}
          name={name}
          onChange={(event) => handleCheckBoxChange(event)}
          ref={formContext?.register}
          type="checkbox"
          checked={checked}
        />
        <span>
          <i />
        </span>
      </label>
    </div>
  );
};

export default SwitchBox;
