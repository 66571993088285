import { useSelector } from 'react-redux';
import { RootState } from 'client/_redux/reducers';
import { useTableColumnsWithActions } from 'client/containers/DataTableContainer/useTableColumnsWithActions';
import { useCallback, useRef, useState } from 'react';
import { DataTableHandle } from 'client/containers/DataTableContainer/types';
import { IUserPackageDocument } from 'server/models/Package/types';
import { ExpeditorPackageTableColumns } from './ExpeditorPackagesTableColumes';

export const useExpeditorPackagesPage = () => {
  const { expeditorPackages, loaded } = useSelector(({ packages }: RootState) => packages);

  const dataTableRef = useRef<DataTableHandle>(null);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState<boolean>(false);
  const [itemToUpdateOrDelete, setItemToUpdateOrDelete] = useState<IUserPackageDocument>();
  const [isPrintModalVisible, setIsPrintModalVisible] = useState<boolean>(false);

  const handleClosePrintModal = () => {
    setIsPrintModalVisible(false);
    setItemToUpdateOrDelete(undefined);
  };
  const handleCloseUpdateModal = () => {
    setIsUpdateModalVisible(false);
    setItemToUpdateOrDelete(undefined);
  };
  const handleUpdatePackage = useCallback((item: IUserPackageDocument) => {
    setItemToUpdateOrDelete(item);
    setIsUpdateModalVisible(true);
  }, []);
  const handlePrintPackage = useCallback((item: IUserPackageDocument) => {
    setItemToUpdateOrDelete(item);
    setIsPrintModalVisible(true);
  }, []);
  const columns = useTableColumnsWithActions({
    columns: ExpeditorPackageTableColumns(),
    buttons: [
      {
        className: (item) => `fa fa-pen`,
        fn: handleUpdatePackage,
        color: 'black',
      },
      {
        className: (item) => `fas fa-print`,
        fn: handlePrintPackage,
        color: 'black',
      },
    ],
  });

  return {
    expeditorPackages,
    loaded,
    columns,
    dataTableRef,
    isUpdateModalVisible,
    handleCloseUpdateModal,
    itemToUpdateOrDelete,
    isPrintModalVisible,
    handleClosePrintModal,
  };
};
