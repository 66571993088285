import { IRunsheetDocument } from 'server/models/Runsheet/types';
import { DeliveringPackageTypeEnum } from 'types/Enums/DeliveringPackagesEnum';
import { deliveryButtons, manifestButtons, pickupButtons } from './buttons';

export const buttons = (
  type: DeliveringPackageTypeEnum,
  redirectToUpdateRunsheetPage?: (item: IRunsheetDocument) => void,
  handlePrintRunsheet?: (item: IRunsheetDocument) => void,
  confirmRunsheet?: (item: IRunsheetDocument) => void,
  handleAddDriverToManifest?: (item: IRunsheetDocument) => void,
  handlePrintManifest?: (item: IRunsheetDocument) => void,
  handlePrintDriverInvoice?: (item: IRunsheetDocument) => void,
  handleDeleteRunsheet?: (item: IRunsheetDocument) => void,
  isAdmin?: boolean,
) => {
  switch (type) {
    case DeliveringPackageTypeEnum.DELIVERING:
      return deliveryButtons(
        redirectToUpdateRunsheetPage,
        handlePrintRunsheet,
        handlePrintDriverInvoice,
        confirmRunsheet,
        handleDeleteRunsheet,
        isAdmin,
      );
    case DeliveringPackageTypeEnum.RETURN:
      return manifestButtons(
        handleAddDriverToManifest,
        handlePrintManifest,
        handleDeleteRunsheet,
        isAdmin,
      );
    case DeliveringPackageTypeEnum.PICKUP:
      return pickupButtons(handleDeleteRunsheet, isAdmin);

    default:
      return [];
  }
};
