import React from 'react';
import { IUser } from 'server/models/User/types';
import DataTable from 'react-data-table-component';
import { useTableColumnsWithActions } from 'client/containers/DataTableContainer/useTableColumnsWithActions';
import { expeditorPackagesColumns } from './UserTableColumns';

export interface ExpandedOrderRowProps {
  data: IUser;
}
const ExpandedRows = ({ data: { packages } }: ExpandedOrderRowProps) => {
  const columns = useTableColumnsWithActions({
    columns: expeditorPackagesColumns(),
    buttons: [],
  });

  return (
    <div className="d-flex justify-content-around flex-wrap">
      <DataTable data={packages} columns={columns} />
    </div>
  );
};

export default ExpandedRows;
