import { PackageReturnCuzEnum } from 'types/Enums/PackageReturnCuzEnum';
import { PackageStateEnum } from 'types/Enums/PackageStateEnum';

export const managerResetPackageStateOptions = [
  { label: 'Livrée', value: PackageStateEnum.DELIVERED },
  { label: 'Livrison échouer', value: PackageStateEnum.DELIVERING_FAIlED },
];
export const managerReturnCuzOptions = [
  {
    label: 'coli annulee',
    value: PackageReturnCuzEnum.PACKAGED_RETURNED_CANCELED_BY_EXPEDITOR,
  },
  {
    label: 'adresse incomplet',
    value: PackageReturnCuzEnum.PACKAGE_RETURNED_ADDRESS_MESSING_INFORMATION,
  },
  {
    label: 'coli inacceptable',
    value: PackageReturnCuzEnum.PACKAGE_RETURNED_BROKEN,
  },
  {
    label: 'le client disponible demain',
    value: PackageReturnCuzEnum.PACKAGE_RETURNED_CLIENT_AVAILABLE_TOMORROW,
  },
  {
    label: 'coli a reportee',
    value: PackageReturnCuzEnum.PACKAGE_RETURNED_CLIENT_NOT_AVAILABLE_Date,
  },
  {
    label: 'le client pas serieux',
    value: PackageReturnCuzEnum.PACKAGE_RETURNED_CLIENT_NOT_SERIOUS,
  },
  { label: 'coli  double', value: PackageReturnCuzEnum.PACKAGE_RETURNED_DOUBLE_PACKAGE },
  {
    label: 'adresse incorrecte',
    value: PackageReturnCuzEnum.PACKAGE_RETURNED_INCORRECT_ADDRESS,
  },
  {
    label: 'le prix est incorrecte',
    value: PackageReturnCuzEnum.PACKAGE_RETURNED_INCORRECT_PRICE,
  },
  {
    label: 'coli hors zone',
    value: PackageReturnCuzEnum.PACKAGE_RETURNED_NOT_IN_DRIVER_DESTINATION,
  },
  {
    label: 'client ne repond pas',
    value: PackageReturnCuzEnum.PACKAGE_RETURNED_NO_ANSWER_FROM_CLIENT,
  },
  {
    label: "manque d'argent",
    value: PackageReturnCuzEnum.PACKAGE_RETURNED_NO_MONEY,
  },
  { label: 'telphone fermeé', value: PackageReturnCuzEnum.PACKAGE_RETURNED_PHONE_CLOSED },
  {
    label: 'le numero incorrecte',
    value: PackageReturnCuzEnum.PACKAGE_RETURNED_PHONE_NUMBER_INCORRECT,
  },
  {
    label: 'coli refusée par client',
    value: PackageReturnCuzEnum.PACKAGE_RETURNED_REFUSED_BY_CLIENT,
  },
];

export const packagesStatesOptions = [
  { label: 'Tous', value: '' },
  { label: PackageStateEnum.DELIVERED, value: PackageStateEnum.DELIVERED },
  {
    label: PackageStateEnum.DELIVERED_AND_PAYED,
    value: PackageStateEnum.DELIVERED_AND_PAYED,
  },
  { label: PackageStateEnum.DELIVERING_FAIlED, value: PackageStateEnum.DELIVERING_FAIlED },
  { label: PackageStateEnum.EXCHANGE_PACKAGE, value: PackageStateEnum.EXCHANGE_PACKAGE },
  { label: PackageStateEnum.PACKAGE_CREATED, value: PackageStateEnum.PACKAGE_CREATED },
  {
    label: PackageStateEnum.PACKAGE_HEADING_TO_CLIENT,
    value: PackageStateEnum.PACKAGE_HEADING_TO_CLIENT,
  },
  {
    label: PackageStateEnum.PACKAGE_REFUSED_AND_WILL_RETURN_TO_EXPEDITOR,
    value: PackageStateEnum.PACKAGE_REFUSED_AND_WILL_RETURN_TO_EXPEDITOR,
  },
  {
    label: PackageStateEnum.PACKAGE_RETURNED_TO_EXPEDITOR,
    value: PackageStateEnum.PACKAGE_RETURNED_TO_EXPEDITOR,
  },
  { label: PackageStateEnum.PACKAGE_STORED, value: PackageStateEnum.PACKAGE_STORED },
  { label: PackageStateEnum.PICKED_UP, value: PackageStateEnum.PICKED_UP },
  {
    label: PackageStateEnum.RETURNED_TO_EXPEDITOR,
    value: PackageStateEnum.RETURNED_TO_EXPEDITOR,
  },
  {
    label: PackageStateEnum.RETURNED_TO_WAREHOUSE,
    value: PackageStateEnum.RETURNED_TO_WAREHOUSE,
  },
  {
    label: PackageStateEnum.SELECTED_FOR_PICKUP,
    value: PackageStateEnum.SELECTED_FOR_PICKUP,
  },
];
export const adminPackagesStatesOptions = [
  { label: PackageStateEnum.DELIVERED, value: PackageStateEnum.DELIVERED },
  {
    label: PackageStateEnum.DELIVERED_AND_PAYED,
    value: PackageStateEnum.DELIVERED_AND_PAYED,
  },
  {
    label: PackageStateEnum.PACKAGE_REFUSED_AND_WILL_RETURN_TO_EXPEDITOR,
    value: PackageStateEnum.PACKAGE_REFUSED_AND_WILL_RETURN_TO_EXPEDITOR,
  },
  { label: PackageStateEnum.PACKAGE_STORED, value: PackageStateEnum.PACKAGE_STORED },
  {
    label: PackageStateEnum.RETURNED_TO_EXPEDITOR,
    value: PackageStateEnum.RETURNED_TO_EXPEDITOR,
  },
  {
    label: PackageStateEnum.RETURNED_TO_WAREHOUSE,
    value: PackageStateEnum.RETURNED_TO_WAREHOUSE,
  },
];
