import * as redux from 'redux';
import auth from './auth';
import user from './user';
import packages from './packages';
import loading from './loading';
import runsheet from './runsheet';
const rootReducer = redux.combineReducers({ loading, auth, user, packages, runsheet });

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
