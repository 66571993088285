import React from 'react';
import { IUserPackageDocument } from 'server/models/Package/types';
import { IRunsheetDocument } from 'server/models/Runsheet/types';
import BarCode from 'react-barcode';
import RunsheetHeader from './RunsheetHeader';

type RunsheetContentProps = {
  invoiceRef: React.MutableRefObject<null>;
  runsheet: IRunsheetDocument;
};
const RunsheetContent: React.FC<RunsheetContentProps> = ({ invoiceRef, runsheet }) => {
  const tableRow = (parcel: IUserPackageDocument) => (
    <tr
      style={{
        color: parcel.exchangePackage ? 'red' : 'black',
      }}
      key={runsheet?._id}
    >
      <td>
        <BarCode width={1} height={40} value={parcel.barCode} />
      </td>
      <td>{parcel?.expeditor.firstName}</td>
      <td>{parcel?.articleName}</td>
      <td>{parcel?.price} DT</td>
      <td>{parcel.client.name}</td>
      <td>{parcel.client.clientPhoneNumber}</td>
      <td>
        {`${parcel.destination.city},${parcel.destination.state},${parcel.destination.address}`}
      </td>
      <td>{parcel.packageDeliveringAttempt || 0}</td>
      <td>{parcel.exchangePackage ? 'Oui' : 'Non'}</td>
    </tr>
  );
  const tabHeader = () => (
    <thead>
      <tr>
        <th>Code à barre</th>
        <th>Expéditeur</th>
        <th>Colis</th>
        <th>Montant</th>
        <th>Client</th>
        <th>Telephone</th>
        <th>Adresse</th>
        <th>T</th>
        <th>E</th>
      </tr>
    </thead>
  );
  const maxRows = 20;

  return (
    <div ref={invoiceRef} className="factureContainer factureInfo ">
      <div className="ml-5 mr-5 mt-3">
        <RunsheetHeader runsheet={runsheet} />

        <div>
          <table
            cellSpacing={3}
            cellPadding={5}
            className="table table-bordered"
            style={{ border: 'solid' }}
          >
            {tabHeader()}
            {(runsheet.packagesList as IUserPackageDocument[])?.map((parcel, index) => {
              if ((index + 1) % maxRows === 0) {
                return (
                  <>
                    {tableRow(parcel)}
                    {runsheet.packagesList?.length > maxRows && (
                      <div className="page-counter" />
                    )}
                    <tr className="x pt-2" />
                  </>
                );
              }

              return <>{tableRow(parcel)}</>;
            })}
          </table>
        </div>
      </div>
    </div>
  );
};

export default RunsheetContent;
