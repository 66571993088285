import { clientApi } from 'api';
import { AxiosRequestConfig } from 'axios';

export const refreshToken = (isServer = false) => async (config: AxiosRequestConfig) => {
  const response = await clientApi.get<{ accessToken: string }>(
    '/users/refresh-token',
    config,
  );

  return response;
};
