import { useDispatch } from 'react-redux';
import React, {
  useEffect,
  useCallback,
  useMemo,
  ForwardedRef,
  useImperativeHandle,
} from 'react';
import { TableColumn } from 'react-data-table-component';
import { IThunkAction } from 'types/IThunkAction';
import useSearchParamsFilter from 'client/hooks/useSearchParamsFilter';
import { DataTableHandle, SearchQuery } from './types';
import DataTableHeaderContainer from './DataTableHeaderContainer';
type IProps<T extends unknown> = {
  getData: (searchQuery: SearchQuery<T>) => IThunkAction;
  onAddButtonClick?: () => void;
  myRef?: ForwardedRef<DataTableHandle>;
  title?: string;
};
export const useDataTable = <T extends unknown>({
  getData,
  onAddButtonClick,
  myRef,
  title,
}: IProps<T>) => {
  const dispatch = useDispatch();

  const { handleFilterSearch, searchParams } = useSearchParamsFilter({});

  useEffect(() => {
    const paramsObject: { [key: string]: string | number } = {};

    searchParams.forEach((value: string, key: string) => {
      if (key === 'direction' || key === 'limit') {
        paramsObject[key] = Number(value);
      }
      paramsObject[key] = value;
    });

    dispatch(getData(paramsObject as SearchQuery<T>));
  }, [dispatch, getData, searchParams]);

  useImperativeHandle(
    myRef,
    () => ({
      getData: () => {
        dispatch(getData({}));
      },
    }),
    [dispatch, getData],
  );

  const handlePageChange = useCallback(
    (page: number, _totalRows: number) => {
      handleFilterSearch('page', `${page}`);
    },
    [handleFilterSearch],
  );

  const handleSort = useCallback(
    (column: TableColumn<T>, sortDirection: 'asc' | 'desc') => {
      handleFilterSearch('direction', sortDirection === 'asc' ? '1' : '-1');
      handleFilterSearch('field', `${column.sortField || column.name}`);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [handleFilterSearch],
  );

  const handlePerRowsChange = useCallback(
    async (newPerPage: number, page: number) => {
      handleFilterSearch('limit', `${newPerPage}`);
    },
    [handleFilterSearch],
  );

  const headerComponent = useMemo(
    () => <DataTableHeaderContainer title={title} onAddButtonClick={onAddButtonClick} />,
    [onAddButtonClick, title],
  );

  return {
    handlePageChange,
    handleSort,
    handlePerRowsChange,
    headerComponent,
    searchParams,
  };
};
