import useNotification from 'client/hooks/useNotification';
import * as React from 'react';

const NotificationContainer = () => {
  useNotification();

  return <></>;
};

export default NotificationContainer;
