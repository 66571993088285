import React from 'react';
import { IPaymentHistory } from 'server/models/PaymentHistory/types';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTableColumnsWithActions } from 'client/containers/DataTableContainer/useTableColumnsWithActions';
import { IUserPackage } from 'server/models/Package/types';
import { format } from 'date-fns';
import BarCode from 'react-barcode';

export interface PackagesRowsProps {
  data: IPaymentHistory;
}
const ExpandedRows: React.FC<PackagesRowsProps> = ({ data }) => {
  const { packages } = data;

  const PackagesTableColumns = (): TableColumn<IUserPackage>[] => [
    {
      name: 'code a bare',
      cell: (e: IUserPackage) => <BarCode width={1} height={40} value={e.barCode} />,
      center: true,
    },
    {
      name: 'Nom du client',
      selector: (e: IUserPackage) => `${e.client.name}`,
      center: true,
    },
    {
      name: 'Telephone du client',
      selector: (e: IUserPackage) => `${e.client.clientPhoneNumber}`,
      center: true,
    },
    {
      name: 'Etat',
      selector: (e: IUserPackage) => `${e.state}`,
      center: true,
    },
    {
      name: 'Echange',
      selector: (e: IUserPackage) => `${e.exchangePackage ? 'Oui' : 'Non'}`,
      center: true,
    },
    {
      sortField: 'createdAt',
      name: 'Date de creation',
      cell: (e: IUserPackage) =>
        e.createdAt && format(new Date(e.createdAt), 'MM/dd/yyyy HH:mm'),
      sortable: true,
      center: true,
    },
  ];
  const columns = useTableColumnsWithActions({
    columns: PackagesTableColumns(),
    buttons: [],
  });

  return (
    <div className="container my-5">
      <div className="row d-flex justify-content-center">
        <DataTable data={packages as IUserPackage[]} columns={columns} />
      </div>
    </div>
  );
};

export default ExpandedRows;
