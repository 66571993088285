import { IUserPackageDocument } from 'server/models/Package/types';
import { PackageStateEnum } from 'types/Enums/PackageStateEnum';
import { FormFelidType } from 'types/FormFelidTypes';
import { ISelectorOption } from 'types/ISelectorOption';

export const formFields = (
  availablePackageStateToUpdate: PackageStateEnum[],
  itemToUpdateOrDelete?: IUserPackageDocument,
  isUpdate?: boolean,
  cityOptions?: ISelectorOption<string>[],
  stateOptions?: ISelectorOption<string>[],
  isAdmin?: boolean,
) => {
  const priceAvailable = isAdmin
    ? false
    : isUpdate &&
      itemToUpdateOrDelete &&
      !availablePackageStateToUpdate.includes(itemToUpdateOrDelete?.state);
  const clientInfoFields: FormFelidType[] = [
    {
      label: 'Nom du client',
      name: 'client.name',
      placeholder: 'Nom du client',
      col: 'col-md-6',
      type: 'text',
      fieldType: 'input',
    },
    {
      label: 'Telephone du client',
      name: 'client.clientPhoneNumber',
      placeholder: 'Telephone du client',
      col: 'col-md-6',
      type: 'number',
      fieldType: 'input',
    },
  ];
  const packageInfoFields: FormFelidType[] = [
    {
      label: "Nom d'article",
      name: 'articleName',
      placeholder: "Nom d'article",
      col: 'col-md-4',
      type: 'text',
      fieldType: 'input',
    },
    {
      label: "Nombre d'article",
      name: 'articlesNumber',
      placeholder: "Nombre d'article",
      col: 'col-md-4',
      type: 'number',
      fieldType: 'input',
    },
    {
      label: "Prix d'article",
      name: 'price',
      placeholder: "Prix d'article",
      col: 'col-md-4',
      type: 'number',
      isDisable: priceAvailable,
      fieldType: 'input',
    },
  ];
  const addressInfoFields: FormFelidType[] = [
    {
      label: 'Gouvernemet du client',
      name: 'destination.state',
      placeholder: 'Gouvernemet',
      col: 'col-md-4',
      type: 'text',
      fieldType: 'select',
      options: stateOptions,
    },
    {
      label: 'Adresse du client',
      name: 'destination.city',
      placeholder: 'Adresse',
      col: 'col-md-4',
      type: 'text',
      fieldType: 'select',
      options: cityOptions,
    },
    {
      label: 'Ville du client',
      name: 'destination.address',
      placeholder: 'Ville',
      col: 'col-md-4',
      type: 'text',
      fieldType: 'input',
    },
  ];
  const extraInfoFields: FormFelidType[] = [
    {
      id: 'isDelicate',
      label: 'colis fragile',
      name: 'isDelicate',
      placeholder: 'colis fragile',
      col: 'col-md-6 col-sm-12',
      type: 'text',
      fieldType: 'switchBox',
    },
    {
      id: 'exchangePackage',
      label: 'colis à échanger',
      name: 'exchangePackage',
      placeholder: 'colis à échanger',
      col: 'col-md-6 col-sm-12',
      type: 'number',
      fieldType: 'switchBox',
    },
  ];

  return { clientInfoFields, packageInfoFields, addressInfoFields, extraInfoFields };
};
