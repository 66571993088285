import { getAddressById } from 'client/_redux/actions/Address';
import { RootState } from 'client/_redux/reducers';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IAddressDocument } from 'server/models/Address/types';

import AddressForm from './AddressForm';

const Address = () => {
  const addressId = useSelector(
    ({ user: { user } }: RootState) => (user?.address as IAddressDocument)?._id,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAddressById(addressId as string));
  }, [addressId, dispatch]);
  const address = useSelector(({ user: { address } }: RootState) => address);
  const isLoading = useSelector(({ loading: { loading } }: RootState) => loading);

  return (
    <>
      <div className="d-flex justify-content-center">
        <div className="page-header w-90">
          <div className="page-header-text my-2 pl-4">Mon adresse</div>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <div className="col-8">{!isLoading && <AddressForm address={address} />}</div>
      </div>
    </>
  );
};

export default Address;
