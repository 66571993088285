import React from 'react';
import { IUserPackageDocument } from 'server/models/Package/types';
import { format } from 'date-fns';
import BarCode from 'react-barcode';
import InvoiceHeader from './InvoiceHeader';

type InvoiceContentProps = {
  invoiceRef: React.MutableRefObject<null>;
  parcels: IUserPackageDocument[];
};
const MultiPackageInvoiceContent: React.FC<InvoiceContentProps> = ({
  invoiceRef,
  parcels,
}) => {
  const tableRow = (parcel: IUserPackageDocument) => (
    <tr key={parcel?._id}>
      <td style={{ textAlign: 'left' }}>
        <BarCode width={1} height={40} value={parcel?.barCode} />
      </td>
      <td className="h3" style={{ textAlign: 'left' }}>
        {parcel?.barCode}
      </td>
      <td className="h3" style={{ textAlign: 'left' }}>
        {parcel?.client.name}
      </td>
      <td className="h3" style={{ textAlign: 'right' }}>
        {parcel?.client.clientPhoneNumber}
      </td>
      <td className="h3" style={{ textAlign: 'right' }}>
        {parcel?.articlesNumber}
      </td>
      <td className="h3" style={{ textAlign: 'right' }}>
        {parcel?.price} DT
      </td>
      <td className="h3" style={{ textAlign: 'right' }}>
        {format(new Date(parcel?.createdAt), 'MM/dd/yyyy')}
      </td>
    </tr>
  );
  const tabHeader = () => (
    <thead>
      <tr>
        <th>Code à barre</th>
        <th>Numero</th>
        <th>Nom de destinataire</th>
        <th>Telephone</th>
        <th>Quantité</th>
        <th>Montant total HT</th>
        <th>Date</th>
      </tr>
    </thead>
  );
  const maxRows = 22;

  const total = parcels.map((parcel) => parcel.price).reduce((prev, curr) => prev + curr, 0);

  return (
    <div ref={invoiceRef} className="factureContainer factureInfo ">
      <div className="ml-5 mr-5 mt-3">
        <InvoiceHeader />

        <div>
          <table
            cellSpacing={3}
            cellPadding={5}
            className="table table-bordered"
            style={{ border: 'solid' }}
          >
            {tabHeader()}
            {parcels?.map((parcel, index) => {
              if ((index + 1) % maxRows === 0) {
                return (
                  <>
                    {tableRow(parcel)}
                    {parcels?.length > maxRows && <div className="page-counter" />}
                    <tr className="x" />
                  </>
                );
              }

              return <>{tableRow(parcel)}</>;
            })}
          </table>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <div className="fiscal-information rounded">
          <div className="rounded mb-3 mt-3" style={{ border: 'solid', width: 350 }}>
            <div className="m-2">
              <div className="d-flex justify-content-between">
                <span className="h2 pr-1">Montant HT:</span>
                <span>{total.toFixed(2)} DT</span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="h2 pr-1">TVA 19%:</span>
                <span>{(total * 0.19).toFixed(2)} DT</span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="h2 pr-1">Total en TTC:</span>
                <span>{(total + total * 0.19).toFixed(2)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiPackageInvoiceContent;
