import Input from 'client/components/Form/Input';
import useSearchParamsFilter from 'client/hooks/useSearchParamsFilter';
import { getAllPackagesManager } from 'client/_redux/actions/packages';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

const PackageFilter: React.FC = () => {
  const { handleFilterSearch, searchParams, resetFilter } = useSearchParamsFilter({});
  const dispatch = useDispatch();
  const [selectedFilter, setSelectedFilter] = useState('all');

  return (
    <div className="d-flex justify-content-between align-items-center w-100 flex-wrap pt-4 flex-wrap no-gutters">
      <div className="d-flex justify-content-between align-items-center">
        <Input
          type="text"
          placeholder="Rechercher..."
          value={searchParams.get('keyword') || ''}
          onChange={(e) => handleFilterSearch('keyword', e.currentTarget.value)}
          name="keyword"
        />
        <button
          type="button"
          className={`btn-sm btn  m-1 ${
            selectedFilter === 'fast' ? 'btn-primary' : 'btn-outline-primary'
          }`}
          onClick={() => {
            dispatch(getAllPackagesManager({ isFastPackage: true }));
            setSelectedFilter('fast');
          }}
        >
          Colis rapide
        </button>
        <button
          type="button"
          className={`btn-sm btn  m-1 ${
            selectedFilter === 'normal' ? 'btn-primary' : 'btn-outline-primary'
          }`}
          onClick={() => {
            dispatch(getAllPackagesManager({ isFastPackage: false }));
            setSelectedFilter('normal');
          }}
        >
          Colis normal
        </button>
        <button
          type="button"
          className={`btn-sm btn  m-1 ${
            selectedFilter === 'all' ? 'btn-primary' : 'btn-outline-primary'
          }`}
          onClick={() => {
            dispatch(getAllPackagesManager({ isFastPackage: undefined }));
            setSelectedFilter('all');
          }}
        >
          Tous les colis
        </button>
      </div>
      <div>
        <button
          type="button"
          className="btn-sm btn btn-outline-primary m-1"
          onClick={() => resetFilter()}
        >
          Reset
        </button>
      </div>
    </div>
  );
};

export default PackageFilter;
