import React from 'react';
import { TableColumn } from 'react-data-table-component';
type IProps<T extends unknown> = {
  columns: TableColumn<T>[];
  buttons: {
    fn?: (item: T) => void;
    color?: string;
    className: (item: T) => string;
    disabled?: (item: T) => boolean | undefined;
  }[];
};

export const useTableColumnsWithActions = <T extends unknown>({
  columns,
  buttons,
}: IProps<T>) => {
  const icons = buttons.map(({ className, fn, color, disabled }) => ({
    sortable: false,
    center: true,
    cell: (row: T) => (
      <div className="d-flex mr-1">
        <i
          style={{ color: disabled && disabled(row) ? '#dddddd' : color, cursor: 'pointer' }}
          className={className(row)}
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            disabled && disabled(row) ? null : fn && fn(row);
          }}
        />
      </div>
    ),
    button: true,
    ignoreRowClick: true,
    minWidth: '3vw',
  }));
  const TableColumns: TableColumn<T>[] = [...columns, ...icons];

  return TableColumns;
};
