import { Route } from 'react-router-dom';
import * as React from 'react';
import AcceptPackages from 'client/pages/Manager/AcceptPackages';
import ManagerPickupList from 'client/pages/Manager/ManagerPickupList';
import RunsheetList from 'client/pages/Manager/Runsheet';
import CreateRunSheet from 'client/pages/Manager/CreateRunsheet';
import PackagesStatisticPage from 'client/pages/Expeditor/Home';
import ConfirmRunsheet from 'client/pages/Manager/ConfirmRunsheet';
import ExpeditorReturn from 'client/pages/Manager/ExpedetorReturn';
import CreateManifest from 'client/pages/Manager/CreateManifest';
import AdminPackagesPage from 'client/pages/Dashboard/AdminPackages';
import { UserRolesEnum } from 'types/Enums/UserRolesEnum';
import { renderClassicRotes } from './DefaultRoutes';

export const renderManagerRoutes = (isLoggedIn: boolean, role: UserRolesEnum) => {
  if (isLoggedIn)
    return (
      <>
        {renderClassicRotes()}
        <Route
          path="/pickup"
          element={
            <div className="pt-10">
              <ManagerPickupList />
            </div>
          }
        />
        <Route
          path="/colis"
          element={
            <div className="pt-10">
              <AdminPackagesPage isAdmin={role === UserRolesEnum.ROLE_ADMIN} />
            </div>
          }
        />
        <Route
          path="/accepter-magasin"
          element={
            <div className="pt-10">
              <AcceptPackages />
            </div>
          }
        />
        <Route
          path="/ajouter-runsheet"
          element={
            <div className="pt-10">
              <CreateRunSheet />
            </div>
          }
        />
        <Route
          path="/modifier-runsheet/:id"
          element={
            <div className="pt-10">
              <CreateRunSheet isUpdate />
            </div>
          }
        />
        <Route
          path="/confirmer-runsheet/:id"
          element={
            <div className="pt-10">
              <ConfirmRunsheet />
            </div>
          }
        />
        <Route
          path="/runsheet"
          element={
            <div className="pt-10">
              <RunsheetList isAdmin={role === UserRolesEnum.ROLE_ADMIN} />
            </div>
          }
        />
        <Route
          path="/retour-expediteur"
          element={
            <div className="pt-10">
              <ExpeditorReturn />
            </div>
          }
        />
        <Route
          path="/retour-expediteur/:id"
          element={
            <div className="pt-10">
              <CreateManifest />
            </div>
          }
        />
        <Route
          path="/statistique"
          element={
            <div className="pt-10">
              <PackagesStatisticPage role={role} />
            </div>
          }
        />
      </>
    );

  return renderClassicRotes();
};
