/* eslint-disable @typescript-eslint/ban-ts-comment */
import { GroupTypeBase, Styles } from 'react-select';
import { ISelectorOption } from 'types/ISelectorOption';
import { CSSObject } from '@emotion/serialize';
import CSS from 'csstype';

const customStyles: Partial<
  Styles<ISelectorOption, boolean, GroupTypeBase<ISelectorOption>>
  // @ts-ignore
> = (style?: CSS.Properties) => ({
  // @ts-ignore
  option: (provided, state): CSSObject => {
    const focusedControlStyles: CSSObject = {
      // label: 'option',
      backgroundColor: '#006aff',
      color: 'white',
      cursor: 'default',
      display: 'block',
      fontSize: 'inherit',
      padding: '8px 12px',
      width: '100%',
      userSelect: 'none',
      WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
      // active: { backgroundColor: 'rgba(247, 148, 28, 0.3)' },
      boxSizing: 'border-box',
    };
    const selectedControlStyles: CSSObject = {
      // label: 'option',
      backgroundColor: '#006aff',
      color: 'inherit',
      cursor: 'default',
      display: 'block',
      fontSize: 'inherit',
      padding: '8px 12px',
      width: '100%',
      userSelect: 'none',
      WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
      // active: { backgroundColor: 'rgba(247, 148, 28, .7)' },
      boxSizing: 'border-box',
    };

    if (state.isSelected) return selectedControlStyles;
    if (state.isFocused) return focusedControlStyles;

    return provided as CSSObject;
  },
  // @ts-ignore
  control: (provided, state): CSSObject => {
    // @ts-ignore
    const focusedControlStyles: CSSObject = {
      margin: 'auto',
      alignItems: 'center',
      backgroundColor: 'hsl(0, 0%, 100%)',
      boxShadow: '0 1px 5px rgb(0 0 0 / 10%)',
      borderRadius: 8,
      height: 60,
      borderColor: '#006aff',
      borderStyle: 'solid',
      borderWidth: 1,
      cursor: 'default',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      outline: '0 !important',
      position: 'relative',
      transition: 'all 100ms',
      boxSizing: 'border-box',
      ...style,
    };
    // @ts-ignore
    const controlStyles: CSSObject = {
      margin: 'auto',
      alignItems: 'center',
      backgroundColor: 'hsl(0, 0%, 100%)',
      boxShadow: '0 1px 5px rgb(0 0 0 / 10%)',
      borderRadius: 8,
      height: 60,
      cursor: 'default',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      outline: '0 !important',
      position: 'relative',
      transition: 'all 100ms',
      boxSizing: 'border-box',
      ...style,
    };

    return state.isFocused ? focusedControlStyles : controlStyles;
  },
});

export const errorStyles: Partial<
  Styles<ISelectorOption, boolean, GroupTypeBase<ISelectorOption>>
> = {
  option: (provided, state) => customStyles.option?.(provided, state) || {},
  control: () => {
    const controlHasErrorStyle: CSSObject = {
      alignItems: 'center',
      backgroundColor: 'hsl(0, 0%, 100%)',
      borderColor: '#ed4c78',
      boxShadow: '0 1px 5px rgb(0 0 0 / 10%)',
      borderRadius: 8,
      height: 60,
      borderStyle: 'solid',
      borderWidth: 1,
      cursor: 'default',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      outline: '0 !important',
      position: 'relative',
      transition: 'all 100ms',
      boxSizing: 'border-box',
    };

    return controlHasErrorStyle;
  },
};

export default customStyles;
