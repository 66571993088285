import ScanInput from 'client/components/Form/ScanInput';
import { managerScanPackages } from 'client/_redux/actions/packages';

import React from 'react';
import DataTable from 'react-data-table-component';
import { FormProvider, useForm } from 'react-hook-form';
import { useAcceptPackages } from './useAcceptPackages';

const AcceptPackages = () => {
  const { scannedPackages, columns } = useAcceptPackages();
  const formMethods = useForm();

  return (
    <div>
      <div className="d-flex justify-content-center">
        <div className="page-header w-90">
          <div className="page-header-text my-2 pl-4">Accepter magasin</div>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <div className="col-8">
          <FormProvider {...formMethods}>
            <ScanInput
              id="barCode"
              name="barCode"
              action={(code) => managerScanPackages(code)}
            />
          </FormProvider>
        </div>
      </div>
      <div className="d-flex justify-content-around flex-wrap">
        <DataTable data={scannedPackages} columns={columns} />
      </div>
    </div>
  );
};

export default AcceptPackages;
