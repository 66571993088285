import { RootState } from 'client/_redux/reducers';
import React from 'react';
import { useSelector } from 'react-redux';
import { RunsheetValidation } from 'types/RunsheetValidationType';
import RunsheetHeader from '../Runsheet/RunsheetHeader';

type DriverInvoiceContentProps = {
  invoiceRef: React.MutableRefObject<null>;
  runsheet: RunsheetValidation;
};
const DriverInvoiceContent: React.FC<DriverInvoiceContentProps> = ({
  invoiceRef,
  runsheet,
}) => {
  const user = useSelector(({ user: { user } }: RootState) => user);
  const { total, payedByCheck, payedCash } = runsheet;
  const tableRow = () => (
    <tbody>
      <tr>
        <td>{runsheet.rd}</td>
        <td>{runsheet.re}</td>
        <td>{runsheet.deliveredPackages.length}</td>
        <td>{runsheet.deliveredPackages.length + runsheet.re + runsheet.rd}</td>
      </tr>
    </tbody>
  );
  const tabHeader = () => (
    <thead>
      <tr>
        <th>Retour Dépo</th>
        <th>Retour Définitive</th>
        <th>Livrée</th>
        <th>Total</th>
      </tr>
    </thead>
  );

  return (
    <div ref={invoiceRef} className="factureContainer factureInfo ">
      <div className="ml-5 mr-5 mt-3">
        {runsheet.runsheet && <RunsheetHeader runsheet={runsheet.runsheet} />}
        <div>
          <table className="table table-bordered" style={{ border: 'solid' }}>
            {tabHeader()}
            {tableRow()}
          </table>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <div className="fiscal-information rounded">
          <div className="rounded mb-3 mt-3" style={{ border: 'solid', width: 350 }}>
            <div className="m-2">
              <div className="d-flex justify-content-between">
                <span className="h5 pr-1">Payé par chéque:</span>
                <span>{payedByCheck} DT</span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="h5 pr-1">Payé éspece:</span>
                <span>{payedCash} DT</span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="h5 pr-1">Total:</span>
                <span>{total} DT</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <div className="d-flex justify-content-between">
          <div className="fiscal-information rounded m-1">
            <div
              className="rounded mb-3 mt-3"
              style={{ border: 'solid', width: 350, height: 200 }}
            >
              <div className="d-flex justify-content-center">
                <span>Manager: </span>
                <span
                  style={{ fontWeight: 'bold' }}
                >{`${user?.firstName} ${user?.lastName}`}</span>
              </div>
              <div className="d-flex justify-content-center">Signature</div>
            </div>
          </div>
          <div className="fiscal-information rounded m-1">
            <div
              className="rounded mb-3 mt-3"
              style={{ border: 'solid', width: 350, height: 200 }}
            >
              <div className="d-flex justify-content-center">
                <span>Livreur: </span>
                <span style={{ fontWeight: 'bold' }}>
                  {runsheet?.runsheet?.driver?.firstName}{' '}
                  {runsheet?.runsheet?.driver?.lastName}
                </span>
              </div>
              <div className="d-flex justify-content-center">Signature</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriverInvoiceContent;
