import { TableColumn } from 'react-data-table-component';
import { IAddress } from 'server/models/Address/types';
import { IUser } from 'server/models/User/types';

export const ExpeditorColumns = (): TableColumn<IUser>[] => [
  {
    name: 'Nom du expedituer',
    selector: (e) => `${e.firstName}`,
    center: true,
    sortable: true,
    sortField: 'firstName',
    style: {
      fontWeight: 'bold',
    },
  },
  {
    name: 'Telephone',
    selector: (e) => `${e.phoneNumber}`,
    center: true,
    sortable: true,
    sortField: 'phoneNumber',
  },
  {
    name: 'VIP',
    selector: (e) => `${e.isVIP ? 'Oui' : 'Non'}`,
    center: true,
    sortable: true,
    sortField: 'isVIP',
  },
  {
    name: 'Adresse',
    selector: (e) =>
      `${(e.address as IAddress)?.address}, ${(e.address as IAddress)?.city}, ${
        (e.address as IAddress)?.country
      }`,
    center: true,
  },
  {
    name: 'Nombre de colis',
    selector: (e) => e.packages.length,
    center: true,
  },
];
