import { format } from 'date-fns';
import React from 'react';
import { IUserPackage, IUserPackageDocument } from 'server/models/Package/types';

import { IRunsheetDocument } from 'server/models/Runsheet/types';
import NextPackSignature from '../Invoice/NextPackSignature';

type RunsheetHeaderProps = {
  runsheet: IRunsheetDocument;
};
const RunsheetHeader: React.FC<RunsheetHeaderProps> = ({ runsheet }) => {
  const total = (runsheet.packagesList as IUserPackageDocument[])
    .map((parcel) => parcel.price)
    .reduce((prev, curr) => prev + curr, 0);

  return (
    <>
      <div className="d-flex justify-content-between pb-3">
        <NextPackSignature />
        <div className="fiscal-information rounded ">
          <div className="rounded mb-3 mt-3" style={{ border: 'solid', width: 350 }}>
            <div className="m-2">
              <div className="d-flex justify-content-between">
                <span className="h5 pr-1">Nom de livreur:</span>
                <span>
                  {runsheet?.driver?.firstName} {runsheet?.driver?.lastName}
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="h5 pr-1">Matricule de voiture:</span>
                <span>{runsheet?.carSerialNumber}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="h5 pr-1">Consemation:</span>
                <span>{runsheet?.fuel} DT</span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="h5 pr-1">Date:</span>
                <span>
                  {runsheet?.createdAt &&
                    format(new Date(runsheet?.createdAt), 'dd-MM-yyyy hh:mm')}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between  pb-3 align-items-center ">
        <div className="d-flex justify-content-center">
          <span className="h1">{`Runsheet N° ${runsheet.codeRunsheet}`}</span>
        </div>
        <div className="d-flex justify-content-center mx-1">
          <div className="fiscal-information rounded">
            <div className="rounded mb-3 mt-3" style={{ border: 'solid', width: 350 }}>
              <div className="m-2">
                <div className="d-flex justify-content-between">
                  <span className="h5 pr-1">Montant Total:</span>
                  <span>{total} DT</span>
                </div>
                <div className="d-flex justify-content-between">
                  <span className="h5 pr-1">Nombre des colis:</span>
                  <span>{runsheet.packagesList.length}</span>
                </div>
                <div className="d-flex justify-content-between">
                  <span className="h5 pr-1">Nombre des colis échange:</span>
                  <span>
                    {
                      (runsheet.packagesList as IUserPackage[]).filter(
                        (pack) => pack.exchangePackage,
                      ).length
                    }
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RunsheetHeader;
