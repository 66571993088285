import RunsheetForm from 'client/containers/RunsheetForm';
import React from 'react';

type CreateRunSheetProps = {
  isUpdate?: boolean;
};
const CreateRunSheet: React.FC<CreateRunSheetProps> = ({ isUpdate }) => (
  <div className="container">
    <RunsheetForm isUpdate={isUpdate} />
  </div>
);

export default CreateRunSheet;
