/* eslint-disable max-len */
import React from 'react';
import { useUserDetails } from './useUserDetails';

const UserDetailsPage = () => {
  const { user } = useUserDetails();

  return (
    <div className="container-fluid my-5">
      {user && (
        <div className="row">
          <div className="col-md-4 col-12">
            <div className="card card-primary card-outline">
              <div className="card-body">
                <div className="text-center">
                  {/* <img
                    className="profile-user-img img-fluid img-circle"
                    src={getImageUrl((user?.avatar as IMedia).uri)}
                    alt="User profile"
                  /> */}
                </div>
                <h3 className="profile-username text-center">{`${user?.firstName} ${user?.lastName}`}</h3>
                <p className="text-muted text-center">{`${user?.email}`}</p>
                <ul className="list-group list-group-unbordered mb-3">
                  <li className="list-group-item row">
                    <p className="text-dark font-weight-bold font-size-1 mb-0 col-12 col-md-6 px-1">
                      Phone
                    </p>
                    {user?.phoneNumber && (
                      <p className="float-right font-size-1 text-right text-primary mb-0 col-12 col-md-6 px-1">{`${user?.phoneNumber}`}</p>
                    )}
                  </li>
                  <li className="list-group-item row">
                    <p className="text-dark font-weight-bold font-size-1 mb-0 col-12 col-md-6 px-1">
                      Role
                    </p>
                    <p className="float-right font-size-1  text-right text-primary mb-0 col-12 col-md-6 px-1">{`${user?.role}`}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-8 col-12">
            <div className="card">
              <div className="card-body">
                <h3 className="mb-1">{`${user?.firstName} ${user?.lastName}`}</h3>
                <hr />
                <ul className="px-0">
                  <li className="list-group-item">
                    <p className="float-right font-size-1 text-primary mb-0">{`${
                      user?.isBanned ? 'Utilisateur Banni' : 'Actif'
                    }`}</p>
                  </li>
                  <li className="list-group-item">
                    <p className="text-dark font-weight-bold font-size-1 mb-0">Transaction</p>
                    <p className="float-right font-size-1 text-primary mb-0">52</p>
                  </li>

                  <li className="list-group-item">
                    <p className="text-dark font-weight-bold font-size-1 mb-0">Billing</p>
                  </li>
                  <li className="list-group-item">
                    <p className="text-dark font-weight-bold font-size-1 mb-0">
                      shippingAddress
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDetailsPage;
