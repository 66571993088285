/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { IAddressDocument } from 'server/models/Address/types';
import { IUserPackageDocument } from 'server/models/Package/types';
import { IUserDocument } from 'server/models/User/types';
import NextPackSignature from './NextPackSignature';
import { QrCode } from './QrCodeGenerator';

type InvoiceHeaderProps = {
  parcel?: IUserPackageDocument;
  expeditor?: IUserDocument;
};

const InvoiceHeader: React.FC<InvoiceHeaderProps> = ({ parcel, expeditor }) => (
  <>
    <div className="d-flex justify-content-between pb-3">
      <NextPackSignature />
      <div className="fiscal-information rounded ">
        <div className="rounded mb-3 mt-3" style={{ border: 'solid', width: 350 }}>
          <table className="ml-2">
            <tbody>
              <tr>
                <td>
                  <span className="h5 pr-1">Nom de l'Expéditeur:</span>
                  <span>
                    {parcel?.expeditor?.firstName || expeditor?.firstName}{' '}
                    {parcel?.expeditor?.lastName || expeditor?.lastName}
                  </span>
                </td>
              </tr>
              {parcel && (
                <tr>
                  <span className="h5 pr-1">Adresse:</span>
                  <span>
                    {(parcel?.expeditor?.address as IAddressDocument)?.city},
                    {(parcel?.expeditor?.address as IAddressDocument)?.country},
                    {(parcel?.expeditor?.address as IAddressDocument)?.state},
                    {(parcel?.expeditor?.address as IAddressDocument)?.address}
                  </span>
                </tr>
              )}
              <tr>
                <span className="h5 pr-1">Telephone:</span>
                <span>{parcel?.expeditor?.phoneNumber || expeditor?.phoneNumber}</span>
              </tr>
              <tr>
                <span className="h5 pr-1">M/F:</span>
                <span>{parcel?.expeditor?.taxNumber || expeditor?.taxNumber}</span>
              </tr>
            </tbody>
          </table>
        </div>
        {parcel && (
          <div className="d-flex justify-content-end ">
            <QrCode url={String(parcel.barCode)} />
          </div>
        )}
      </div>
    </div>
    {parcel && parcel.exchangePackage ? (
      <div className="d-flex justify-content-center my-9 ">
        <div
          className="d-flex justify-content-center rounded border-danger py-3 mb-5"
          style={{ border: 'solid', width: 350 }}
        >
          <span className="h3 text-danger">Bon de livraison échange</span>
        </div>
      </div>
    ) : (
      <div className="d-flex justify-content-center my-9 ">
        <div
          className="d-flex justify-content-center rounded py-3 mb-5"
          style={{ border: 'solid' }}
        >
          <span className="h3 px-4">Bon de livraison N° {String(parcel?.barCode)}</span>
        </div>
      </div>
    )}
  </>
);

export default InvoiceHeader;
