/* eslint-disable max-lines */

export const cities = [
  {
    id: 106915,
    name: 'Ariana Ville',
    latitude: '36.86012000',
    longitude: '10.19337000',
    state_code: 'Ariana',
  },
  {
    id: 106944,
    name: 'El Menzah',
    latitude: '33.48485000',
    longitude: '9.00678000',
    state_code: 'Ariana',
  },
  {
    id: 106964,
    name: ' Naser',
    latitude: '33.57778000',
    longitude: '9.01472000',
    state_code: 'Ariana',
  },
  {
    id: 106955,
    name: 'Ettadhamen',
    latitude: '37.06290000',
    longitude: '10.11829000',
    state_code: 'Ariana',
  },
  {
    id: 106920,
    name: 'Kalaat Landlous',
    latitude: '36.75452000',
    longitude: '10.22167000',
    state_code: 'Ariana',
  },
  {
    id: 106959,
    name: 'La Soukra',
    latitude: '36.71919000',
    longitude: '10.32233000',
    state_code: 'Ariana',
  },
  {
    id: 106961,
    name: 'Mnihla',
    latitude: '36.72866000',
    longitude: '10.34163000',
    state_code: 'Ariana',
  },
  {
    id: 106977,
    name: 'Raoued',
    latitude: '36.67931000',
    longitude: '10.29195000',
    state_code: 'Ariana',
  },
  {
    id: 106970,
    name: 'borj louzir',
    latitude: '33.70439000',
    longitude: '8.96903000',
    state_code: 'Ariena',
  },

  {
    id: 107007,
    name: 'Sidi Thabet',
    latitude: '36.76946000',
    longitude: '10.27468000',
    state_code: 'Ariena',
  },
  {
    id: 107700,
    name: 'el manar',
    latitude: '36.76946000',
    longitude: '10.27468000',
    state_code: 'Ariena',
  },

  {
    id: 106951,
    name: 'Bab El Bhar',
    latitude: '36.65547000',
    longitude: '8.69602000',
    state_code: 'Tunis',
  },
  {
    id: 106965,
    name: 'Bab Souika',
    latitude: '36.48519000',
    longitude: '8.82325000',
    state_code: 'Tunis',
  },
  {
    id: 107005,
    name: 'Carthage',
    latitude: '36.46813000',
    longitude: '8.54951000',
    state_code: 'Tunis',
  },
  {
    id: 107030,
    name: 'Cité El Khadra',
    latitude: '36.95442000',
    longitude: '8.75801000',
    state_code: 'Tunis',
  },
  {
    id: 106913,
    name: 'Djebel Jelloud',
    latitude: '37.24516000',
    longitude: '10.05000000',
    state_code: 'Tunis',
  },
  {
    id: 106926,
    name: 'El Kabaria',
    latitude: '37.27442000',
    longitude: '9.87391000',
    state_code: 'Tunis',
  },
  {
    id: 106927,
    name: 'El Omrane',
    latitude: '37.25528000',
    longitude: '9.67915000',
    state_code: 'Tunis',
  },
  {
    id: 106938,
    name: 'El Omrane supérieur',
    latitude: '37.16667000',
    longitude: '9.75000000',
    state_code: 'Tunis',
  },
  {
    id: 106941,
    name: 'El Ouardia',
    latitude: '37.16911000',
    longitude: '10.03478000',
    state_code: 'Tunis',
  },
  {
    id: 106983,
    name: 'Ettahrir',
    latitude: '37.04045000',
    longitude: '9.66557000',
    state_code: 'Tunis',
  },
  {
    id: 106988,
    name: 'Ezzouhour',
    latitude: '37.23737000',
    longitude: '9.86313000',
    state_code: 'Tunis',
  },
  {
    id: 106989,
    name: 'Hraïria',
    latitude: '37.15368000',
    longitude: '9.78594000',
    state_code: 'Tunis',
  },
  {
    id: 106990,
    name: 'La Goulette',
    latitude: '37.23618000',
    longitude: '9.91448000',
    state_code: 'Tunis',
  },
  {
    id: 107008,
    name: 'La Marsa',
    latitude: '37.19043000',
    longitude: '10.18365000',
    state_code: 'Tunis',
  },
  {
    id: 107010,
    name: 'Le Bardo',
    latitude: '37.16939000',
    longitude: '10.19064000',
    state_code: 'Tunis',
  },
  {
    id: 107017,
    name: 'Le Bardo',
    latitude: '37.05722000',
    longitude: '9.23806000',
    state_code: 'Tunis',
  },
  {
    id: 107041,
    name: 'Le Kram',
    latitude: '37.03959000',
    longitude: '10.03876000',
    state_code: 'Tunis',
  },

  {
    id: 106929,
    name: 'Médina',
    latitude: '33.89927000',
    longitude: '9.78496000',
    state_code: 'Tunis',
  },
  {
    id: 106945,
    name: 'Séjoumi',
    latitude: '33.89152000',
    longitude: '9.79629000',
    state_code: 'Tunis',
  },
  {
    id: 106952,
    name: 'Sidi El Béchir',
    latitude: '33.88146000',
    longitude: '10.09820000',
    state_code: 'Tunis',
  },
  {
    id: 106984,
    name: 'Sidi Hassine',
    latitude: '33.54445000',
    longitude: '9.97157000',
    state_code: 'Tunis',
  },
  {
    id: 107701,
    name: 'Ibn khaldoun',
    latitude: '36.76946000',
    longitude: '10.27468000',
    state_code: 'Tunis',
  },

  {
    id: 106958,
    name: 'Ben Arous centre',
    latitude: '35.63235000',
    longitude: '9.67624000',
    state_code: 'Ben Arous',
  },
  {
    id: 106968,
    name: 'Bou Mhel el-Bassatine',
    latitude: '35.67810000',
    longitude: '10.09633000',
    state_code: 'Ben Arous',
  },
  {
    id: 107016,
    name: 'El Mourouj',
    latitude: '35.93325000',
    longitude: '10.02081000',
    state_code: 'Ben Arous',
  },

  {
    id: 106914,
    name: 'Ezzahra',
    latitude: '34.38270000',
    longitude: '8.15549000',
    state_code: 'Ben Arous',
  },
  {
    id: 106916,
    name: 'Fouchana',
    latitude: '34.46280000',
    longitude: '9.26404000',
    state_code: 'Ben Arous',
  },
  {
    id: 106954,
    name: 'Hammam Chott',
    latitude: '34.42500000',
    longitude: '8.78417000',
    state_code: 'Ben Arous',
  },
  {
    id: 106994,
    name: 'Hammam Lif',
    latitude: '34.32081000',
    longitude: '8.40157000',
    state_code: 'Ben Arous',
  },
  {
    id: 107000,
    name: 'Mohamedia',
    latitude: '34.40081000',
    longitude: '8.17057000',
    state_code: 'Ben Arous',
  },

  {
    id: 106969,
    name: 'Medina Jedida',
    latitude: '35.16758000',
    longitude: '8.83651000',
    state_code: 'Ben Arous',
  },
  {
    id: 107011,
    name: 'Mégrine',
    latitude: '35.65129000',
    longitude: '9.05306000',
    state_code: 'Ben Arous',
  },
  {
    id: 107015,
    name: 'Mornag',
    latitude: '35.54332000',
    longitude: '9.07370000',
    state_code: 'Ben Arous',
  },
  {
    id: 107036,
    name: 'Radès',
    latitude: '35.57244000',
    longitude: '8.67031000',
    state_code: 'Ben Arous',
  },

  {
    id: 106930,
    name: 'Borj El Amri',
    latitude: '36.72564000',
    longitude: '9.18169000',
    state_code: 'Manouba',
  },
  {
    id: 106955,
    name: 'Ettadhamen',
    latitude: '37.06290000',
    longitude: '10.11829000',
    state_code: 'Ariana',
  },
  {
    id: 106940,
    name: 'Djedeida',
    latitude: '36.80353000',
    longitude: '9.24984000',
    state_code: 'Manouba',
  },
  {
    id: 106956,
    name: 'Douar Hicher',
    latitude: '36.54178000',
    longitude: '9.66334000',
    state_code: 'Manouba',
  },
  {
    id: 106986,
    name: 'El Batan',
    latitude: '36.64964000',
    longitude: '9.61231000',
    state_code: 'Manouba',
  },
  {
    id: 107031,
    name: 'Mornaguia',
    latitude: '36.45692000',
    longitude: '9.24751000',
    state_code: 'Manouba',
  },
  {
    id: 107035,
    name: 'Oued Ellil',
    latitude: '36.55130000',
    longitude: '9.44307000',
    state_code: 'Manouba',
  },

  {
    id: 106939,
    name: 'Tebourba',
    latitude: '33.46632000',
    longitude: '9.02030000',
    state_code: 'Manouba',
  },
  {
    id: 106939,
    name: 'Manouba centre',
    latitude: '33.46632000',
    longitude: '9.02030000',
    state_code: 'Manouba',
  },
  {
    id: 106917,
    name: 'Seres',
    latitude: '36.07640000',
    longitude: '9.02117000',
    state_code: 'Kef ',
  },
  {
    id: 106947,
    name: 'El Kef nord',
    latitude: '36.17424000',
    longitude: '8.70486000',
    state_code: 'Kef ',
  },
  {
    id: 107708,
    name: 'El kef sud',
    latitude: '35.89174000',
    longitude: '8.55276000',
    state_code: 'Kef ',
  },
  {
    id: 106948,
    name: 'El Ksour',
    latitude: '35.89607000',
    longitude: '8.88493000',
    state_code: 'Kef ',
  },
  {
    id: 106992,
    name: 'Menzel Salem',
    latitude: '35.85673000',
    longitude: '8.47654000',
    state_code: 'Kef ',
  },
  {
    id: 107002,
    name: 'Nebber',
    latitude: '36.29411000',
    longitude: '8.76657000',
    state_code: 'Kef ',
  },
  {
    id: 107013,
    name: 'Sakiet Sidi Youssef',
    latitude: '36.22292000',
    longitude: '8.35547000',
    state_code: 'Kef ',
  },
  {
    id: 107032,
    name: 'Tajerouine',
    latitude: '35.89174000',
    longitude: '8.55276000',
    state_code: 'Kef ',
  },
  {
    id: 107703,
    name: 'kalaat snen',
    latitude: '35.89174000',
    longitude: '8.55276000',
    state_code: 'Kef ',
  },
  {
    id: 107704,
    name: 'Kala khasba',
    latitude: '35.89174000',
    longitude: '8.55276000',
    state_code: 'Kef ',
  },
  {
    id: 107705,
    name: 'Ejrissa',
    latitude: '35.89174000',
    longitude: '8.55276000',
    state_code: 'Kef ',
  },
  {
    id: 107706,
    name: 'Dahmeni',
    latitude: '35.89174000',
    longitude: '8.55276000',
    state_code: 'Kef ',
  },
  {
    id: 107707,
    name: 'Touiref',
    latitude: '35.89174000',
    longitude: '8.55276000',
    state_code: 'Kef ',
  },

  {
    id: 106932,
    name: 'Chebba',
    latitude: '35.23722000',
    longitude: '11.11500000',
    state_code: 'Mahdia ',
  },
  {
    id: 106934,
    name: 'Chorbane',
    latitude: '35.28581000',
    longitude: '10.38580000',
    state_code: 'Mahdia ',
  },
  {
    id: 106946,
    name: 'El Jem',
    latitude: '35.30000000',
    longitude: '10.71667000',
    state_code: 'Mahdia ',
  },
  {
    id: 106974,
    name: 'Ksour Essaf',
    latitude: '35.41808000',
    longitude: '10.99475000',
    state_code: 'Mahdia ',
  },
  {
    id: 106980,
    name: 'Mahdia',
    latitude: '35.50472000',
    longitude: '11.06222000',
    state_code: 'Mahdia ',
  },
  {
    id: 106987,
    name: 'Melloulèche',
    latitude: '35.16617000',
    longitude: '11.03504000',
    state_code: 'Mahdia ',
  },
  {
    id: 107014,
    name: 'Salakta',
    latitude: '35.39444000',
    longitude: '11.04361000',
    state_code: 'Mahdia ',
  },
  {
    id: 107020,
    name: 'Sidi Alouane',
    latitude: '35.37505000',
    longitude: '10.93899000',
    state_code: 'Mahdia ',
  },
  {
    id: 107043,
    name: 'Zouila',
    latitude: '35.50056000',
    longitude: '11.06056000',
    state_code: 'Mahdia ',
  },
  {
    id: 107710,
    name: 'Souasi',
    latitude: '35.50056000',
    longitude: '11.06056000',
    state_code: 'Mahdia ',
  },
  {
    id: 107711,
    name: 'Ouled chamekh',
    latitude: '35.50056000',
    longitude: '11.06056000',
    state_code: 'Mahdia ',
  },

  {
    id: 106921,
    name: 'Ben Gardane',
    latitude: '33.13783000',
    longitude: '11.21965000',
    state_code: 'Medenine ',
  },
  {
    id: 106923,
    name: 'Beni Kheddache',
    latitude: '33.25279000',
    longitude: '10.19883000',
    state_code: 'Medenine ',
  },
  {
    id: 106950,
    name: 'Erriadh',
    latitude: '33.82063000',
    longitude: '10.85394000',
    state_code: 'Medenine ',
  },
  {
    id: 106963,
    name: 'Houmt El Souk',
    latitude: '33.87576000',
    longitude: '10.85745000',
    state_code: 'Medenine ',
  },
  {
    id: 106966,
    name: 'Jerba Midoun',
    latitude: '33.77918000',
    longitude: '10.95215000',
    state_code: 'Medenine ',
  },
  {
    id: 106985,
    name: 'Medenine nord',
    latitude: '33.35495000',
    longitude: '10.50548000',
    state_code: 'Medenine ',
  },
  {
    id: 106713,
    name: 'Medenine sud',
    latitude: '33.35495000',
    longitude: '10.50548000',
    state_code: 'Medenine ',
  },

  {
    id: 107042,
    name: 'Zarzis',
    latitude: '33.50398000',
    longitude: '11.11215000',
    state_code: 'Medenine ',
  },

  {
    id: 106918,
    name: 'Banbalah',
    latitude: '35.70000000',
    longitude: '10.80000000',
    state_code: 'Monastir ',
  },
  {
    id: 106919,
    name: 'Bekalta',
    latitude: '35.61739000',
    longitude: '10.99466000',
    state_code: 'Monastir ',
  },
  {
    id: 106922,
    name: 'Beni Hassane',
    latitude: '35.56720000',
    longitude: '10.80869000',
    state_code: 'Monastir ',
  },
  {
    id: 106937,
    name: 'Djemmal',
    latitude: '35.62231000',
    longitude: '10.75696000',
    state_code: 'Monastir ',
  },
  {
    id: 106972,
    name: 'Ksar Hellal',
    latitude: '35.64773000',
    longitude: '10.89046000',
    state_code: 'Monastir ',
  },
  {
    id: 106973,
    name: 'Ksibet el Mediouni',
    latitude: '35.68561000',
    longitude: '10.84256000',
    state_code: 'Monastir ',
  },
  {
    id: 106979,
    name: 'Lemta',
    latitude: '35.66667000',
    longitude: '10.88333000',
    state_code: 'Monastir ',
  },
  {
    id: 106991,
    name: 'Menzel Kamel',
    latitude: '35.62477000',
    longitude: '10.66727000',
    state_code: 'Monastir ',
  },
  {
    id: 106993,
    name: 'Mesdour',
    latitude: '35.68206000',
    longitude: '10.72746000',
    state_code: 'Monastir ',
  },
  {
    id: 106997,
    name: 'Monastir',
    latitude: '35.77799000',
    longitude: '10.82617000',
    state_code: 'Monastir ',
  },
  {
    id: 107003,
    name: 'Ouardenine',
    latitude: '35.70915000',
    longitude: '10.67397000',
    state_code: 'Monastir ',
  },
  {
    id: 107012,
    name: 'Sahline',
    latitude: '35.75166000',
    longitude: '10.71109000',
    state_code: 'Monastir ',
  },
  {
    id: 107018,
    name: 'Seïada',
    latitude: '35.66887000',
    longitude: '10.89246000',
    state_code: 'Monastir ',
  },
  {
    id: 107021,
    name: 'Sidi Ben Nour',
    latitude: '35.53333000',
    longitude: '10.91667000',
    state_code: 'Monastir ',
  },
  {
    id: 107027,
    name: 'Skanes',
    latitude: '35.78333000',
    longitude: '10.80000000',
    state_code: 'Monastir ',
  },
  {
    id: 107037,
    name: 'Touza',
    latitude: '35.63544000',
    longitude: '10.82732000',
    state_code: 'Monastir ',
  },

  {
    id: 106910,
    name: 'Agareb',
    latitude: '34.74406000',
    longitude: '10.46110000',
    state_code: 'Sfax ',
  },
  {
    id: 106924,
    name: 'Bir Ali Ben Khalifa',
    latitude: '34.73592000',
    longitude: '10.09240000',
    state_code: 'Sfax ',
  },
  {
    id: 106936,
    name: 'Djebeniana',
    latitude: '35.03500000',
    longitude: '10.90809000',
    state_code: 'Sfax ',
  },
  {
    id: 106957,
    name: 'Gremda',
    latitude: '34.75000000',
    longitude: '10.78333000',
    state_code: 'Sfax ',
  },
  {
    id: 107019,
    name: 'Sfax nord',
    latitude: '34.74056000',
    longitude: '10.76028000',
    state_code: 'Sfax ',
  },
  {
    id: 107714,
    name: 'Sfax sud',
    latitude: '34.74056000',
    longitude: '10.76028000',
    state_code: 'Sfax ',
  },
  {
    id: 107028,
    name: 'Skhira',
    latitude: '34.29920000',
    longitude: '10.06999000',
    state_code: 'Sfax ',
  },
  {
    id: 107715,
    name: 'El hencha',
    latitude: '34.29920000',
    longitude: '10.06999000',
    state_code: 'Sfax ',
  },
  {
    id: 107716,
    name: 'Manzel chaker',
    latitude: '34.29920000',
    longitude: '10.06999000',
    state_code: 'Sfax ',
  },
  {
    id: 107717,
    name: 'Sakeit ezit',
    latitude: '34.29920000',
    longitude: '10.06999000',
    state_code: 'Sfax ',
  },
  {
    id: 107718,
    name: 'Sakeit eddaier',
    latitude: '34.29920000',
    longitude: '10.06999000',
    state_code: 'Sfax ',
  },
  {
    id: 107719,
    name: 'Karkena',
    latitude: '34.29920000',
    longitude: '10.06999000',
    state_code: 'Sfax ',
  },
  {
    id: 107720,
    name: 'El mahres',
    latitude: '34.29920000',
    longitude: '10.06999000',
    state_code: 'Sfax ',
  },
  {
    id: 107720,
    name: 'El amra',
    latitude: '34.29920000',
    longitude: '10.06999000',
    state_code: 'Sfax ',
  },
  {
    id: 107721,
    name: 'Ghraiba',
    latitude: '34.29920000',
    longitude: '10.06999000',
    state_code: 'Sfax ',
  },
  {
    id: 106925,
    name: 'Bir el Hafey',
    latitude: '34.93212000',
    longitude: '9.19321000',
    state_code: 'Sidi Bouzid ',
  },
  {
    id: 106830,
    name: 'Essabela ouled asker',
    latitude: '34.93212000',
    longitude: '9.19321000',
    state_code: 'Sidi Bouzid ',
  },
  {
    id: 106949,
    name: 'El Regueb',
    latitude: '34.85932000',
    longitude: '9.78654000',
    state_code: 'Sidi Bouzid ',
  },
  {
    id: 106967,
    name: 'Jelma',
    latitude: '35.27311000',
    longitude: '9.42385000',
    state_code: 'Sidi Bouzid ',
  },
  {
    id: 106995,
    name: 'Mezzouna',
    latitude: '34.57758000',
    longitude: '9.84193000',
    state_code: 'Sidi Bouzid ',
  },
  {
    id: 107024,
    name: 'Sidi Bouzid nord',
    latitude: '35.03823000',
    longitude: '9.48494000',
    state_code: 'Sidi Bouzid ',
  },
  {
    id: 107722,
    name: 'Sidi Bouzid sud',
    latitude: '35.03823000',
    longitude: '9.48494000',
    state_code: 'Sidi Bouzid ',
  },
  {
    id: 107723,
    name: 'Ben oun',
    latitude: '35.03823000',
    longitude: '9.48494000',
    state_code: 'Sidi Bouzid ',
  },
  {
    id: 107724,
    name: 'Manzel bouzayen',
    latitude: '35.03823000',
    longitude: '9.48494000',
    state_code: 'Sidi Bouzid ',
  },
  {
    id: 107725,
    name: 'ouled haffouz',
    latitude: '35.03823000',
    longitude: '9.48494000',
    state_code: 'Sidi Bouzid ',
  },
  {
    id: 107726,
    name: 'el faith',
    latitude: '35.03823000',
    longitude: '9.48494000',
    state_code: 'Sidi Bouzid ',
  },
  {
    id: 106928,
    name: 'Bou Arada',
    latitude: '36.35251000',
    longitude: '9.62175000',
    state_code: 'Siliana ',
  },
  {
    id: 106953,
    name: 'Gafour',
    latitude: '36.32045000',
    longitude: '9.32424000',
    state_code: 'Siliana ',
  },
  {
    id: 106971,
    name: 'Kesra',
    latitude: '35.81363000',
    longitude: '9.36434000',
    state_code: 'Siliana ',
  },
  {
    id: 106978,
    name: 'Le Krib',
    latitude: '36.32802000',
    longitude: '9.13613000',
    state_code: 'Siliana ',
  },
  {
    id: 106981,
    name: 'Maktar',
    latitude: '35.85798000',
    longitude: '9.20072000',
    state_code: 'Siliana ',
  },
  {
    id: 107026,
    name: 'Siliana sud',
    latitude: '36.08497000',
    longitude: '9.37082000',
    state_code: 'Siliana ',
  },
  {
    id: 107731,
    name: 'Siliana nord',
    latitude: '36.08497000',
    longitude: '9.37082000',
    state_code: 'Siliana ',
  },

  {
    id: 107727,
    name: 'Rouhia',
    latitude: '36.08497000',
    longitude: '9.37082000',
    state_code: 'Siliana ',
  },

  {
    id: 107728,
    name: 'Arousa',
    latitude: '36.08497000',
    longitude: '9.37082000',
    state_code: 'Siliana ',
  },

  {
    id: 107729,
    name: 'Bargou',
    latitude: '36.08497000',
    longitude: '9.37082000',
    state_code: 'Siliana ',
  },

  {
    id: 107730,
    name: 'Sidi bou rouis',
    latitude: '36.08497000',
    longitude: '9.37082000',
    state_code: 'Siliana ',
  },

  {
    id: 106911,
    name: 'Akouda',
    latitude: '35.86910000',
    longitude: '10.56530000',
    state_code: 'Sousse ',
  },
  {
    id: 106960,
    name: 'Hammam Sousse',
    latitude: '35.86090000',
    longitude: '10.60313000',
    state_code: 'Sousse ',
  },
  {
    id: 106962,
    name: 'Hergla',
    latitude: '36.03027000',
    longitude: '10.50904000',
    state_code: 'Sousse ',
  },
  {
    id: 106998,
    name: 'Msaken',
    latitude: '35.72917000',
    longitude: '10.58082000',
    state_code: 'Sousse ',
  },
  {
    id: 107006,
    name: 'Port el Kantaoui',
    latitude: '35.89239000',
    longitude: '10.59434000',
    state_code: 'Sousse ',
  },
  {
    id: 107022,
    name: 'Sidi Bou Ali',
    latitude: '35.95667000',
    longitude: '10.47306000',
    state_code: 'Sousse ',
  },
  {
    id: 107025,
    name: 'Sidi el Hani',
    latitude: '35.67139000',
    longitude: '10.31583000',
    state_code: 'Sousse ',
  },
  {
    id: 107029,
    name: 'Sousse sud',
    latitude: '35.82539000',
    longitude: '10.63699000',
    state_code: 'Sousse ',
  },
  {
    id: 107731,
    name: 'Sousse nord',
    latitude: '35.82539000',
    longitude: '10.63699000',
    state_code: 'Sousse ',
  },
  {
    id: 107732,
    name: 'Bouficha',
    latitude: '35.82539000',
    longitude: '10.63699000',
    state_code: 'Sousse ',
  },
  {
    id: 107733,
    name: 'Kondar',
    latitude: '35.82539000',
    longitude: '10.63699000',
    state_code: 'Sousse ',
  },
  {
    id: 107734,
    name: 'Enfidha',
    latitude: '35.82539000',
    longitude: '10.63699000',
    state_code: 'Sousse ',
  },
  {
    id: 107009,
    name: 'Remada',
    latitude: '32.31662000',
    longitude: '10.39551000',
    state_code: 'Tataouine ',
  },
  {
    id: 107034,
    name: 'Tataouine nord',
    latitude: '32.92967000',
    longitude: '10.45177000',
    state_code: 'Tataouine ',
  },
  {
    id: 107735,
    name: 'Tataouine sud',
    latitude: '32.92967000',
    longitude: '10.45177000',
    state_code: 'Tataouine ',
  },
  {
    id: 107736,
    name: 'Smar',
    latitude: '32.92967000',
    longitude: '10.45177000',
    state_code: 'Tataouine ',
  },
  {
    id: 107737,
    name: 'Dhhiba',
    latitude: '32.92967000',
    longitude: '10.45177000',
    state_code: 'Tataouine ',
  },
  {
    id: 107738,
    name: 'Ghomrassen',
    latitude: '32.92967000',
    longitude: '10.45177000',
    state_code: 'Tataouine ',
  },
  {
    id: 107739,
    name: 'Bir lahmer',
    latitude: '32.92967000',
    longitude: '10.45177000',
    state_code: 'Tataouine ',
  },

  {
    id: 106935,
    name: 'Degache',
    latitude: '33.97606000',
    longitude: '8.20810000',
    state_code: 'Tozeur ',
  },
  {
    id: 107001,
    name: 'Nefta',
    latitude: '33.87309000',
    longitude: '7.87765000',
    state_code: 'Tozeur ',
  },
  {
    id: 107033,
    name: 'Tamaghzah',
    latitude: '34.38849000',
    longitude: '7.94313000',
    state_code: 'Tozeur ',
  },
  {
    id: 107038,
    name: 'Tozeur nord',
    latitude: '33.91968000',
    longitude: '8.13352000',
    state_code: 'Tozeur ',
  },
  {
    id: 107740,
    name: 'Tozeur sud',
    latitude: '33.91968000',
    longitude: '8.13352000',
    state_code: 'Tozeur ',
  },
  {
    id: 107741,
    name: 'Hezwa',
    latitude: '33.91968000',
    longitude: '8.13352000',
    state_code: 'Tozeur ',
  },

  {
    id: 106943,
    name: 'El Fahs',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Zaghouan ',
  },
  {
    id: 106742,
    name: 'Zaghouan sud',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Zaghouan ',
  },
  {
    id: 106743,
    name: 'Zaghouan nord',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Zaghouan ',
  },
  {
    id: 106744,
    name: 'El nadhour',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Zaghouan ',
  },
  {
    id: 106745,
    name: 'Bir mchergua',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Zaghouan ',
  },
  {
    id: 106746,
    name: 'Saouaf',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Zaghouan ',
  },
  {
    id: 106747,
    name: 'El zriba',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Zaghouan ',
  },
  {
    id: 106748,
    name: 'Nabeul nord',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Nabeul ',
  },
  {
    id: 106749,
    name: 'Nabeul sud',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Nabeul ',
  },
  {
    id: 106750,
    name: 'Hammamet sud',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Nabeul ',
  },
  {
    id: 106751,
    name: 'Hammamet nord',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Nabeul ',
  },
  {
    id: 106752,
    name: 'Beni khiar',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Nabeul ',
  },
  {
    id: 106753,
    name: 'Beni khalled',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Nabeul ',
  },
  {
    id: 106754,
    name: 'Bou argoub',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Nabeul ',
  },
  {
    id: 106755,
    name: 'Manzel tmim',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Nabeul ',
  },
  {
    id: 106756,
    name: 'Manzel bouzalfa',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Nabeul ',
  },
  {
    id: 106757,
    name: 'Tazarka',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Nabeul ',
  },
  {
    id: 106758,
    name: 'Kelibia',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Nabeul ',
  },
  {
    id: 106759,
    name: 'El haouaria',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Nabeul ',
  },
  {
    id: 106760,
    name: 'Slimen',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Nabeul ',
  },
  {
    id: 106761,
    name: 'Korba',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Nabeul ',
  },
  {
    id: 106762,
    name: 'Dar chaaben',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Nabeul ',
  },
  {
    id: 106763,
    name: 'El mida',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Nabeul ',
  },
  {
    id: 106764,
    name: 'Takelsa',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Nabeul ',
  },
  {
    id: 106765,
    name: 'Hamem laghzez',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Nabeul ',
  },
  {
    id: 106766,
    name: 'Grombalia',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Nabeul ',
  },
  {
    id: 106767,
    name: 'Bizerte nord',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Bizerte ',
  },
  {
    id: 106768,
    name: 'Bizerte sud',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Bizerte ',
  },
  {
    id: 106769,
    name: 'El alia',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Bizerte ',
  },
  {
    id: 106770,
    name: 'Ghar el melah',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Bizerte ',
  },
  {
    id: 106771,
    name: 'Ghzela',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Bizerte ',
  },
  {
    id: 106772,
    name: 'jarzouna',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Bizerte ',
  },
  {
    id: 106773,
    name: 'Mateur',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Bizerte ',
  },
  {
    id: 106774,
    name: 'Joumine',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Bizerte ',
  },
  {
    id: 106775,
    name: 'Manzel bourguiba',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Bizerte ',
  },
  {
    id: 106776,
    name: 'Manzel jmil',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Bizerte ',
  },
  {
    id: 106777,
    name: 'Sajnen',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Bizerte ',
  },
  {
    id: 106778,
    name: 'Ras jbal',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Bizerte ',
  },
  {
    id: 106779,
    name: 'Tinja',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Bizerte ',
  },
  {
    id: 106780,
    name: 'Utique',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Bizerte ',
  },
  {
    id: 106781,
    name: 'Rafraf',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Bizerte ',
  },
  {
    id: 106782,
    name: 'Ousja',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Bizerte ',
  },
  {
    id: 106783,
    name: 'La pecherie',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Bizerte ',
  },
  {
    id: 106784,
    name: 'Beja nord',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Beja ',
  },
  {
    id: 106785,
    name: 'Beja sud',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Beja ',
  },
  {
    id: 106786,
    name: 'Amdoun',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Beja ',
  },
  {
    id: 106787,
    name: 'Nefza',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Beja ',
  },
  {
    id: 106788,
    name: 'Sidi smail',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Beja ',
  },
  {
    id: 106789,
    name: 'Thibar',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Beja ',
  },
  {
    id: 106790,
    name: 'Oued zargua',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Beja ',
  },
  {
    id: 106791,
    name: 'Mjez lbeb',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Beja ',
  },
  {
    id: 106792,
    name: 'Gbollat',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Beja ',
  },
  {
    id: 106793,
    name: 'Tboursek',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Beja ',
  },
  {
    id: 106794,
    name: 'Tastour',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Beja ',
  },
  {
    id: 106802,
    name: 'Wechteta',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Beja ',
  },
  {
    id: 106795,
    name: 'Jandouba nord',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Jandouba ',
  },
  {
    id: 106796,
    name: 'Jandouba sud',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Jandouba ',
  },
  {
    id: 106797,
    name: 'Bouselm',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Jandouba ',
  },
  {
    id: 106798,
    name: 'Ghar dima',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Jandouba ',
  },
  {
    id: 106799,
    name: 'Fernana',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Jandouba ',
  },
  {
    id: 106800,
    name: 'Ain drahem',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Jandouba ',
  },
  {
    id: 106801,
    name: 'Tabrka',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Jandouba ',
  },
  {
    id: 106803,
    name: 'Hammem bourguiba',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Jandouba ',
  },
  {
    id: 106804,
    name: 'Bni mtir',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Jandouba ',
  },
  {
    id: 106805,
    name: 'Kairouan nord',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Kairouan ',
  },

  {
    id: 106806,
    name: 'Kairouan sud',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Kairouan ',
  },
  {
    id: 106807,
    name: 'Chbika',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Kairouan ',
  },
  {
    id: 106808,
    name: 'Sidi amor bou hajla',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Kairouan ',
  },
  {
    id: 106809,
    name: 'Chrarda',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Kairouan ',
  },
  {
    id: 106810,
    name: 'Nasr allah',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Kairouan ',
  },
  {
    id: 106811,
    name: 'Haffouz',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Kairouan ',
  },
  {
    id: 106812,
    name: 'Hajeb el ayoun',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Kairouan ',
  },
  {
    id: 106813,
    name: 'El baten',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Kairouan ',
  },
  {
    id: 106814,
    name: 'Sbikha',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Kairouan ',
  },
  {
    id: 106815,
    name: 'Rageda',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Kairouan ',
  },
  {
    id: 106816,
    name: 'El ala',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Kairouan ',
  },
  {
    id: 106817,
    name: 'Kasserine sud',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Kasserine ',
  },
  {
    id: 106818,
    name: 'Kasserine nord',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Kasserine ',
  },
  {
    id: 106819,
    name: 'Feriana',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Kasserine ',
  },
  {
    id: 106820,
    name: 'Majel bel abees',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Kasserine ',
  },
  {
    id: 106821,
    name: 'Tala',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Kasserine ',
  },
  {
    id: 106822,
    name: 'Khmouda',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Kasserine ',
  },
  {
    id: 106823,
    name: 'Fousena',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Kasserine ',
  },
  {
    id: 106824,
    name: 'Hidra',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Kasserine ',
  },
  {
    id: 106825,
    name: 'El ayoun',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Kasserine ',
  },
  {
    id: 106826,
    name: 'sbeitla',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Kasserine ',
  },
  {
    id: 106827,
    name: 'Sbiba',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Kasserine ',
  },
  {
    id: 106828,
    name: 'Jedliane',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Kasserine ',
  },
  {
    id: 106829,
    name: 'Hassi lfrid',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Kasserine ',
  },
  {
    id: 106829,
    name: 'talebet',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Kasserine ',
  },
  {
    id: 106830,
    name: 'Bouchebka',
    latitude: '36.37419000',
    longitude: '9.90651000',
    state_code: 'Kasserine ',
  },
  {
    id: 106833,
    name: 'Gafsa sud',
    latitude: '34.93212000',
    longitude: '9.19321000',
    state_code: 'Gafsa ',
  },
  {
    id: 106834,
    name: 'Gafsa nord',
    latitude: '34.93212000',
    longitude: '9.19321000',
    state_code: 'Gafsa ',
  },
  {
    id: 106835,
    name: 'El gutar',
    latitude: '34.93212000',
    longitude: '9.19321000',
    state_code: 'Gafsa ',
  },
  {
    id: 106836,
    name: 'Metloui',
    latitude: '34.93212000',
    longitude: '9.19321000',
    state_code: 'Gafsa ',
  },
  {
    id: 106837,
    name: 'Rdayef',
    latitude: '34.93212000',
    longitude: '9.19321000',
    state_code: 'Gafsa ',
  },
  {
    id: 106838,
    name: 'Om larayes',
    latitude: '34.93212000',
    longitude: '9.19321000',
    state_code: 'Gafsa ',
  },
  {
    id: 106839,
    name: 'Snad',
    latitude: '34.93212000',
    longitude: '9.19321000',
    state_code: 'Gafsa ',
  },
  {
    id: 106840,
    name: 'Zanouch',
    latitude: '34.93212000',
    longitude: '9.19321000',
    state_code: 'Gafsa ',
  },
  {
    id: 106841,
    name: 'Belkhir',
    latitude: '34.93212000',
    longitude: '9.19321000',
    state_code: 'Gafsa ',
  },
  {
    id: 106842,
    name: 'El ayeycha',
    latitude: '34.93212000',
    longitude: '9.19321000',
    state_code: 'Gafsa ',
  },
  {
    id: 106843,
    name: 'Bou saad',
    latitude: '34.93212000',
    longitude: '9.19321000',
    state_code: 'Gafsa ',
  },
  {
    id: 106844,
    name: 'Chabiba',
    latitude: '34.93212000',
    longitude: '9.19321000',
    state_code: 'Gafsa ',
  },
  {
    id: 106845,
    name: 'El faj',
    latitude: '34.93212000',
    longitude: '9.19321000',
    state_code: 'Gafsa ',
  },
  {
    id: 106846,
    name: 'Sidi aich',
    latitude: '34.93212000',
    longitude: '9.19321000',
    state_code: 'Gafsa ',
  },
  {
    id: 106847,
    name: 'Mdhila',
    latitude: '34.93212000',
    longitude: '9.19321000',
    state_code: 'Gafsa ',
  },
  {
    id: 106848,
    name: 'Alim',
    latitude: '34.93212000',
    longitude: '9.19321000',
    state_code: 'Gafsa ',
  },
  {
    id: 106849,
    name: 'Gabes sud ',
    latitude: '34.93212000',
    longitude: '9.19321000',
    state_code: 'Gabès ',
  },
  {
    id: 106850,
    name: 'Gabes nord',
    latitude: '34.93212000',
    longitude: '9.19321000',
    state_code: 'Gabès ',
  },
  {
    id: 106851,
    name: 'El metouia',
    latitude: '34.93212000',
    longitude: '9.19321000',
    state_code: 'Gabès ',
  },
  {
    id: 106852,
    name: 'El hamma',
    latitude: '34.93212000',
    longitude: '9.19321000',
    state_code: 'Gabès ',
  },
  {
    id: 106853,
    name: 'Matmata',
    latitude: '34.93212000',
    longitude: '9.19321000',
    state_code: 'Gabès ',
  },
  {
    id: 106854,
    name: 'Marth',
    latitude: '34.93212000',
    longitude: '9.19321000',
    state_code: 'Gabès ',
  },
  {
    id: 106855,
    name: 'Manzel hbib',
    latitude: '34.93212000',
    longitude: '9.19321000',
    state_code: 'Gabès ',
  },

  {
    id: 106856,
    name: 'Nouvelle matmata',
    latitude: '34.93212000',
    longitude: '9.19321000',
    state_code: 'Gabès ',
  },
  {
    id: 106857,
    name: 'Oudhref',
    latitude: '34.93212000',
    longitude: '9.19321000',
    state_code: 'Gabès ',
  },
  {
    id: 106858,
    name: 'kebili nord',
    latitude: '34.93212000',
    longitude: '9.19321000',
    state_code: 'Kebili ',
  },
  {
    id: 106859,
    name: 'kebili sud',
    latitude: '34.93212000',
    longitude: '9.19321000',
    state_code: 'Kebili ',
  },
  {
    id: 106860,
    name: 'douz',
    latitude: '34.93212000',
    longitude: '9.19321000',
    state_code: 'Kebili ',
  },
  {
    id: 106861,
    name: 'Souk lahad',
    latitude: '34.93212000',
    longitude: '9.19321000',
    state_code: 'Kebili ',
  },
  {
    id: 106862,
    name: 'El fawar',
    latitude: '34.93212000',
    longitude: '9.19321000',
    state_code: 'Kebili ',
  },
  {
    id: 106863,
    name: 'Jemna',
    latitude: '34.93212000',
    longitude: '9.19321000',
    state_code: 'Kebili ',
  },
  {
    id: 106864,
    name: 'Fatnasa',
    latitude: '34.93212000',
    longitude: '9.19321000',
    state_code: 'Kebili ',
  },
];
export const states = [
  {
    id: 2550,
    name: 'Ariana',
    state_code: '12',
    latitude: '36.99227510',
    longitude: '10.12551640',
    type: null,
  },
  {
    id: 2566,
    name: 'Ben Arous',
    state_code: '13',
    latitude: '36.64356060',
    longitude: '10.21515780',
    type: null,
  },
  {
    id: 2555,
    name: 'Manouba',
    state_code: '14',
    latitude: '36.84465040',
    longitude: '9.85714160',
    type: null,
  },
  {
    id: 2554,
    name: 'Tunis',
    state_code: '11',
    latitude: '36.83749460',
    longitude: '10.19273890',
    type: null,
  },
  {
    id: 2551,
    name: 'Bizerte ',
    state_code: '23',
    latitude: '37.16093970',
    longitude: '9.63413500',
    type: null,
  },
  {
    id: 2552,
    name: 'Jandouba ',
    state_code: '32',
    latitude: '36.71818620',
    longitude: '8.74811670',
    type: null,
  },
  {
    id: 2599,
    name: 'Beja ',
    state_code: '11',
    latitude: '36.83749460',
    longitude: '10.19273890',
    type: null,
  },
  {
    id: 2561,
    name: 'Kef ',
    state_code: '33',
    latitude: '36.12305120',
    longitude: '8.66005860',
    type: null,
  },
  {
    id: 2563,
    name: 'Siliana ',
    state_code: '34',
    latitude: '36.08872080',
    longitude: '9.36453350',
    type: null,
  },
  {
    id: 2565,
    name: 'Zaghouan ',
    state_code: '22',
    latitude: '36.40911880',
    longitude: '10.14231720',
    type: null,
  },
  {
    id: 2650,
    name: 'Nabeul ',
    state_code: '10',
    latitude: '36.83749460',
    longitude: '10.19273890',
    type: null,
  },
  {
    id: 2564,
    name: 'Kairouan ',
    state_code: '41',
    latitude: '35.67116630',
    longitude: '10.10054690',
    type: null,
  },
  {
    id: 2570,
    name: 'Kasserine ',
    state_code: '42',
    latitude: '35.08091480',
    longitude: '8.66005860',
    type: null,
  },
  {
    id: 2567,
    name: 'Sidi Bouzid ',
    state_code: '43',
    latitude: '35.03543860',
    longitude: '9.48393920',
    type: null,
  },
  {
    id: 2571,
    name: 'Sousse ',
    state_code: '51',
    latitude: '35.90222670',
    longitude: '10.34978950',
    type: null,
  },
  {
    id: 2553,
    name: 'Monastir ',
    state_code: '52',
    latitude: '35.76425150',
    longitude: '10.81128850',
    type: null,
  },
  {
    id: 2557,
    name: 'Sfax ',
    state_code: '61',
    latitude: '34.86065810',
    longitude: '10.34978950',
    type: null,
  },
  {
    id: 2568,
    name: 'Mahdia ',
    state_code: '53',
    latitude: '35.33525580',
    longitude: '10.89030990',
    type: null,
  },
  {
    id: 2562,
    name: 'Kebili ',
    state_code: '73',
    latitude: '33.70715510',
    longitude: '8.97146230',
    type: null,
  },
  {
    id: 2559,
    name: 'Tataouine ',
    state_code: '83',
    latitude: '32.13441220',
    longitude: '10.08072980',
    type: null,
  },
  {
    id: 2569,
    name: 'Tozeur ',
    state_code: '72',
    latitude: '33.97894910',
    longitude: '8.04651850',
    type: null,
  },
  {
    id: 2560,
    name: 'Medenine ',
    state_code: '82',
    latitude: '33.22805650',
    longitude: '10.89030990',
    type: null,
  },
  {
    id: 2558,
    name: 'Gabès ',
    state_code: '81',
    latitude: '33.94596480',
    longitude: '9.72326730',
    type: null,
  },
  {
    id: 2556,
    name: 'Gafsa ',
    state_code: '71',
    latitude: '34.37885050',
    longitude: '8.66005860',
    type: null,
  },
];
