import Input from 'client/components/Form/Input';
import Validators from 'client/helpers/formValidator';
import Select from 'client/components/Form/Select';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import DataTable from 'react-data-table-component';
import Runsheet from 'client/components/Runsheet';
import Modal from 'client/components/Modal';
import ScanInput from 'client/components/Form/ScanInput';
import { scanRunsheetPackages } from 'client/_redux/actions/packages';
import { useRunsheetForm } from './useRunsheetForm';

type RunsheetFormProps = {
  isUpdate?: boolean;
};
const RunsheetForm: React.FC<RunsheetFormProps> = ({ isUpdate }) => {
  const {
    handleSubmit,
    onSubmit,
    formMethods,
    options,
    handleDriverSelect,
    scannedRunsheetPackages,
    columns,
    selectedDriver,
    toggleRunsheetModal,
    setToggleRunsheetModal,
    runsheet,
    loading,
    packagesList,
    itemToUpdateOrDelete,
    handleDelete,
    id,
  } = useRunsheetForm(isUpdate);

  return !loading ? (
    <div className="row align-items-center">
      <div className="col-md-4 col-sm-12">
        <FormProvider {...formMethods}>
          <form className="mt-5" onSubmit={handleSubmit(onSubmit)}>
            {options && (
              <Select
                defaultValue={{
                  label: itemToUpdateOrDelete?.driver?.firstName || '',
                  value: itemToUpdateOrDelete?.driver?._id,
                }}
                name="driver"
                label="sélectionner le livreur"
                isSearchable
                isClearable
                options={options}
                onChange={(value) => handleDriverSelect(value)}
              />
            )}
            <Input
              defaultValue={itemToUpdateOrDelete?.fuel}
              label="Coût du carburant"
              type="number"
              name="fuel"
              min={1}
              validate={Validators([{ validation: 'required' }])}
            />
            <ScanInput
              label={`scanner le code-barres (${scannedRunsheetPackages?.length} colis scannés)`}
              name="barCode"
              validate={Validators([{ validation: 'packageListNotEmpty', packagesList }])}
              action={(code) => scanRunsheetPackages(Number(code), isUpdate, id)}
            />
            <Input
              label="Matricule de voiture de livreur"
              defaultValue={itemToUpdateOrDelete?.carSerialNumber || selectedDriver?.carId}
              placeholder="matricule"
              type="text"
              name="carSerialNumber"
              validate={Validators([{ validation: 'required' }])}
            />

            <div className="d-flex justify-content-center">
              <button className="btn btn-success m-2" type="submit">
                {isUpdate
                  ? 'Modifier et imprimer le runsheet'
                  : 'Creé et imprimer le runsheet'}
              </button>
              {isUpdate && (
                <button onClick={handleDelete} className="btn btn-danger m-2" type="button">
                  Supprimer runsheet
                </button>
              )}
            </div>
          </form>
        </FormProvider>
      </div>
      <div className="col-md-8 col-sm-12">
        <div className="d-flex justify-content-around flex-wrap side-table">
          <DataTable data={scannedRunsheetPackages} columns={columns} />
        </div>
      </div>
      <Modal
        isOpen={toggleRunsheetModal}
        closeModal={() => setToggleRunsheetModal(false)}
        modalHead="Modifier votre colis"
      >
        {runsheet && <Runsheet runsheet={runsheet} />}
      </Modal>
    </div>
  ) : (
    <></>
  );
};

export default RunsheetForm;
