import { clientApi } from 'api';
import { SearchQuery } from 'client/containers/DataTableContainer/types';
import { errorHandler } from 'client/helpers/errorHandler';
import { AdminUserTypes } from 'client/_redux/types/AdminUserTypes';
import { IUser } from 'server/models/User/types';
import { IPaymentHistory } from 'server/models/PaymentHistory/types';
import { PaginatedResponse } from 'types/IPaginationResponse';
import { IThunkAction } from 'types/IThunkAction';
import { toast } from 'react-toastify';
import { UserTypes } from 'client/_redux/types/userTypes';
import { startLoading, stopLoading } from '../loading';

const baseUrl = `/dashboard`;

export const getAllExpeditor = (searchQuery: SearchQuery<IUser>): IThunkAction => async (
  dispatch,
) => {
  dispatch(startLoading());
  try {
    const { data } = await clientApi.get<PaginatedResponse<IUser>>(`${baseUrl}/expeditor`, {
      params: { ...searchQuery },
    });

    dispatch({ type: AdminUserTypes.GET_EXPEDITORS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: AdminUserTypes.GET_EXPEDITORS_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const payExpeditor = async ({
  _id,
  from,
  to,
}: {
  _id: string;
  from: string;
  to: string;
}) => {
  try {
    const { data } = await clientApi.patch<IPaymentHistory>(
      `${baseUrl}/pay-expeditor`,
      {},
      {
        params: { expeditorId: _id, from, to },
      },
    );

    toast('payed', {
      type: 'success',
    });

    return data;
  } catch (error) {
    errorHandler(error);

    return undefined;
  }
};
export const getPaymentHistory = (
  searchQuery: Partial<IPaymentHistory>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const { data } = await clientApi.get<PaginatedResponse<IPaymentHistory>>(
      `users/payment-history`,
    );

    dispatch({ type: UserTypes.FETCH_PAYMENT_HISTORY, payload: data });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
