import { SelectorStyleProperties } from 'client/containers/NavBar';
import { navBarLinksProperties } from 'client/containers/NavBar/navBarLinks';
import React from 'react';
import { Link } from 'react-router-dom';
import { useNavBarItem } from './useNavBarItem';

type NavBarItemProps = {
  setSelectorStyle: React.Dispatch<React.SetStateAction<SelectorStyleProperties>>;
  link: navBarLinksProperties;
  width: number;
  closeMobileMenu: () => void;
  onClick?: () => void;
  extraInfo?: string;
};
const NavBarItem: React.FC<NavBarItemProps> = ({
  setSelectorStyle,
  link,
  width,
  closeMobileMenu,
  onClick,
  extraInfo,
}) => {
  const { ref, pathname } = useNavBarItem(setSelectorStyle, link, width);
  const handleCloseMobileMenu = () => {
    setTimeout(() => {
      closeMobileMenu();
    }, 1000);
  };

  return (
    <li
      ref={ref}
      className={`nav-item ${
        pathname.includes(link.title.toLocaleLowerCase().replaceAll(' ', '-')) ? 'active' : ''
      }`}
    >
      <Link
        onClick={() => {
          onClick && onClick();
          handleCloseMobileMenu();
        }}
        to={link.src}
        className="nav-link"
      >
        <i className={link.icon} />
        {link.title}
        {extraInfo}
      </Link>
    </li>
  );
};

export default NavBarItem;
