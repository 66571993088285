import { format } from 'date-fns';
import { TableColumn } from 'react-data-table-component';
import { IUserPackage } from 'server/models/Package/types';
import { IRunsheetDocument } from 'server/models/Runsheet/types';
import { PackageStateEnum } from 'types/Enums/PackageStateEnum';

export const deliveryColumns = (): TableColumn<IRunsheetDocument>[] => [
  {
    name: 'code',
    selector: (e) => `${e.codeRunsheet}`,
    center: true,
    sortable: true,
    sortField: 'price',
    style: {
      fontWeight: 'bold',
    },
  },
  {
    name: 'Nom du livreur',
    selector: (e) => `${e?.driver?.firstName}`,
    center: true,
    sortable: true,
    sortField: 'firstName',
    style: {
      fontWeight: 'bold',
    },
  },
  {
    name: 'Etat de runsheet',
    selector: (e) => `${e.state}`,
    center: true,
    sortable: true,
    sortField: 'state',
  },
  {
    name: 'matricule de livruer',
    selector: (e) => `${e.carSerialNumber}`,
    center: true,
    sortable: true,
    sortField: 'carSerialNumber',
    style: {
      fontWeight: 'bold',
    },
  },
  {
    name: 'Status',
    selector: (e) => `${e.state}`,
    center: true,
    sortable: true,
    sortField: 'Status',
  },
  {
    name: 'Nombre de colis',
    selector: (e) =>
      `${
        (e.packagesList as IUserPackage[]).filter(
          (pack) => pack.state !== PackageStateEnum.PACKAGE_HEADING_TO_CLIENT,
        ).length
      }/${e.packagesList.length}`,
    center: true,
    sortable: true,
    sortField: 'packageList',
    style: {
      fontWeight: 'bold',
    },
  },
  {
    sortField: 'createdAt',
    name: 'Date de creation',
    cell: (e) => e.createdAt && format(new Date(e.createdAt), 'MM/dd/yyyy HH:mm'),
    sortable: true,
    center: true,
  },
];
