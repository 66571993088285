import { TableStyles } from 'react-data-table-component';

export const dataTableStyles: TableStyles = {
  tableWrapper: {
    style: {
      display: 'table',
    },
  },
  header: {
    style: {},
  },
  subHeader: {
    style: {
      minHeight: '52px',
      margin: '8px auto',
      justifyContent: 'normal',
      borderTop: '1px solid #E0E0E0',
      borderBottom: '1px solid #E0E0E0',
    },
  },
  pagination: {
    style: {
      fontSize: '15px',
      marginTop: '20px',
      minHeight: '56px',
      borderTop: '1px solid #E0E0E0',
      display: 'flex',
      justifyContent: 'center',
    },
  },
};
