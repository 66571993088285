/* eslint-disable @typescript-eslint/ban-ts-comment */
import ReactSelect, { OptionTypeBase, ValueType } from 'react-select';
import * as React from 'react';
import { FieldError } from 'react-hook-form';
import { ISelectorOption } from 'types/ISelectorOption';
import CSS from 'csstype';
import customStyles, { errorStyles } from './styles';

export interface Props<T extends unknown> {
  id?: string;
  className?: string;
  options: ISelectorOption<T>[];
  multi?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  label?: string;
  onChange: (value?: T | T[]) => void;
  errors?: FieldError;
  isSearchBar?: boolean;
  value?: T | T[];
  name?: string;
  defaultValue?: { label: string; value: string };
  placeholder?: string;
  style?: CSS.Properties;
}

const Select = <T extends unknown = unknown>({
  isSearchBar,
  id,
  options,
  multi = false,
  isClearable = false,
  isSearchable = false,
  label,
  onChange,
  errors,
  value,
  className,
  name,
  defaultValue,
  placeholder,
  style,
}: Props<T>) => {
  React.useEffect(() => {
    if (!multi && value instanceof Array) onChange(undefined);
    // eslint-disable-next-line
  }, [multi, value]);

  const selectedValue = React.useMemo(() => {
    if (!multi) return options?.find((el) => el.value === value);
    if (!(value instanceof Array))
      throw new Error('Value should be an array for multi = true');

    return options.filter((el) => value.includes(el.value));
  }, [value, options, multi]);

  const handleChange = (event: ValueType<OptionTypeBase, boolean>): void => {
    if (!multi) return onChange((event as ISelectorOption<T> | null)?.value);

    return onChange((event as ISelectorOption<T>[] | null)?.map((el) => el.value) || []);
  };

  return (
    <div
      style={{ minWidth: 200 }}
      className={`${isSearchBar ? 'my-0' : ''} form-group ${className || ''} ${
        errors ? 'u-has-error' : ''
      }`}
    >
      {!isSearchBar && label && (
        <label className="form-label" htmlFor={id}>
          <span className="d-flex justify-content-between align-items-center ">{label}</span>
        </label>
      )}
      {/* @ts-ignore */}
      <ReactSelect
        placeholder={placeholder}
        className="select-min-width border-0"
        defaultValue={defaultValue}
        name={name}
        valueKey="value"
        isMulti={multi}
        options={options}
        isClearable={isClearable}
        isSearchable={isSearchable}
        // @ts-ignore
        styles={errors ? errorStyles : customStyles(style)}
        value={selectedValue}
        onChange={handleChange}
        menuPosition="fixed"
      />
      {errors && (
        <div className="invalid-feedback" style={{ display: 'block' }}>
          {errors.message}
        </div>
      )}
    </div>
  );
};

export default Select;
