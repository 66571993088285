/* eslint-disable jsx-a11y/anchor-is-valid */
import Modal from 'client/components/Modal';
import NavBarItem from 'client/components/NavBarItem';
import { getImageUrl } from 'client/helpers/getImageUrl';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { UserRolesEnum } from 'types/Enums/UserRolesEnum';
import PackageForm from '../PackageForm';
import { useNavBar } from './useNavBar';

export type SelectorStyleProperties = {
  top: number;
  left: number;
  height: number;
  width: number;
};

type NavBarProps = {
  userRole?: UserRolesEnum;
  className?: string;
};
const NavBar: React.FC<NavBarProps> = ({ className, userRole }) => {
  const {
    isLoggedIn,
    logout,
    nabBarLinks,
    selectorStyle,
    width,
    mobileMenuDisplay,
    toggleMobileMenu,
    userBalance,
    role,
    addPackageModal,
    setAddPackageModal,
    setSelectorStyle,
  } = useNavBar();
  const { pathname } = useLocation();

  return (
    <>
      <div className={`navbar-container ${className || ''}`}>
        <nav className="navbar navbar-expand-custom navbar-mainbg">
          <Link className="navbar-brand navbar-logo p-0" to="/">
            <img
              className={width > 992 ? 'pl-2' : 'p-2'}
              width={145}
              src={getImageUrl('assets/images/nextPackLogo.png')}
              alt="Next pack"
            />
          </Link>

          {userRole === UserRolesEnum.ROLE_EXPEDITOR && (
            <>
              <button
                className={`${
                  width > 992 ? 'mx-4' : ''
                } add-fast-package add-fast-package-button`}
                type="button"
                onClick={() => setAddPackageModal(true)}
              >
                Creer un colis rappide
              </button>
              <Modal
                isOpen={addPackageModal}
                closeModal={() => setAddPackageModal(false)}
                modalHead="Ajouter des nouvelles colis"
              >
                <PackageForm
                  isFastPackage
                  setIsUpdateModalVisible={setAddPackageModal}
                  setAddPackageModal={setAddPackageModal}
                />
              </Modal>
            </>
          )}
          <div>
            <button
              style={{ backgroundColor: 'transparent' }}
              className="navbar-toggler p-1"
              type="button"
              onClick={toggleMobileMenu}
            >
              {mobileMenuDisplay === 'none' ? (
                <i className="fas fa-bars" style={{ color: 'white' }} />
              ) : (
                <i className="fa fa-times" style={{ color: 'white' }} />
              )}
            </button>
          </div>

          <div
            style={{ display: mobileMenuDisplay }}
            className="collapse navbar-collapse"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ml-auto">
              {pathname !== '/' && (
                <div style={selectorStyle} className="hori-selector">
                  <div className="left" />
                  <div className="right" />
                </div>
              )}
              {isLoggedIn && (
                <>
                  {nabBarLinks}
                  {role === UserRolesEnum.ROLE_EXPEDITOR && (
                    <NavBarItem
                      key="balance"
                      setSelectorStyle={setSelectorStyle}
                      link={{
                        icon: 'fas fa-money-bill-wave',
                        src: '/balance',
                        title: 'Balance',
                      }}
                      width={width}
                      extraInfo={` ${userBalance} DT`}
                      closeMobileMenu={toggleMobileMenu}
                    />
                  )}
                  {width < 992 && (
                    <NavBarItem
                      onClick={logout}
                      key="logout"
                      setSelectorStyle={setSelectorStyle}
                      link={{
                        icon: 'fas fa-sign-out-alt',
                        src: '',
                        title: 'Logout',
                      }}
                      width={width}
                      closeMobileMenu={toggleMobileMenu}
                    />
                  )}
                </>
              )}

              {width > 992 && (
                <li className="nav-item">
                  <Link onClick={() => logout()} className="nav-link" to="">
                    <i className="fas fa-sign-out-alt" />
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
};

export default NavBar;
