import React from 'react';

export interface ExpandedComponentProps<T extends unknown> {
  data?: T;
  children?: React.ReactNode;
}
export const DefaultExpandedComponent = <T extends unknown>({
  data,
  children,
}: ExpandedComponentProps<T>) => <>{children}</>;

export default DefaultExpandedComponent;
