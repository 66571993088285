import React from 'react';
import { IAddressDocument } from 'server/models/Address/types';
import { IUserPackageDocument } from 'server/models/Package/types';
import InvoiceHeader from './InvoiceHeader';
import SingleParcelContent from './SingleParcelContent';

type InvoiceContentProps = {
  invoiceRef: React.MutableRefObject<null>;
  parcel: IUserPackageDocument;
};
const InvoiceContent: React.FC<InvoiceContentProps> = ({ invoiceRef, parcel }) => (
  <div
    style={{
      backgroundImage: parcel.isDelicate ? `url('assets/images/fragile.png')` : '',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      height: '99vh',
    }}
    ref={invoiceRef}
    className="factureContainer factureInfo "
  >
    <div className="ml-5 mr-5 mt-3">
      <InvoiceHeader parcel={parcel} />
      <div className="d-flex justify-content-center pb-5">
        <div
          style={{ border: 'solid' }}
          className="d-flex justify-content-between rounded p-3"
        >
          <div>
            <span>{(parcel?.expeditor?.address as IAddressDocument)?.city}</span>
          </div>
          <div className="px-2">{`=====Dispatch=====>`}</div>
          <div>
            <span>{parcel?.destination?.city}</span>
          </div>
        </div>
      </div>

      <SingleParcelContent parcel={parcel} />
    </div>
    <div className="d-flex justify-content-center">
      <div className="fiscal-information rounded">
        <div className="rounded mb-3 mt-3" style={{ border: 'solid', width: 350 }}>
          <div className="m-2">
            <div className="d-flex justify-content-between">
              <span className="h2 pr-1">Montant HT:</span>
              <span className="h2 pr-1">
                {(parcel.price - parcel.price * 0.19).toFixed(2)} DT
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="h2 pr-1">TVA 19%:</span>
              <span className="h2 pr-1">{(parcel.price * 0.19).toFixed(2)} DT</span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="h2 pr-1">Total en TTC:</span>
              <span className="h2 pr-1">{parcel?.price} DT</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default InvoiceContent;
