import { useTableColumnsWithActions } from 'client/containers/DataTableContainer/useTableColumnsWithActions';
import { useCallback, useRef, useState } from 'react';
import { DataTableHandle } from 'client/containers/DataTableContainer/types';
import { useAppSelector } from 'client/hooks/useAppSelector';
import { useNavigate } from 'react-router-dom';
import { IRunsheetDocument } from 'server/models/Runsheet/types';
import { DeliveringPackageTypeEnum } from 'types/Enums/DeliveringPackagesEnum';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import {
  affectDriverToManifest,
  deleteAdminRunsheet,
  getRunsheetPackagesId,
} from 'client/_redux/actions/runsheet';
import { RunsheetValidation } from 'types/RunsheetValidationType';
import { RunsheetTableColumns } from './RunsheetTableColumns';
import { buttons } from './tableButtons';

export const useRunsheet = (isAdmin: boolean) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const runsheetList = useAppSelector(({ runsheet: { runsheetList } }) => runsheetList);

  const [driver, setDriver] = useState('');
  const dataTableRef = useRef<DataTableHandle>(null);
  const [itemToUpdateOrDelete, setItemToUpdateOrDelete] = useState<IRunsheetDocument>();
  const [manifest, setManifest] = useState<IRunsheetDocument>();
  const [isPrintModalVisible, setIsPrintModalVisible] = useState<boolean>(false);
  const [manifestModal, setManifestModal] = useState(false);
  const [addDriverModal, setAddDriverModal] = useState(false);
  const [toggleDriverInvoice, setToggleDriverInvoice] = useState(false);
  const [type, setType] = useState<DeliveringPackageTypeEnum>(
    DeliveringPackageTypeEnum.DELIVERING,
  );
  const [packagesList, setPackagesList] = useState<RunsheetValidation>({
    ce: 0,
    payedByCheck: 0,
    payedCash: 0,
    rd: 0,
    re: 0,
    runsheet: undefined,
    total: 0,
    deliveredPackages: [],
    failedPackages: [],
  });
  const handlePrintRunsheet = useCallback((item: IRunsheetDocument) => {
    setItemToUpdateOrDelete(item);
    setIsPrintModalVisible(true);
  }, []);
  const handleAddDriverToManifest = useCallback((item: IRunsheetDocument) => {
    setManifest(item);
    setManifestModal(true);
  }, []);
  const handlePrintManifest = useCallback((item: IRunsheetDocument) => {
    setManifest(item);
    setManifestModal(true);
  }, []);
  const handlePrintDriverInvoice = useCallback((item: IRunsheetDocument) => {
    getRunsheetPackagesId(item._id).then((packagesList) => {
      setPackagesList(packagesList);
      setToggleDriverInvoice(true);
    });
  }, []);
  const redirectToAddRunsheetPage = () => {
    navigate('/ajouter-runsheet');
  };
  const redirectToUpdateRunsheetPage = (item: IRunsheetDocument) => {
    navigate(`/modifier-runsheet/${item?._id}`);
  };
  const confirmRunsheet = (item: IRunsheetDocument) => {
    navigate(`/confirmer-runsheet/${item?._id}`);
  };

  const [itemToDelete, setItemToDelete] = useState<IRunsheetDocument>();
  const [deleteModal, setDeleteModal] = useState(false);
  const handleDeleteRunsheet = useCallback((item: IRunsheetDocument) => {
    setItemToDelete(item);
    setDeleteModal(true);
  }, []);
  const onDelete = useCallback(() => {
    if (itemToDelete) dispatch(deleteAdminRunsheet(itemToDelete._id, itemToDelete.type));
  }, [dispatch, itemToDelete]);

  const columns = useTableColumnsWithActions({
    columns: RunsheetTableColumns(type),
    buttons: buttons(
      type,
      redirectToUpdateRunsheetPage,
      handlePrintRunsheet,
      confirmRunsheet,
      handleAddDriverToManifest,
      handlePrintManifest,
      handlePrintDriverInvoice,
      handleDeleteRunsheet,
      isAdmin,
    ),
  });

  const onSubmitAddDriverToManifest = useCallback(() => {
    if (!driver) {
      toast('selectionner un livreur', { type: 'error' });
    }
    dispatch(affectDriverToManifest(driver, manifest?._id));
    setAddDriverModal(false);
  }, [dispatch, driver, manifest?._id]);

  return {
    runsheetList,
    manifestModal,
    manifest,
    columns,
    dataTableRef,
    redirectToAddRunsheetPage,
    isPrintModalVisible,
    itemToUpdateOrDelete,
    setIsPrintModalVisible,
    type,
    setType,
    setManifestModal,
    addDriverModal,
    setAddDriverModal,
    setDriver,
    onSubmitAddDriverToManifest,
    packagesList,
    toggleDriverInvoice,
    setToggleDriverInvoice,
    deleteModal,
    setDeleteModal,
    onDelete,
    itemToDelete,
  };
};
