import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'client/_redux/reducers';
import { useTableColumnsWithActions } from 'client/containers/DataTableContainer/useTableColumnsWithActions';
import { useCallback, useRef, useState } from 'react';
import { DataTableHandle } from 'client/containers/DataTableContainer/types';
import { IUserPackageDocument } from 'server/models/Package/types';
import { deletePackage } from 'client/_redux/actions/packages';
import { TableColumns } from './TableColumns';

export const useAdminPackages = (isAdmin?: boolean) => {
  const { expeditorPackages, loaded } = useSelector(({ packages }: RootState) => packages);

  const dataTableRef = useRef<DataTableHandle>(null);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState<boolean>(false);
  const [itemToUpdateOrDelete, setItemToUpdateOrDelete] = useState<IUserPackageDocument>();
  const [isPrintModalVisible, setIsPrintModalVisible] = useState<boolean>(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);

  const dispatch = useDispatch();
  const handleClosePrintModal = () => {
    setIsPrintModalVisible(false);
    setItemToUpdateOrDelete(undefined);
  };
  const handleCloseUpdateModal = () => {
    setIsUpdateModalVisible(false);
    setItemToUpdateOrDelete(undefined);
  };
  const handleUpdatePackage = useCallback((item: IUserPackageDocument) => {
    setItemToUpdateOrDelete(item);
    setIsUpdateModalVisible(true);
  }, []);
  const handlePrintPackage = useCallback((item: IUserPackageDocument) => {
    setItemToUpdateOrDelete(item);
    setIsPrintModalVisible(true);
  }, []);
  const handleDeletePackage = useCallback((item: IUserPackageDocument) => {
    setItemToUpdateOrDelete(item);
    setIsDeleteModalVisible(true);
  }, []);

  const columns = useTableColumnsWithActions({
    columns: TableColumns(isAdmin),
    buttons: isAdmin
      ? [
          {
            className: (item) => `fa fa-pen`,
            fn: handleUpdatePackage,
            color: 'black',
          },
          {
            className: (item) => `fas fa-print`,
            fn: handlePrintPackage,
            color: 'black',
          },
          {
            className: (item) => `fas fa-trash`,
            fn: handleDeletePackage,
            color: 'red',
          },
        ]
      : [
          {
            className: (item) => `fas fa-print`,
            fn: handlePrintPackage,
            color: 'black',
          },
        ],
  });
  const onDelete = useCallback(() => {
    if (!itemToUpdateOrDelete) return;
    dispatch(deletePackage(itemToUpdateOrDelete._id));
    setIsDeleteModalVisible(false);
  }, [dispatch, itemToUpdateOrDelete]);

  return {
    expeditorPackages,
    loaded,
    columns,
    dataTableRef,
    isUpdateModalVisible,
    handleCloseUpdateModal,
    itemToUpdateOrDelete,
    isPrintModalVisible,
    handleClosePrintModal,
    isDeleteModalVisible,
    setIsDeleteModalVisible,
    onDelete,
  };
};
