import { DataTableHandle } from 'client/containers/DataTableContainer/types';
import { useTableColumnsWithActions } from 'client/containers/DataTableContainer/useTableColumnsWithActions';
import { useAppSelector } from 'client/hooks/useAppSelector';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ExpeditorColumns } from './ExpeditorColumns';

export const useExpeditorReturn = () => {
  const expeditorsList = useAppSelector(({ user: { userList } }) => userList);
  const dataTableRef = useRef<DataTableHandle>(null);
  const navigate = useNavigate();
  const columns = useTableColumnsWithActions({
    columns: ExpeditorColumns(),
    buttons: [
      {
        className: () => `fas fa-undo-alt`,
        // eslint-disable-next-line no-console
        fn: (item) => navigate(`/retour-expediteur/${item._id}`),
        color: 'black',
      },
    ],
  });

  return { columns, expeditorsList, dataTableRef };
};
