export enum PackageReturnCuzEnum {
  PACKAGE_RETURNED_NO_MONEY = 'client has no money to pay',
  PACKAGE_RETURNED_NO_ANSWER_FROM_CLIENT = "client didn't pickup",
  PACKAGE_RETURNED_PHONE_CLOSED = 'clint phone is closed',
  PACKAGE_RETURNED_CLIENT_NOT_SERIOUS = 'client is not serious about the product',
  PACKAGE_RETURNED_REFUSED_BY_CLIENT = 'client refuse the package',
  PACKAGE_RETURNED_NOT_IN_DRIVER_DESTINATION = 'package not in driver destination',
  PACKAGE_RETURNED_PHONE_NUMBER_INCORRECT = 'wrong phone number',
  PACKAGE_RETURNED_INCORRECT_PRICE = 'incorrect package price',
  PACKAGE_RETURNED_CLIENT_NOT_AVAILABLE_Date = 'client is available for another date',
  PACKAGE_RETURNED_DOUBLE_PACKAGE = 'package doubled',
  PACKAGE_RETURNED_BROKEN = 'something wrong with the package',
  PACKAGE_RETURNED_INCORRECT_ADDRESS = 'client address is incorrect',
  PACKAGE_RETURNED_ADDRESS_MESSING_INFORMATION = 'uncompleted address',
  PACKAGE_RETURNED_CLIENT_AVAILABLE_TOMORROW = 'client available tomorrow',
  PACKAGED_RETURNED_CANCELED_BY_EXPEDITOR = 'package canceled by expeditor',
}
