/* eslint-disable max-lines */
import { IThunkAction } from 'types/IThunkAction';
import { startLoading, stopLoading } from 'client/_redux/actions/loading';
import { errorHandler } from 'client/helpers/errorHandler';
import * as PackagesService from 'client/_redux/services/packages';
import { IUserPackageDocument } from 'server/models/Package/types';
import { PackagesExpTypes } from 'client/_redux/types/packagesExpTypes';
import { toast } from 'react-toastify';
import { SearchQuery } from 'client/containers/DataTableContainer/types';
import { PackageFormType } from 'types/forms/PackageFormType';
import { packageCsvObject } from 'types/PackageExternalTypes';
import { AdminUserTypes } from 'client/_redux/types/AdminUserTypes';
import { playAudioSound } from 'client/helpers/effectSound';
import { PackageStateEnum } from 'types/Enums/PackageStateEnum';

export const getAllPackagesExp = (
  searchQuery?: SearchQuery<IUserPackageDocument>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const data = await PackagesService.getAllPackagesExp()(searchQuery);

    dispatch({ type: PackagesExpTypes.FETCH_PACKAGES_SUCCESS, payload: data });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const getAllPackagesManager = (
  searchQuery?: SearchQuery<IUserPackageDocument>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const data = await PackagesService.getAllPackagesManager()(searchQuery);

    dispatch({ type: AdminUserTypes.GET_EXPEDITORS_SUCCESS, payload: data });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const getPickupPackages = (
  searchQuery?: SearchQuery<IUserPackageDocument>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const data = await PackagesService.getPickupPackages()(searchQuery);

    dispatch({ type: PackagesExpTypes.FETCH_PACKAGES_SUCCESS, payload: data });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const expeditorStats = (): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await PackagesService.expeditorStats()();

    dispatch({
      type: PackagesExpTypes.FETCH_EXPEDITOR_STATS_SUCCESS,
      payload: response.data.stats,
    });
  } catch (error) {
    dispatch({ type: PackagesExpTypes.FETCH_EXPEDITOR_STATS_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const managerStats = (): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await PackagesService.managerStats()();

    dispatch({
      type: PackagesExpTypes.FETCH_EXPEDITOR_STATS_SUCCESS,
      payload: response.data.stats,
    });
  } catch (error) {
    dispatch({ type: PackagesExpTypes.FETCH_EXPEDITOR_STATS_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const deletePackage = (packageId: string): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    await PackagesService.deletePackage()(packageId);

    dispatch(getPickupPackages());
    toast('colis supprimé avec succès', { type: 'success' });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const deleteManyPackage = (packageList: string[]): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    await PackagesService.deleteManyPackage()(packageList);

    dispatch(getPickupPackages());
    toast('votre colis a éte supprimer avec succées', { type: 'success' });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const updatePackage = (
  data: PackageFormType & { _id: string },
  location: '/pickup' | '/mes-colis',
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const { _id, ...rest } = data;

    await PackagesService.updatePackage()(_id, rest);
    if (location === '/pickup') dispatch(getPickupPackages());
    else dispatch(getAllPackagesExp());

    toast('colis modifier avec succès', { type: 'success' });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const createPackages = (
  data: PackageFormType,
  isFastPackage?: boolean,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());

  try {
    await PackagesService.createPackages()(data, isFastPackage);
    dispatch(getPickupPackages());

    toast(`votre colis (${data.articleName}) a été creé avec succer`, { type: 'success' });
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};

export const createMultiplePackages = (
  csvRows: packageCsvObject[],
  isFastPackage?: boolean,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());

  try {
    await PackagesService.createMultiplePackages()(csvRows, isFastPackage);

    dispatch(getPickupPackages());
    toast('Panier importé depuis Excel', { className: 'toast-success' });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const getExpeditorClients = (): IThunkAction => async (dispatch) => {
  dispatch(startLoading());

  try {
    const response = await PackagesService.getExpeditorClients()();

    dispatch({
      type: PackagesExpTypes.FETCH_EXPEDITOR_CLIENTS,
      payload: response,
    });
  } catch (error) {
    dispatch({ type: PackagesExpTypes.FETCH_EXPEDITOR_CLIENTS_FAILURE });
    errorHandler(error);
  }

  dispatch(stopLoading());
};

export const scanRunsheetPackages = (
  barCode: number,
  isUpdate?: boolean,
  id?: string,
): IThunkAction => async (dispatch, getState) => {
  dispatch(startLoading());

  const {
    packages: { scannedRunsheetPackages },
  } = getState();

  try {
    const { data } = await PackagesService.scanRunsheetPackages()(barCode, isUpdate, id);

    if (data.scannedPackage) {
      const existedPack = scannedRunsheetPackages?.find((pack) => pack.barCode === barCode);

      if (existedPack) {
        playAudioSound(false);

        toast('error', {
          type: 'error',
          autoClose: 1000,
        });
      } else {
        scannedRunsheetPackages.push(data.scannedPackage);
        playAudioSound(true);
        toast('scaner avec succesé', {
          type: 'success',
          autoClose: 1000,
        });
      }
    } else {
      playAudioSound(false);
      toast('error', {
        type: 'error',
        autoClose: 1000,
      });
    }
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};
export const deletePackagesFromRunsheet = (barCode: number): IThunkAction => async (
  dispatch,
  getState,
) => {
  dispatch(startLoading());

  const {
    packages: { scannedRunsheetPackages },
  } = getState();

  try {
    const existedPack = scannedRunsheetPackages?.find(
      (pack) =>
        pack.barCode === barCode && pack.state === PackageStateEnum.PACKAGE_HEADING_TO_CLIENT,
    );

    if (existedPack) {
      const index = scannedRunsheetPackages.indexOf(existedPack);

      scannedRunsheetPackages.splice(index, 1);
      toast('colis supprimer', {
        type: 'error',
        autoClose: 1000,
      });
      playAudioSound(false);
    } else {
      toast('Error', {
        type: 'error',
      });
      playAudioSound(false);
    }
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};
export const scanManifestPackages = (barCode: number, id?: string): IThunkAction => async (
  dispatch,
  getState,
) => {
  dispatch(startLoading());

  const {
    packages: { scannedManifestPackages, scannedReturnedPackages },
  } = getState();

  try {
    const { data } = await PackagesService.scanManifestPackages()(barCode, id);

    if (data.scannedPackage) {
      const existedPack =
        !!scannedManifestPackages?.find((pack) => pack.barCode === barCode) ||
        !!scannedReturnedPackages?.find((pack) => pack.exchangeBarCode === barCode);

      if (existedPack) {
        playAudioSound(false);

        toast('error', {
          type: 'error',
          autoClose: 1000,
        });
      } else {
        if (data.returned) scannedReturnedPackages.push(data.scannedPackage);
        else scannedManifestPackages.push(data.scannedPackage);
        playAudioSound(true);
        toast('scaner avec succesé', {
          type: 'success',
          autoClose: 1000,
        });
      }
    } else {
      playAudioSound(false);
      toast('error', {
        type: 'error',
        autoClose: 1000,
      });
    }
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};

export const managerScanPackages = (barCode: number): IThunkAction => async (
  dispatch,
  getState,
) => {
  dispatch(startLoading());

  try {
    const { data } = await PackagesService.managerScanPackages()(barCode);
    const { validCode, scannedPackage } = data;
    const {
      packages: { scannedPackages },
    } = getState();

    if (scannedPackage) {
      const existedPack = scannedPackages.find((pack) => pack._id === scannedPackage._id);

      if (existedPack) playAudioSound(false);
      else playAudioSound(!!scannedPackage);
      toast(!existedPack && validCode ? 'scaner avec succesé' : 'error', {
        type: !existedPack && validCode ? 'success' : 'error',
        autoClose: 1000,
      });
    } else {
      playAudioSound(false);
      toast('error', {
        type: 'error',
        autoClose: 1000,
      });
    }

    scannedPackage && scannedPackages?.push(scannedPackage);
  } catch (error) {
    dispatch({ type: PackagesExpTypes.FETCH_EXPEDITOR_CLIENTS_FAILURE });
    errorHandler(error);
  }

  dispatch(stopLoading());
};
export const managerCreatePickup = (values: {
  driver: string;
  packagesIds: string[];
}): IThunkAction => async (dispatch) => {
  startLoading();
  try {
    await PackagesService.managerCreatePickup()(values);
    dispatch(getAllPackagesManager());
  } catch (error) {
    errorHandler(error);
  }
  stopLoading();
};
