import React, { useState } from 'react';

type AnimatedCardProps = {
  title: string;
  image: string;
  stats: {
    monday: number;
    tuesday: number;
    wednesday: number;
    thursday: number;
    friday: number;
    saturday: number;
    sunday: number;
    total: number;
  };
};
const AnimatedCard: React.FC<AnimatedCardProps> = ({ title, image, stats }) => {
  const [details, setDetails] = useState('');
  const handleCardDetails = () => {
    details ? setDetails('') : setDetails('details');
  };

  console.log({ stats });

  const { monday, tuesday, wednesday, thursday, friday, saturday, sunday, total } = stats;

  return (
    <div className={details}>
      <div onClick={handleCardDetails} className="cursor-pointer animated-card m-2">
        <div
          style={{ background: `url('assets/images/${image}') center` }}
          className="photo"
        />
        <span className="h2 title text-center">{title}</span>
        <p>Plus des details</p>
        <div className="chart">
          <div style={{ height: details ? sunday + 100 : 1 }} className="bar bar1">
            <span>{sunday}</span>
          </div>
          <div style={{ height: details ? monday + 100 : 1 }} className="bar bar2">
            <span>{monday}</span>
          </div>
          <div style={{ height: details ? tuesday + 100 : 1 }} className="bar bar3">
            <span>{tuesday}</span>
          </div>
          <div style={{ height: details ? wednesday + 100 : 1 }} className="bar bar4">
            <span>{wednesday}</span>
          </div>
          <div style={{ height: details ? thursday + 100 : 1 }} className="bar bar5">
            <span>{thursday}</span>
          </div>
          <div style={{ height: details ? friday + 100 : 1 }} className="bar bar6">
            <span>{friday}</span>
          </div>
          <div style={{ height: details ? saturday + 100 : 1 }} className="bar bar7">
            <span>{saturday}</span>
          </div>
        </div>
        <span className="h3 total">{total}</span>
      </div>
    </div>
  );
};

export default AnimatedCard;
