import * as React from 'react';
import Select, { Props as IComponentProps } from 'client/components/Form/Select';
import { useFormContext, Controller, ValidationRules } from 'react-hook-form';
import { get } from 'lodash';

interface IProps<T extends unknown>
  extends Omit<IComponentProps<T>, 'errors' | 'onChange' | 'value'> {
  name: string;
  placeholder?: string;
  validate?: ValidationRules;
}

const FormSelect = <T extends unknown>({ name, validate, ...selectProps }: IProps<T>) => {
  const { control, errors } = useFormContext();

  return (
    <Controller
      rules={validate}
      name={name}
      control={control}
      defaultValue={[]}
      render={({ onChange, value }) => (
        <Select
          {...selectProps}
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          errors={get(errors, name)}
        />
      )}
    />
  );
};

export default FormSelect;
