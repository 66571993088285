/* eslint-disable react/no-unescaped-entities */
import { format } from 'date-fns';
import React from 'react';
import { IAddress } from 'server/models/Address/types';
import { IUserPackage, IUserPackageDocument } from 'server/models/Package/types';

import { IRunsheetDocument } from 'server/models/Runsheet/types';
import { IUser } from 'server/models/User/types';
import NextPackSignature from '../Invoice/NextPackSignature';

type ManifestHeaderProps = {
  manifest: IRunsheetDocument;
  expeditor: IUser;
};
const ManifestHeader: React.FC<ManifestHeaderProps> = ({ manifest, expeditor }) => {
  const total = (manifest.packagesList as IUserPackageDocument[])
    .map((parcel) => parcel.price)
    .reduce((prev, curr) => prev + curr, 0);

  return (
    <>
      <div className="d-flex justify-content-between pb-3">
        <NextPackSignature />
        <div className="fiscal-information rounded ">
          <div className="rounded mb-3 mt-3" style={{ border: 'solid', width: 350 }}>
            <div className="m-2">
              <div className="d-flex justify-content-between">
                <span className="h5 pr-1">Nom de l'expéditeur:</span>
                <span>
                  {expeditor.firstName} {expeditor.lastName}
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="h5 pr-1">Telephone:</span>
                <span>{expeditor?.phoneNumber}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="h5 pr-1">Address:</span>
                <span>
                  {(expeditor?.address as IAddress)?.city}
                  {', '}
                  {(expeditor?.address as IAddress)?.state}
                  {', '}
                  {(expeditor?.address as IAddress)?.address}
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="h5 pr-1">Matricule fiscale:</span>
                <span>{expeditor?.taxNumber}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between  pb-3 align-items-center">
        <div>
          <div className="d-flex justify-content-center">
            <span className="h1">{`Manifest N° ${manifest.codeRunsheet}`}</span>
          </div>
          <div className="d-flex justify-content-center">
            <span className="h6">{`Creé le ${
              manifest.createdAt && format(new Date(manifest.createdAt), 'yyyy-M-dd hh:mm')
            }`}</span>
          </div>
        </div>

        <div className="d-flex justify-content-center mx-1">
          <div className="fiscal-information rounded">
            <div className="rounded mb-3 mt-3" style={{ border: 'solid', width: 350 }}>
              <div className="m-2">
                <div className="d-flex justify-content-between">
                  <span className="h5 pr-1">Montant Total:</span>
                  <span>{total} DT</span>
                </div>
                <div className="d-flex justify-content-between">
                  <span className="h5 pr-1">Nombre des colis:</span>
                  <span>{manifest.packagesList.length}</span>
                </div>
                <div className="d-flex justify-content-between">
                  <span className="h5 pr-1">Nombre des colis échange:</span>
                  <span>
                    {
                      (manifest.packagesList as IUserPackage[]).filter(
                        (pack) => pack.exchangePackage,
                      ).length
                    }
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManifestHeader;
