/* eslint-disable react-hooks/exhaustive-deps */
import useDebounce from 'client/hooks/useDebounce';
import React, { useCallback } from 'react';
import { useFormContext, ValidationRules } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Input from '../Input';

type ScanInputProps = {
  id?: string;
  label?: string;
  name: string;
  validate?: ValidationRules;
  className?: string;
  action: (code: number) => unknown;
};
const ScanInput: React.FC<ScanInputProps> = ({
  action,
  id,
  name,
  validate,
  className,
  label,
}) => {
  const dispatch = useDispatch();
  const formMethods = useFormContext();
  const { setValue } = formMethods;

  const scanPackageCode = useCallback(
    useDebounce((code: string) => {
      if (code.length > 10) {
        dispatch(action(Number(code)));
        setValue(name, '');
      }
    }, 1000),
    [],
  );

  return (
    <Input
      label={label}
      autoFocus
      className={className}
      id={id}
      name={name}
      placeholder="code a barre"
      type="number"
      onChange={(event) => scanPackageCode((event.target as HTMLInputElement).value)}
      validate={validate}
    />
  );
};

export default ScanInput;
