import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as XLSX from 'xlsx';
import { createMultiplePackages } from 'client/_redux/actions/packages';
import { packageCsvObject } from 'types/PackageExternalTypes';
import { toast } from 'react-toastify';
export const useImportCsv = (
  setAddPackageModal?: React.Dispatch<React.SetStateAction<boolean>>,
  isFastPackage?: boolean,
) => {
  const [file, setFile] = useState<Blob>();
  const dispatch = useDispatch();

  const filePathSet = (_file: File | null) => {
    _file && setFile(_file);
  };

  const convertToJson = (csv: string) => {
    const lines = csv.split('\n');

    const result = [];

    const headers = lines[0].split(',');

    for (let i = 1; i < lines.length; i++) {
      const obj: Record<string, unknown> = {};

      const currentLine = lines[i].split(',');

      for (let j = 0; j < headers.length; j++) {
        const objKey = headers[j];

        obj[objKey as keyof typeof obj] = currentLine[j];
      }

      result.push(obj);
    }

    return result;
  };

  const readFile = useCallback(() => {
    if (!file) {
      toast(`veuillez d'abord déposer votre fichier `, {
        type: 'error',
      });

      return;
    }

    const { name } = (file as unknown) as { name: string };
    const ext = name.split('.')[1];

    if (ext !== 'xlsx') {
      toast(`seuls les fichiers xlsx sont acceptés `, {
        type: 'error',
      });

      return;
    }

    const reader = new FileReader();

    reader.onload = (evt) => {
      const bstr = evt?.target?.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_csv(ws);

      dispatch(
        createMultiplePackages(convertToJson(data) as packageCsvObject[], isFastPackage),
      );
      setFile(undefined);
      setAddPackageModal && setAddPackageModal(false);
    };

    file && reader.readAsBinaryString(file);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, file, setAddPackageModal]);

  return {
    filePathSet,
    readFile,
  };
};
