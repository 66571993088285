import { AdminUserTypes } from 'client/_redux/types/AdminUserTypes';
import { UserTypes } from 'client/_redux/types/userTypes';
import { emptyPaginationPayload } from 'server/constants/emptyPaginationPayload';
import { IAddress } from 'server/models/Address/types';
import { IPaymentHistory } from 'server/models/PaymentHistory/types';
import { IUser } from 'server/models/User/types';
import { PaginatedResponse } from 'types/IPaginationResponse';
import { IReduxAction } from 'types/IReduxAction';

export interface IUserState {
  loading: boolean;
  loaded: boolean;
  error: boolean;
  user?: IUser;
  address?: IAddress;
  userList: PaginatedResponse<IUser>;
  expeditorsList: PaginatedResponse<IUser>;
  paymentHistory: PaginatedResponse<IPaymentHistory>;
  newUser?: IUser;
}

const initialState: IUserState = {
  loading: false,
  loaded: false,
  error: false,
  userList: emptyPaginationPayload,
  expeditorsList: emptyPaginationPayload,
  paymentHistory: emptyPaginationPayload,
};

export default (state = initialState, { type, payload }: IReduxAction): IUserState => {
  switch (type) {
    case UserTypes.USER_LOGGED_OUT:
      return initialState;
    case UserTypes.SIGN_IN_USER_SUCCESS:
      return { ...state, loaded: true, user: payload };
    case UserTypes.FETCH_PAYMENT_HISTORY:
      return { ...state, loaded: true, paymentHistory: payload };
    case UserTypes.RESET_USER:
      return { ...state, loaded: true };
    case UserTypes.GET_USER_ADDRESS:
      return { ...state, address: payload };
    case AdminUserTypes.ADD_USER_SUCCESS:
      return {
        ...state,
        loaded: true,
        newUser: payload,
      };
    case AdminUserTypes.GET_USERS_SUCCESS:
      return { ...state, loaded: true, userList: payload };
    case AdminUserTypes.RESET_USER:
      return { ...state, loaded: true };
    case AdminUserTypes.GET_EXPEDITORS_SUCCESS:
      return { ...state, loaded: true, expeditorsList: payload };
    default:
      return state;
  }
};
