import { IThunkAction } from 'types/IThunkAction';
import { startLoading, stopLoading } from 'client/_redux/actions/loading';
import { errorHandler } from 'client/helpers/errorHandler';
import * as AddressService from 'client/_redux/services/address';
import { toast } from 'react-toastify';
import { AddressFormType } from 'types/forms/AddressFormType';
import { UserTypes } from 'client/_redux/types/userTypes';

export const getAddressById = (id?: string): IThunkAction => async (dispatch) => {
  if (!id) return;
  dispatch(startLoading());
  try {
    const response = await AddressService.getAddressById()(id);

    dispatch({
      type: UserTypes.GET_USER_ADDRESS,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const updateAddress = (data: AddressFormType & { _id: string }): IThunkAction => async (
  dispatch,
) => {
  dispatch(startLoading());
  try {
    const { _id, ...rest } = data;

    const response = await AddressService.updateAddress()(_id, rest);

    dispatch({
      type: UserTypes.GET_USER_ADDRESS,
      payload: response.data,
    });
    toast('colis modifier avec succès', { type: 'success' });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const createAddress = (data: AddressFormType): IThunkAction => async (dispatch) => {
  dispatch(startLoading());

  try {
    const response = await AddressService.createAddress()(data);

    dispatch(getAddressById(response.data._id));
    toast('votre adresse a été créée avec succès', { type: 'success' });
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};
