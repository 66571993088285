import Modal from 'client/components/Modal';
import ModalFooter from 'client/components/Modal/ModalFooter';
import DataTable from 'client/containers/DataTableContainer';
import { getAllUsers } from 'client/_redux/actions/user';
import * as React from 'react';
import { UserRolesEnum } from 'types/Enums/UserRolesEnum';
import { FormProvider, useForm } from 'react-hook-form';
import Validators from 'client/helpers/formValidator';
import FormDatePicker from 'client/components/Form/FormDatePicker';
import PaymentInvoice from 'client/components/Invoice/PaymentInvoice';
import { useUser } from './useUser';
import ExpandedRows from './ExpandableRow';
import UserFilterComponent from './UserFilterComponent';

const UserList: React.FC = () => {
  const {
    userList,
    loaded,
    columns,
    dataTableRef,
    isDeleteModalVisible,
    setIsDeleteModalVisible,
    paymentModal,
    setPaymentModal,
    handlePayExpeditor,
    selectedItem,
    onDelete,
    onAdd,
    itemToPrint,
    printModal,
    setPrintModal,
  } = useUser();

  const formMethods = useForm();
  const { handleSubmit } = formMethods;

  const _onSubmit = (values: { from: string; to: string }) => {
    if (!selectedItem) return;
    const { _id } = selectedItem;

    handlePayExpeditor({ _id, ...values });
  };

  return (
    <div className="d-flex justify-content-around flex-wrap">
      <DataTable
        myRef={dataTableRef}
        loading={!loaded}
        data={userList}
        title="Utilisateur"
        columns={columns}
        onAddButtonClick={onAdd}
        fetchDataFn={getAllUsers}
        expandableRows
        expandableIcon={{
          collapsed: <i className="fas fa-chevron-circle-down m-auto" />,
          expanded: <i className="fas fa-chevron-circle-up m-auto" />,
        }}
        ExpandedComponent={ExpandedRows}
        expandableRowDisabled={(item) => item.role !== UserRolesEnum.ROLE_EXPEDITOR}
        subHeaderComponent={<UserFilterComponent />}
      />
      <Modal
        isOpen={isDeleteModalVisible}
        closeModal={() => setIsDeleteModalVisible(false)}
        modalHead="Model Suppression"
        footer={ModalFooter({
          handleSubmitModal: onDelete,
          cancelModal: () => setIsDeleteModalVisible(false),
          modalButtonText: 'Supprimer',
        })}
      />
      <Modal
        isOpen={paymentModal}
        closeModal={() => setPaymentModal(false)}
        modalHead="Payment"
      >
        <FormProvider {...formMethods}>
          <form className="mt-2" onSubmit={handleSubmit(_onSubmit)}>
            <FormDatePicker
              validate={Validators([{ validation: 'required' }])}
              withFullScreenPortal={false}
              label="de:"
              name="from"
            />
            <FormDatePicker
              validate={Validators([{ validation: 'required' }])}
              withFullScreenPortal={false}
              label="a:"
              name="to"
            />

            <div className="align-items-center mb-5">
              <div className="text-center">
                <button
                  className="btn btn-outline-primary transition-3d-hover btn-md btn-block mb-1"
                  type="submit"
                >
                  Payée
                </button>
              </div>
            </div>
          </form>
        </FormProvider>
      </Modal>
      <Modal
        isOpen={printModal}
        closeModal={() => {
          setPrintModal(false);
        }}
        modalHead="Facture de payment"
      >
        {itemToPrint && <PaymentInvoice paymentInvoice={itemToPrint} />}
      </Modal>
    </div>
  );
};

export default UserList;
