import { RunsheetTypes } from 'client/_redux/types/RunsheetTypes';
import { emptyPaginationPayload } from 'server/constants/emptyPaginationPayload';
import { IRunsheetDocument } from 'server/models/Runsheet/types';
import { IUserDocument } from 'server/models/User/types';
import { PaginatedResponse } from 'types/IPaginationResponse';
import { IReduxAction } from 'types/IReduxAction';
export interface IRunsheetState {
  loading: boolean;
  loaded: boolean;
  runsheetList: PaginatedResponse<IRunsheetDocument>;
  runsheet?: IRunsheetDocument;
  manifest?: IRunsheetDocument;
  driversList?: IUserDocument[];
}

const initialState: IRunsheetState = {
  loading: false,
  loaded: false,
  runsheetList: emptyPaginationPayload,
};

export default (state = initialState, { type, payload }: IReduxAction): IRunsheetState => {
  switch (type) {
    case RunsheetTypes.FETCH_RUNSHEET_SUCCESS:
      return { ...state, loaded: true, runsheetList: payload };
    case RunsheetTypes.FETCH_RUNSHEET_FAILURE:
      return { ...state, loaded: true, runsheetList: emptyPaginationPayload };
    case RunsheetTypes.FETCH_DRIVERS_SUCCESS:
      return { ...state, loaded: true, driversList: payload };
    case RunsheetTypes.FETCH_DRIVERS_FAILURE:
      return { ...state, loaded: true, driversList: [] };
    case RunsheetTypes.CREATE_RUNSHEET_SUCCESS:
      return { ...state, loaded: true, runsheet: payload };
    case RunsheetTypes.CREATE_MANIFEST_SUCCESS:
      return { ...state, loaded: true, manifest: payload };
    case RunsheetTypes.CREATE_RUNSHEET_FAILURE:
      return { ...state, loaded: true, runsheet: undefined };

    default:
      return state;
  }
};
