import { clientApi } from 'api';
import { SearchQuery } from 'client/containers/DataTableContainer/types';
import { PaginatedResponse } from 'types/IPaginationResponse';
// import { INotification } from 'client/_redux/actions/user';
import { IUser } from 'server/models/User/types';

const baseUrl = `/users`;

export const addUser = (isServer = false) => async (values: Partial<IUser>) => {
  const response = await clientApi.post(`${baseUrl}/add-user`, values);

  return response;
};
export const getAllUsers = (isServer = false) => async (searchQuery: SearchQuery<IUser>) => {
  const { data } = await clientApi.get<PaginatedResponse<IUser>>(baseUrl, {
    params: searchQuery,
  });

  return data;
};
export const getAllExpeditors = (isServer = false) => async (
  searchQuery?: SearchQuery<IUser>,
) => {
  const { data } = await clientApi.get<PaginatedResponse<IUser>>(baseUrl, {
    params: { ...searchQuery, role: 'expeditor' },
  });

  return data;
};
export const updateUser = (isServer = false) => async (
  values: Partial<IUser>,
  userId: string,
) => {
  const response = await clientApi.patch(`${baseUrl}/update-user/${userId}`, values);

  return response;
};

export const updateUserPassword = (isServer = false) => async (
  { password }: Partial<IUser>,
  userId: string,
) => {
  const response = await clientApi.patch(`${baseUrl}/update-password/${userId}`, { password });

  return response;
};
export const getUserById = (isServer = false) => async (_id: string) => {
  const { data } = await clientApi.get<IUser>(`${baseUrl}/${_id}`);

  return data;
};
export const deleteUserById = (isServer = false) => async (userId: string) => {
  const response = await clientApi.patch(`${baseUrl}/delete/${userId}`);

  return response;
};
export const banUserById = (isServer = false) => async (userId: string) => {
  const response = await clientApi.patch(`${baseUrl}/ban/${userId}`);

  return response;
};
export const allowUserById = (isServer = false) => async (userId: string) => {
  const response = await clientApi.patch(`${baseUrl}/allow/${userId}`);

  return response;
};
// export const sendNotification = (isServer = false) => async (notification: INotification) => {
//   const { data } = await clientApi.post(`notification`, { ...notification });

//   return data;
// };
