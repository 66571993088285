import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'client/_redux/reducers';
import { useTableColumnsWithActions } from 'client/containers/DataTableContainer/useTableColumnsWithActions';
import { useCallback, useRef, useState } from 'react';
import { DataTableHandle } from 'client/containers/DataTableContainer/types';
import { IUserPackageDocument } from 'server/models/Package/types';
import { deletePackage } from 'client/_redux/actions/packages';
import { ExpeditorPackageTableColumns } from './ExpeditorPackagesTableColumes';

export const usePickup = () => {
  const { expeditorPackages, loaded } = useSelector(({ packages }: RootState) => packages);

  const dataTableRef = useRef<DataTableHandle>(null);
  const dispatch = useDispatch();
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState<boolean>(false);
  const [isPrintModalVisible, setIsPrintModalVisible] = useState<boolean>(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);
  const [itemToUpdateOrDelete, setItemToUpdateOrDelete] = useState<IUserPackageDocument>();
  const [addPackageModal, setAddPackageModal] = useState(false);

  const handleCloseModal = () => {
    setIsUpdateModalVisible(false);
    setItemToUpdateOrDelete(undefined);
  };
  const handleClosePrintModal = () => {
    setIsPrintModalVisible(false);
    setItemToUpdateOrDelete(undefined);
  };
  const onDelete = useCallback(() => {
    if (!itemToUpdateOrDelete) return;
    dispatch(deletePackage(itemToUpdateOrDelete._id));
    setIsDeleteModalVisible(false);
  }, [dispatch, itemToUpdateOrDelete]);

  const handleUpdatePackage = useCallback((item: IUserPackageDocument) => {
    setItemToUpdateOrDelete(item);
    setIsUpdateModalVisible(true);
  }, []);
  const handleDeletePackage = useCallback((item: IUserPackageDocument) => {
    setItemToUpdateOrDelete(item);
    setIsDeleteModalVisible(true);
  }, []);
  const handlePrintPackage = useCallback((item: IUserPackageDocument) => {
    setItemToUpdateOrDelete(item);
    setIsPrintModalVisible(true);
  }, []);

  const columns = useTableColumnsWithActions({
    columns: ExpeditorPackageTableColumns(),
    buttons: [
      {
        className: (item) => `fa fa-pen`,
        fn: handleUpdatePackage,
        color: 'black',
      },
      {
        className: (item) => `fas fa-print`,
        fn: handlePrintPackage,
        color: 'black',
      },
      {
        className: (item) => `fa fa-times`,
        fn: handleDeletePackage,
        color: 'red',
      },
    ],
  });

  return {
    expeditorPackages,
    loaded,
    columns,
    dataTableRef,
    isUpdateModalVisible,
    isDeleteModalVisible,
    setIsDeleteModalVisible,
    handleCloseModal,
    onDelete,
    itemToUpdateOrDelete,
    addPackageModal,
    setAddPackageModal,
    isPrintModalVisible,
    handleClosePrintModal,
  };
};
