import React from 'react';
import { FormFelidType } from 'types/FormFelidTypes';
import FormBlock from './FormBlock';

type FormBlockContainerProps = {
  blockTitle: string;
  blockIcon: JSX.Element;
  fields: FormFelidType[];
  className?: string;
};
const FormBlockContainer: React.FC<FormBlockContainerProps> = ({
  blockIcon,
  blockTitle,
  fields,
  className,
}) => (
  <div className={`text-center ${className || ''}`}>
    <span className="h4">
      {blockTitle} {blockIcon}
    </span>
    <FormBlock fields={fields} />
  </div>
);

export default FormBlockContainer;
