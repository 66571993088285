/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { IAddressDocument } from 'server/models/Address/types';
import { IExchangePackage } from 'server/models/ExchangePackage/types';
import { IUserPackageDocument } from 'server/models/Package/types';
import BarCode from 'react-barcode';
import QRCode from 'react-qr-code';
import NextPackSignature from '../Invoice/NextPackSignature';
import SingleParcelContent from '../Invoice/SingleParcelContent';

type ExchangePackageInvoiceContentProps = {
  parcel: IExchangePackage;
  invoiceRef: React.MutableRefObject<null>;
  mainPackage: IUserPackageDocument;
};
const ExchangePackageInvoiceContent: React.FC<ExchangePackageInvoiceContentProps> = ({
  parcel,
  invoiceRef,
  mainPackage,
}) => (
  <div className="p-5" ref={invoiceRef}>
    <div className="d-flex justify-content-center pb-3 ">
      <div
        className="d-flex justify-content-center rounded border-danger py-3 mb-5"
        style={{ border: 'solid', width: 350 }}
      >
        <span className="h3 text-danger">Bon de livraison échange</span>
      </div>
    </div>

    <div className="d-flex justify-content-between pb-3">
      <NextPackSignature />
      <div className="fiscal-information rounded ">
        <div className="rounded mb-3 mt-3" style={{ border: 'solid', width: 350 }}>
          <div className="m-2">
            <div className="d-flex justify-content-between">
              <span className="h5 pr-1">Nom de l'expéditeur:</span>
              <span>
                {parcel?.mainPackage?.expeditor?.firstName}{' '}
                {parcel?.mainPackage?.expeditor?.lastName}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="h5 pr-1">Adresse:</span>
              <span>
                {(parcel?.mainPackage?.expeditor?.address as IAddressDocument)?.city},
                {(parcel?.mainPackage?.expeditor?.address as IAddressDocument)?.country},
                {(parcel?.mainPackage?.expeditor?.address as IAddressDocument)?.state},
                {(parcel?.mainPackage?.expeditor?.address as IAddressDocument)?.address}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="h5 pr-1">Telephone:</span>
              <span>{parcel?.mainPackage?.expeditor?.phoneNumber}</span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="h5 pr-1">M/F:</span>
              <span>{parcel?.mainPackage?.expeditor?.taxNumber}</span>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between">
          <div className="d-flex justify-content-end">
            <QRCode size={100} value={parcel.exchangeBarCode} />
          </div>
          <div className="d-flex justify-content-end">
            <BarCode width={2} height={40} value={parcel.exchangeBarCode} />
          </div>
        </div>
      </div>
    </div>
    <SingleParcelContent parcel={mainPackage} />
  </div>
);

export default ExchangePackageInvoiceContent;
