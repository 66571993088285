import React, { useState } from 'react';
import { IUser } from 'server/models/User/types';
import DataTable, { TableColumn } from 'react-data-table-component';
import { IUserPackage, IUserPackageDocument } from 'server/models/Package/types';
import { format } from 'date-fns';
import { useTableColumnsWithActions } from 'client/containers/DataTableContainer/useTableColumnsWithActions';
import Modal from 'client/components/Modal';
import Invoice from 'client/components/Invoice';
import BarCode from 'react-barcode';

export interface ExpandedRowsComponentProps {
  data: IUser;
}
const ExpandedRowsComponent = ({ data: { packages } }: ExpandedRowsComponentProps) => {
  const [isPrintModalVisible, setIsPrintModalVisible] = useState<boolean>(false);
  const [itemToPrint, setItemToPrint] = useState<IUserPackage>();

  const PackagesTableColumns = (): TableColumn<IUserPackage>[] => [
    {
      name: 'code a bare',
      cell: (e) => <BarCode width={1} height={40} value={e.barCode} />,
      center: true,
    },
    {
      name: 'Nom du client',
      selector: (e) => `${e.client.name}`,
      center: true,
    },
    {
      name: 'Etat',
      selector: (e) => `${e.state}`,
      center: true,
    },
    {
      name: 'Telephone',
      selector: (e) => `${e.client.clientPhoneNumber}`,
      center: true,
    },
    {
      sortField: 'createdAt',
      name: 'Date de creation',
      cell: (e) => e.createdAt && format(new Date(e.createdAt), 'MM/dd/yyyy HH:mm'),
      center: true,
    },
  ];
  const columns = useTableColumnsWithActions({
    columns: PackagesTableColumns(),
    buttons: [
      {
        className: () => `fa fa-print`,
        // eslint-disable-next-line no-console
        fn: (item) => {
          setItemToPrint(item);
          setIsPrintModalVisible(true);
        },
        color: 'black',
      },
    ],
  });
  const handleClosePrintModal = () => {
    setIsPrintModalVisible(false);
    setItemToPrint(undefined);
  };

  return (
    <div className="container my-5">
      <div className="row d-flex justify-content-center">
        <DataTable data={packages as IUserPackage[]} columns={columns} />
      </div>
      <Modal
        isOpen={isPrintModalVisible}
        closeModal={handleClosePrintModal}
        modalHead="Imprimer votre colis"
      >
        <Invoice parcel={itemToPrint as IUserPackageDocument} />
      </Modal>
    </div>
  );
};

export default ExpandedRowsComponent;
