import React from 'react';
import { useImportCsv } from './useImportCsv';

type ImportCsvProps = {
  setAddPackageModal?: React.Dispatch<React.SetStateAction<boolean>>;
  isFastPackage?: boolean;
};
const ImportCsv: React.FC<ImportCsvProps> = ({ setAddPackageModal, isFastPackage }) => {
  const { filePathSet, readFile } = useImportCsv(setAddPackageModal, isFastPackage);

  return (
    <div className="d-flex flex-column justify-content-center">
      <div className="mb-2" style={{ height: '55px' }}>
        <input
          id="files"
          type="file"
          onChange={(element) => element.target.files && filePathSet(element.target.files[0])}
        />
      </div>

      <div className="d-flex flex-column justify-content-center" style={{ zIndex: 1 }}>
        <button type="button" className="btn btn-primary" onClick={readFile}>
          Importer le ficher
        </button>
      </div>
    </div>
  );
};

export default ImportCsv;
