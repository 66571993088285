import NavBarItem from 'client/components/NavBarItem';
import { useWindowSize } from 'client/hooks/useWindowSize';
import { signOut } from 'client/_redux/actions/auth';
import { RootState } from 'client/_redux/reducers';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserRolesEnum } from 'types/Enums/UserRolesEnum';
import { SelectorStyleProperties } from '.';
import { adminNavBarLinks, expeditorNavBarLinks, managerNavBarLinks } from './navBarLinks';
export const useNavBar = () => {
  const [selectorStyle, setSelectorStyle] = useState<SelectorStyleProperties>({
    top: 0,
    height: 0,
    left: 0,
    width: 0,
  });
  const dispatch = useDispatch();
  const { isLoggedIn, role, userBalance } = useSelector(
    ({ auth: { isLoggedIn }, user: { user } }: RootState) => ({
      isLoggedIn,
      role: user?.role,
      userBalance: user?.balance,
    }),
  );
  const logout = () => {
    dispatch(signOut());
  };
  const [mobileMenuDisplay, setMobileMenuDisplay] = useState('none');
  const [addPackageModal, setAddPackageModal] = useState(false);

  const { width } = useWindowSize();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const toggleMobileMenu = () => {
    mobileMenuDisplay === 'none'
      ? setMobileMenuDisplay('block')
      : setMobileMenuDisplay('none');
  };
  const nabBarLinks = useMemo(() => {
    switch (role) {
      case UserRolesEnum.ROLE_EXPEDITOR:
        return expeditorNavBarLinks.map((link) => (
          <NavBarItem
            key={link.title}
            setSelectorStyle={setSelectorStyle}
            link={link}
            width={width}
            closeMobileMenu={toggleMobileMenu}
          />
        ));
      case UserRolesEnum.ROLE_MANAGER:
        return managerNavBarLinks.map((link) => (
          <NavBarItem
            key={link.title}
            setSelectorStyle={setSelectorStyle}
            link={link}
            width={width}
            closeMobileMenu={toggleMobileMenu}
          />
        ));
      case UserRolesEnum.ROLE_ADMIN:
        return adminNavBarLinks.map((link) => (
          <NavBarItem
            key={link.title}
            setSelectorStyle={setSelectorStyle}
            link={link}
            width={width}
            closeMobileMenu={toggleMobileMenu}
          />
        ));
      default:
        return expeditorNavBarLinks.map((link) => (
          <NavBarItem
            key={link.title}
            setSelectorStyle={setSelectorStyle}
            link={link}
            width={width}
            closeMobileMenu={toggleMobileMenu}
          />
        ));
    }
  }, [role, toggleMobileMenu, width]);

  return {
    setSelectorStyle,
    selectorStyle,
    isLoggedIn,
    logout,
    nabBarLinks,
    width,
    mobileMenuDisplay,
    userBalance,
    role,
    toggleMobileMenu,
    addPackageModal,
    setAddPackageModal,
  };
};
