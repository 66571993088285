import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { registerNotificationToken } from 'client/_redux/actions/auth';
import { RootState } from 'client/_redux/reducers';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import { toast } from 'react-toastify';
import NotificationToast from 'client/components/NotificationToast';
const firebaseConfig = {
  apiKey: 'AIzaSyDgrFlO4gdHyjtkWnlIg6S5EyiELdkO_fM',
  authDomain: 'nextpacktn.firebaseapp.com',
  projectId: 'nextpacktn',
  storageBucket: 'nextpacktn.appspot.com',
  messagingSenderId: '772200032171',
  appId: '1:772200032171:web:7c2c3892202ee77359d4ee',
  measurementId: 'G-N8JK58PF9T',
};

const { FIREBASE_VAPID_KEY } = process.env;
const publicKey = FIREBASE_VAPID_KEY;

const windowExist = typeof window !== 'undefined' && 'serviceWorker' in navigator;

const useNotification = () => {
  const { user } = useSelector(({ user: { user } }: RootState) => ({
    user,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    if (!windowExist || !user || Notification.permission === 'denied') return;
    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);

    getToken(messaging, { vapidKey: publicKey }).then((el) =>
      dispatch(registerNotificationToken(el)),
    );
  }, [user, dispatch]);

  useEffect(() => {
    if (windowExist) {
      const app = initializeApp(firebaseConfig);
      const messaging = getMessaging(app);

      onMessage(messaging, (payload) => {
        payload.notification &&
          toast(
            <NotificationToast
              body={payload.notification.body}
              title={payload.notification.title}
            />,
            {
              type: 'dark',
              pauseOnHover: true,
              progressStyle: { color: 'red' },
              autoClose: false,
              closeOnClick: true,
            },
          );
      });
    }
  }, []);
};

export default useNotification;
