import DataTable from 'client/containers/DataTableContainer';
import { getAllPackagesManager } from 'client/_redux/actions/packages';
import * as React from 'react';
import SelectDriverModal from '../SelectDriverModal';
import ExpandedRowsComponent from './ExpandedRowsComponent';
import PackageFilter from './PackageFilter';
import { usePickup } from './usePickup';

const ManagerPickupList: React.FC = () => {
  const {
    columns,
    dataTableRef,
    expeditorsList,
    togglePickupModal,
    setTogglePickupModal,
    onSubmit,
    setDriver,
  } = usePickup();

  return (
    <div className="d-flex justify-content-around flex-wrap">
      <DataTable
        myRef={dataTableRef}
        data={expeditorsList}
        title="liste de pickup"
        columns={columns}
        fetchDataFn={getAllPackagesManager}
        expandableRows
        expandableIcon={{
          collapsed: <i className="fas fa-chevron-circle-down m-auto" />,
          expanded: <i className="fas fa-chevron-circle-up m-auto" />,
        }}
        ExpandedComponent={ExpandedRowsComponent}
        subHeaderComponent={<PackageFilter />}
      />
      <SelectDriverModal
        onSubmit={onSubmit}
        setDriver={setDriver}
        setToggleModal={setTogglePickupModal}
        toggleModal={togglePickupModal}
      />
    </div>
  );
};

export default ManagerPickupList;
