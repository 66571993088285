import { Route, Routes } from 'react-router-dom';
import Home from 'client/pages/Home';
import NotFound from 'client/pages/NotFound';
import * as React from 'react';

  export const renderClassicRotes = () => (
    <>
      <Route path="/" element={<Home />} />
      <Route path="/*" element={<NotFound />} />
    </>
  );

