import React from 'react';

export interface IModalFooter {
  handleSubmitModal: () => void;
  cancelModal: () => void;
  modalButtonText?: string;
}

const ModalFooter: React.FC<IModalFooter> = ({
  cancelModal,
  handleSubmitModal,
  modalButtonText,
}) => (
  <>
    <button className="btn btn-primary mr-3" onClick={cancelModal} type="button">
      Annuler
    </button>
    <button className="btn btn-primary" onClick={handleSubmitModal} type="button">
      {`${modalButtonText || 'Envoyer'}`}
    </button>
  </>
);

export default ModalFooter;
