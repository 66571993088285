import { AuthTypes } from 'client/_redux/types/authTypes';
import { IToken } from 'types/Auth';
import { IReduxAction } from 'types/IReduxAction';

export interface IAuthState {
  isLoggedIn: boolean;
  loading: boolean;
  loaded: boolean;
  error: boolean;
  token?: IToken;
}

const initialState: IAuthState = {
  isLoggedIn: false,
  loading: false,
  loaded: false,
  error: false,
};

export default (state = initialState, { type, payload }: IReduxAction): IAuthState => {
  switch (type) {
    case AuthTypes.USER_LOGGED_OUT_TOKEN:
      return initialState;
    case AuthTypes.SET_TOKEN:
    case AuthTypes.SIGN_IN_SUCCESS:
      return { ...state, isLoggedIn: true, loaded: true };
    case AuthTypes.RESET_TOKEN:
      return { ...state, isLoggedIn: false, loaded: true };
    default:
      return state;
  }
};
