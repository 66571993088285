/* eslint-disable max-lines */
import React from 'react';
import { TableColumn } from 'react-data-table-component';
import { IUserPackageDocument } from 'server/models/Package/types';
import Select from 'client/components/Form/Select';
import {
  managerResetPackageStateOptions,
  managerReturnCuzOptions,
} from 'client/constants/SelectOptions';
import { changePackageState } from 'client/_redux/actions/runsheet';
import { PackageStateEnum } from 'types/Enums/PackageStateEnum';
import { RunsheetValidation } from 'types/RunsheetValidationType';
import { PackageReturnCuzEnum } from 'types/Enums/PackageReturnCuzEnum';

export const RunsheetPackagesColumns = (
  runsheetId?: string,
  setPackagesList?: React.Dispatch<React.SetStateAction<RunsheetValidation>>,
  isFailed?: boolean,
  setExchangePackage?: React.Dispatch<React.SetStateAction<IUserPackageDocument | undefined>>,
  toggleModal?: React.Dispatch<React.SetStateAction<boolean>>,
  scannedExchangeList?: string[],
  showCuzSelect?: string,
  setShowCuzSelect?: React.Dispatch<React.SetStateAction<string>>,
): TableColumn<IUserPackageDocument>[] =>
  isFailed
    ? [
        {
          name: 'Changer état du colis',
          cell: (e) => (
            <Select
              style={{ width: '100px' }}
              options={managerResetPackageStateOptions}
              name="packageState"
              onChange={(event) =>
                runsheetId &&
                (event === PackageStateEnum.DELIVERED
                  ? (changePackageState(e._id, event as PackageStateEnum, runsheetId).then(
                      (packagesList) => setPackagesList && setPackagesList(packagesList),
                    ),
                    setShowCuzSelect && setShowCuzSelect(''))
                  : setShowCuzSelect && setShowCuzSelect(e._id))
              }
            />
          ),
          center: true,
        },
        {
          name: 'Cuz de retour',
          cell: (e) =>
            showCuzSelect &&
            showCuzSelect === e._id && (
              <Select
                options={managerReturnCuzOptions}
                style={{ width: '100px' }}
                name="packageState"
                onChange={(event) =>
                  runsheetId &&
                  changePackageState(
                    e._id,
                    PackageStateEnum.DELIVERING_FAIlED,
                    runsheetId,
                    event as PackageReturnCuzEnum,
                  ).then((packagesList) => setPackagesList && setPackagesList(packagesList))
                }
              />
            ),
          center: true,
        },
        {
          name: 'nom du client',
          selector: (e) => `${e?.client?.name}`,
          center: true,
        },

        {
          name: <i className="fa fa-phone" />,
          cell: (e) => (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                backgroundColor: '#5161ce',
                width: 30,
                height: 30,
                borderRadius: '50%',
              }}
            >
              <i className="fa fa-phone m-0 text-white" />
              <div
                style={{
                  top: 10,
                  left: 60,
                  backgroundColor: '#13d61e',
                  width: 20,
                  height: 20,
                  borderRadius: '50%',
                }}
                className="position-absolute d-flex justify-content-center align-items-center text-white"
              >
                {e.deliveringDetails.length}
              </div>
            </div>
          ),
          center: true,
        },
        {
          name: 'T',
          selector: (e) => `${e?.packageDeliveringAttempt}`,
          center: true,
        },
        {
          name: 'Status',
          selector: (e) => `${e?.state}`,
          center: true,
        },
        {
          name: "Nom d'article",
          selector: (e) => `${e?.articleName}`,
          center: true,
        },
        {
          name: 'Prix',
          selector: (e) => `${e?.price} DT`,
          center: true,
        },

        {
          name: 'Destination',
          selector: (e) => `${e?.destination?.city}-${e?.destination?.address}`,
          center: true,
        },
      ]
    : [
        {
          name: 'Changer état du colis',
          cell: (e) => (
            <Select
              style={{ width: '100px' }}
              options={managerResetPackageStateOptions}
              name="packageState"
              onChange={(event) =>
                runsheetId &&
                (event === PackageStateEnum.DELIVERED
                  ? (changePackageState(e._id, event as PackageStateEnum, runsheetId).then(
                      (packagesList) => setPackagesList && setPackagesList(packagesList),
                    ),
                    setShowCuzSelect && setShowCuzSelect(''))
                  : setShowCuzSelect && setShowCuzSelect(e._id))
              }
            />
          ),
          center: true,
        },
        {
          name: 'Cuz de retour',
          cell: (e) =>
            showCuzSelect &&
            showCuzSelect === e._id && (
              <Select
                options={managerReturnCuzOptions}
                name="packageState"
                onChange={(event) =>
                  runsheetId &&
                  changePackageState(
                    e._id,
                    PackageStateEnum.DELIVERING_FAIlED,
                    runsheetId,
                    event as PackageReturnCuzEnum,
                  ).then((packagesList) => setPackagesList && setPackagesList(packagesList))
                }
              />
            ),
          center: true,
        },
        {
          name: 'Colis echange',
          cell: (e) =>
            e?.exchangePackage ? (
              <i
                style={{
                  color: scannedExchangeList?.includes(e?._id) ? '#5161ce' : 'black',
                }}
                className="fa fa-print cursor-pointer"
                onClick={() => {
                  setExchangePackage && setExchangePackage(e);
                  toggleModal && toggleModal(true);
                }}
              />
            ) : (
              'Non'
            ),
          center: true,
        },
        {
          name: 'nom du client',
          selector: (e) => `${e?.client?.name}`,
          center: true,
        },
        {
          name: <i className="fa fa-phone" />,
          cell: (e) => (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                backgroundColor: '#5161ce',
                width: 30,
                height: 30,
                borderRadius: '50%',
              }}
            >
              <i className="fa fa-phone m-0 text-white" />
              <div
                style={{
                  top: 10,
                  left: 60,
                  backgroundColor: '#13d61e',
                  width: 20,
                  height: 20,
                  borderRadius: '50%',
                }}
                className="position-absolute d-flex justify-content-center align-items-center text-white"
              >
                {e.deliveringDetails.length}
              </div>
            </div>
          ),
          center: true,
        },
        {
          name: 'T',
          selector: (e) => `${e?.packageDeliveringAttempt}`,
          center: true,
        },
        {
          name: 'Status',
          selector: (e) => `${e?.state}`,
          center: true,
        },
        {
          name: "Nom d'article",
          selector: (e) => `${e?.articleName}`,
          center: true,
        },
        {
          name: 'Prix',
          selector: (e) => `${e?.price} DT`,
          center: true,
        },
        {
          name: 'Destination',
          selector: (e) => `${e?.destination?.city}-${e?.destination?.address}`,
          center: true,
        },
      ];
