import ManifestForm from 'client/containers/ManifestForm';
import React from 'react';

const CreateManifest: React.FC = () => (
  <div className="container">
    <ManifestForm />
  </div>
);

export default CreateManifest;
