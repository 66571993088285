import { getDrivers } from 'client/_redux/actions/runsheet';
import { useCallback, useEffect, useState } from 'react';
import { IUserDocument } from 'server/models/User/types';

export const useSelectDriverModal = (
  setDriver: React.Dispatch<React.SetStateAction<string>>,
) => {
  useEffect(() => {
    getDrivers().then((drivers) => setDriversList(drivers));
  }, []);
  const [driversList, setDriversList] = useState<IUserDocument[]>([]);

  const options = driversList?.map((driver) => ({
    label: driver.firstName,
    value: driver._id,
  }));
  const handleDriverSelect = useCallback(
    (selectedOption) => {
      setDriver(selectedOption);
    },
    [setDriver],
  );

  return { options, handleDriverSelect };
};
