import Input from 'client/components/Form/Input';
import ImportCsv from 'client/components/ImportCsv';
import Select from 'client/components/Form/Select';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { IUserPackageDocument } from 'server/models/Package/types';
import FormBlockContainer from './FormBlockContainer';
import { formFields } from './formFields';
import { useFormPackage } from './usePackageForm';

type PackageFormProps = {
  isUpdate?: boolean;
  itemToUpdateOrDelete?: IUserPackageDocument;
  isFastPackage?: boolean;
  setIsUpdateModalVisible: (value: React.SetStateAction<boolean>) => void;
  setAddPackageModal?: React.Dispatch<React.SetStateAction<boolean>>;
  isAdmin?: boolean;
};
const PackageForm: React.FC<PackageFormProps> = ({
  itemToUpdateOrDelete,
  setIsUpdateModalVisible,
  isUpdate,
  setAddPackageModal,
  isFastPackage,
  isAdmin,
}) => {
  const {
    availablePackageStateToUpdate,
    handleSubmit,
    onSubmit,
    formMethods,
    options,
    handleClientSelect,
    cityOptions,
    stateOptions,
  } = useFormPackage(setIsUpdateModalVisible, isUpdate, itemToUpdateOrDelete, isFastPackage);

  const {
    addressInfoFields,
    clientInfoFields,
    packageInfoFields,
    extraInfoFields,
  } = formFields(
    availablePackageStateToUpdate,
    itemToUpdateOrDelete,
    isUpdate,
    cityOptions,
    stateOptions,
    isAdmin,
  );

  return (
    <FormProvider {...formMethods}>
      <form className="mt-5" onSubmit={handleSubmit(onSubmit)}>
        {!isUpdate && options && (
          <div className="d-flex justify-content-center pb-3">
            <div className="col-md-12">
              <div className="row justify-content-center align-items-center">
                <div className="col-md-4">
                  <Select
                    label="Cherche un client"
                    isSearchable
                    isClearable
                    options={options}
                    name="select1"
                    onChange={(value) => handleClientSelect(value)}
                  />
                </div>
                <div className="col-md-4">
                  <ImportCsv
                    setAddPackageModal={setAddPackageModal}
                    isFastPackage={isFastPackage}
                  />
                </div>
                <div className="col-md-4 d-flex justify-content-center py-2">
                  <a
                    href="/assets/files/nextPackMultipleTemplate.xlsx"
                    className="btn btn-primary"
                    download
                  >
                    Télécharger le modéle csv
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="d-flex justify-content-center">
          <div className="col-md-10">
            <FormBlockContainer
              blockIcon={<i className="fa fa-user" />}
              blockTitle="Informations sur le client"
              fields={clientInfoFields}
            />
            <FormBlockContainer
              blockIcon={<i className="fas fa-cube" />}
              blockTitle="Informations sur le colis "
              fields={packageInfoFields}
            />
            <FormBlockContainer
              blockIcon={<i className="fas fa-map-marker-alt" />}
              blockTitle="Informations sur l'adresse du client "
              fields={addressInfoFields}
            />
            <FormBlockContainer
              className="pb-4"
              blockIcon={<i className="fas fa-plus" />}
              blockTitle="Informations supplémentaires "
              fields={extraInfoFields}
            />
            <div className="text-center">
              <span className="h4">
                ajouter un commentaire <i className="fas fa-comments" />
              </span>
              <Input name="comment" id="comment" type="textarea" />
            </div>
            <div className="d-flex justify-content-center">
              <button className="btn btn-success" type="submit">
                {isUpdate ? 'Modifier' : 'Ajouter colis'}
              </button>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default PackageForm;
