import React from 'react';
import { IUserPackageDocument } from 'server/models/Package/types';
import { IRunsheetDocument } from 'server/models/Runsheet/types';
import { IUser } from 'server/models/User/types';
import BarCode from 'react-barcode';
import ManifestHeader from './ManifestHeader';

type ManifestContentProps = {
  invoiceRef: React.MutableRefObject<null>;
  manifest: IRunsheetDocument;
  expeditor: IUser;
};
const ManifestContent: React.FC<ManifestContentProps> = ({
  invoiceRef,
  manifest,
  expeditor,
}) => {
  const tableRow = (parcel: IUserPackageDocument) => (
    <tr key={manifest?._id}>
      <td>
        <BarCode width={1} height={40} value={parcel.barCode || parcel.exchangeBarCode} />
      </td>
      <td>{parcel?.expeditor?.firstName}</td>
      <td>{parcel?.articleName || ''}</td>
      <td>{parcel?.price || 0} DT</td>
      <td>{parcel?.client?.name || parcel?.mainPackage?.client?.name}</td>
      <td>
        {parcel?.client?.clientPhoneNumber || parcel?.mainPackage?.client?.clientPhoneNumber}
      </td>
      <td>
        {`${parcel?.destination?.city || parcel?.mainPackage?.destination?.city},${
          parcel?.destination?.state || parcel?.mainPackage?.destination?.city
        },${parcel?.destination?.address || parcel?.mainPackage?.destination?.city}`}
      </td>
    </tr>
  );
  const tabHeader = () => (
    <thead>
      <tr>
        <th>Code à barre</th>
        <th>Expéditeur</th>
        <th>Colis</th>
        <th>Montant</th>
        <th>Client</th>
        <th>Telephone</th>
        <th>Adresse</th>
      </tr>
    </thead>
  );
  const maxRows = 20;

  return (
    <div ref={invoiceRef} className="factureContainer factureInfo ">
      <div className="ml-5 mr-5 mt-3">
        <ManifestHeader expeditor={expeditor} manifest={manifest} />

        <div>
          <table
            cellSpacing={3}
            cellPadding={5}
            className="table table-bordered"
            style={{ border: 'solid' }}
          >
            {tabHeader()}
            {([
              ...manifest.packagesList,
              ...manifest.returnedPackagesList,
            ] as IUserPackageDocument[])?.map((parcel, index) => {
              if ((index + 1) % maxRows === 0) {
                return (
                  <React.Fragment key={parcel._id}>
                    {tableRow(parcel)}
                    {manifest.packagesList?.length > maxRows && (
                      <div className="page-counter" />
                    )}
                    <tr className="x pt-2" />
                  </React.Fragment>
                );
              }

              return <>{tableRow(parcel)}</>;
            })}
          </table>
        </div>

        <div className="d-flex justify-content-center">
          <div className="d-flex justify-content-between">
            <div className="fiscal-information rounded m-1">
              <div
                className="rounded mb-3 mt-3"
                style={{ border: 'solid', width: 350, height: 200 }}
              >
                <div className="d-flex justify-content-center">
                  <span>Expéditeur: </span>
                </div>
                <div className="d-flex justify-content-center">Signature</div>
              </div>
            </div>
            <div className="fiscal-information rounded m-1">
              <div
                className="rounded mb-3 mt-3"
                style={{ border: 'solid', width: 350, height: 200 }}
              >
                <div className="d-flex justify-content-center">
                  <span>Livreur: </span>
                </div>
                <div className="d-flex justify-content-center">Signature</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManifestContent;
