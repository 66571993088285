import Input from 'client/components/Form/Input';
import useSearchParamsFilter from 'client/hooks/useSearchParamsFilter';
import { getManagerRunsheet } from 'client/_redux/actions/runsheet';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { DeliveringPackageTypeEnum } from 'types/Enums/DeliveringPackagesEnum';

type RunsheetFilterProps = {
  seType: React.Dispatch<React.SetStateAction<DeliveringPackageTypeEnum>>;
  type: DeliveringPackageTypeEnum;
};
const RunsheetFilter: React.FC<RunsheetFilterProps> = ({ seType, type }) => {
  const { handleFilterSearch, searchParams, resetFilter } = useSearchParamsFilter({});
  const [selectedFilter, setSelectedFilter] = useState('delivery');

  const dispatch = useDispatch();

  return (
    <div className="d-flex justify-content-between align-items-center w-100 flex-wrap pt-4 flex-wrap no-gutters">
      <div className="d-flex justify-content-between align-items-center">
        <Input
          type="text"
          placeholder="Rechercher..."
          value={searchParams.get('keyword') || ''}
          onChange={(e) => handleFilterSearch('keyword', e.currentTarget.value, type)}
          name="keyword"
        />
        <button
          type="button"
          className={`btn-sm btn  m-1 ${
            selectedFilter === 'delivery' ? 'btn-primary' : 'btn-outline-primary'
          }`}
          onClick={() => {
            dispatch(getManagerRunsheet({ type: DeliveringPackageTypeEnum.DELIVERING }));
            setSelectedFilter('delivery');
            seType(DeliveringPackageTypeEnum.DELIVERING);
          }}
        >
          Delivery
        </button>
        <button
          type="button"
          className={`btn-sm btn  m-1 ${
            selectedFilter === 'pickup' ? 'btn-primary' : 'btn-outline-primary'
          }`}
          onClick={() => {
            dispatch(getManagerRunsheet({ type: DeliveringPackageTypeEnum.PICKUP }));
            setSelectedFilter('pickup');
            seType(DeliveringPackageTypeEnum.PICKUP);
          }}
        >
          Pickup
        </button>
        <button
          type="button"
          className={`btn-sm btn  m-1 ${
            selectedFilter === 'manifest' ? 'btn-primary' : 'btn-outline-primary'
          }`}
          onClick={() => {
            dispatch(getManagerRunsheet({ type: DeliveringPackageTypeEnum.RETURN }));
            setSelectedFilter('manifest');
            seType(DeliveringPackageTypeEnum.RETURN);
          }}
        >
          Manifests
        </button>
      </div>

      <div>
        <button
          type="button"
          className="btn-sm btn btn-outline-primary m-1"
          onClick={() => resetFilter()}
        >
          Reset
        </button>
      </div>
    </div>
  );
};

export default RunsheetFilter;
