export enum PackageStateEnum {
  PACKAGE_CREATED = 'Colis en attente',
  SELECTED_FOR_PICKUP = 'Colis affectée',
  PICKED_UP = 'Colis ramassée',
  PACKAGE_STORED = 'Acceptée magazin',
  PACKAGE_HEADING_TO_CLIENT = 'Colis en cours',
  DELIVERED = 'Colis livrée',
  DELIVERED_AND_PAYED = 'Colis livrée payée',
  RETURNED_TO_WAREHOUSE = 'Retour depot',
  PACKAGE_REFUSED_AND_WILL_RETURN_TO_EXPEDITOR = 'Retour definitive',
  RETURNED_TO_EXPEDITOR = 'Retour expéditeur',
  PACKAGE_RETURNED_TO_EXPEDITOR = 'Retour expéditeur récue',
  PACKAGE_RETURNED_TO_EXPEDITOR_AND_PAYED = 'Retour expéditeur payée',
  DELIVERING_FAIlED = 'livraison échoué',
  EXCHANGE_PACKAGE = 'Colis echange',
}
