import React from 'react';
import { IUserPackageDocument } from 'server/models/Package/types';
import { IPaymentHistory } from 'server/models/PaymentHistory/types';
import BarCode from 'react-barcode';
import InvoiceHeader from './InvoiceHeader';

type PaymentInvoiceContentProps = {
  invoiceRef: React.MutableRefObject<null>;
  paymentInvoice: IPaymentHistory;
};
const PaymentInvoiceContent: React.FC<PaymentInvoiceContentProps> = ({
  invoiceRef,
  paymentInvoice,
}) => {
  const tableRow = (parcel: IUserPackageDocument) => (
    <tr
      style={{
        color: parcel.exchangePackage ? 'red' : 'black',
      }}
      key={paymentInvoice?._id}
    >
      <td>
        <BarCode width={1} height={40} value={parcel.barCode} />
      </td>
      <td>{parcel?.articleName}</td>
      <td>{parcel?.price} DT</td>
      <td>{parcel.client.name}</td>
      <td>{parcel.client.clientPhoneNumber}</td>
      <td>
        {`${parcel.destination.city},${parcel.destination.state},${parcel.destination.address}`}
      </td>
    </tr>
  );
  const tabHeader = () => (
    <thead>
      <tr>
        <th>Code à barre</th>
        <th>Colis</th>
        <th>Montant</th>
        <th>Client</th>
        <th>Telephone</th>
        <th>Adresse</th>
      </tr>
    </thead>
  );
  const maxRows = 20;

  return (
    <div ref={invoiceRef} className="factureContainer factureInfo ">
      <div className="ml-5 mr-5 mt-3">
        {paymentInvoice?.expeditor && <InvoiceHeader expeditor={paymentInvoice?.expeditor} />}

        <span className="h1">Facture code: {paymentInvoice.paymentCode}</span>
        <table
          cellSpacing={3}
          cellPadding={5}
          className="table table-bordered"
          style={{ border: 'solid' }}
        >
          <thead>
            <tr>
              <th>Nbr colis retour</th>
              <th>Nbr colis livrée</th>
              <th>Frais livrison</th>
              <th>Frais retour</th>
              <th>Montant recue</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center">{paymentInvoice.returnedPackages.length} </td>
              <td className="text-center">{paymentInvoice.packages.length}</td>
              <td className="text-center">{paymentInvoice.deliveryFee} DT</td>
              <td className="text-center">{paymentInvoice.returnedPackagesFee} DT</td>
              <td className="text-center">{paymentInvoice.paymentAmount} DT</td>
              <td className="text-center">{paymentInvoice.total} DT </td>
            </tr>
          </tbody>
        </table>
        <div>
          <table
            cellSpacing={3}
            cellPadding={5}
            className="table table-bordered"
            style={{ border: 'solid' }}
          >
            {tabHeader()}
            {(paymentInvoice.packages as IUserPackageDocument[])?.map((parcel, index) => {
              if ((index + 1) % maxRows === 0) {
                return (
                  <>
                    {tableRow(parcel)}
                    {paymentInvoice.packages?.length > maxRows && (
                      <div className="page-counter" />
                    )}
                    <tr className="x pt-2" />
                  </>
                );
              }

              return <>{tableRow(parcel)}</>;
            })}
          </table>
        </div>

        <div className="d-flex justify-content-center">
          <div className="d-flex justify-content-between">
            <div className="fiscal-information rounded m-1">
              <div
                className="rounded mb-3 mt-3"
                style={{ border: 'solid', width: 350, height: 200 }}
              >
                <div className="d-flex justify-content-center">
                  <span>Administration: </span>
                </div>
                <div className="d-flex justify-content-center">Signature</div>
              </div>
            </div>
            <div className="fiscal-information rounded m-1">
              <div
                className="rounded mb-3 mt-3"
                style={{ border: 'solid', width: 350, height: 200 }}
              >
                <div className="text-center">
                  <span>Expéditeur: </span>
                  <div>
                    <span style={{ fontWeight: 'bold' }}>
                      Mr/Mem . {paymentInvoice?.expeditor?.firstName}{' '}
                      {paymentInvoice?.expeditor?.lastName}
                    </span>
                  </div>
                  <div>Signature</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentInvoiceContent;
