import React, { ForwardedRef, ReactElement } from 'react';
import DataTable, { createTheme, TableProps } from 'react-data-table-component';
import { IThunkAction } from 'types/IThunkAction';
import { ExpandableRowsComponent } from 'react-data-table-component/dist/src/DataTable/types';
import { IPaginatedResponse } from 'types/PaginatedResponse';
import { useDataTable } from './useDataTable';
import { DataTableHandle, SearchQuery, SelectedRowsState } from './types';
import DefaultExpandedComponent from './DefaultExpandedComponent';
import { dataTableStyles } from './dataTableStyles';

type DataTableProps<T extends unknown> = Omit<TableProps<T>, 'data'> & {
  title?: string;
  fetchDataFn: (searchQuery: SearchQuery<T>) => IThunkAction;
  data: IPaginatedResponse<T>;
  loading?: boolean;
  onAddButtonClick?: () => void;
  onPressChangeStatus?: () => void;
  contextActions?: ReactElement;
  myRef?: ForwardedRef<DataTableHandle>;
  handleRowSelected?: (state: SelectedRowsState<T>) => void;
  toggleCleared?: boolean;
  ExpandedComponent?: ExpandableRowsComponent<T> | undefined;
  expandableRows?: boolean;
  subHeaderComponent?: React.ReactNode | React.ReactNode[];
};

const DataTableContainer = <T extends unknown>({
  title,
  fetchDataFn,
  columns,
  data,
  loading,
  onAddButtonClick,
  myRef,
  contextActions,
  handleRowSelected,
  toggleCleared,
  ExpandedComponent,
  expandableRows,
  subHeaderComponent,
  ...dataTableProps
}: DataTableProps<T>) => {
  const { handlePageChange, handleSort, handlePerRowsChange, headerComponent } = useDataTable({
    getData: fetchDataFn,
    onAddButtonClick,
    myRef,
    title,
  });

  createTheme(
    'solarized',
    {
      text: {
        primary: '#268bd2',
        secondary: '#2aa198',
      },
      background: {
        default: '#002b36',
      },
      context: {
        background: '#cb4b16',
        text: '#FFFFFF',
      },
      divider: {
        default: '#073642',
      },
      action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
      },
    },
    'light',
  );

  return (
    <div className="w-100 my-2 rounded shadow py-2 px-4">
      <DataTable
        title={headerComponent}
        columns={columns}
        data={data.items}
        progressPending={loading}
        pagination
        paginationServer
        paginationPerPage={Number(process.env.RAZZLE_ITEMS_PER_PAGE) || 20}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
        paginationTotalRows={data.totalItems}
        sortServer
        customStyles={dataTableStyles}
        subHeader
        theme="light"
        subHeaderComponent={subHeaderComponent}
        responsive
        onSort={handleSort}
        contextActions={contextActions}
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleCleared}
        highlightOnHover
        expandableRows={expandableRows}
        expandableRowsComponent={ExpandedComponent || DefaultExpandedComponent}
        {...dataTableProps}
      />
    </div>
  );
};

export default DataTableContainer;
