import { clientApi } from 'api';
import { SearchQuery } from 'client/containers/DataTableContainer/types';
import { IRunsheetDocument } from 'server/models/Runsheet/types';
import { DeliveringPackageTypeEnum } from 'types/Enums/DeliveringPackagesEnum';
import { PackageReturnCuzEnum } from 'types/Enums/PackageReturnCuzEnum';
import { PackageStateEnum } from 'types/Enums/PackageStateEnum';
import { runsheetFormType } from 'types/forms/runsheetFormType';

const baseUrl = '/runsheet';

export const getManagerRunsheet = (isServer = false) => async (
  searchQuery?: SearchQuery<IRunsheetDocument>,
) => {
  const response = await clientApi.get(baseUrl, { params: searchQuery });

  return response.data;
};
export const getDrivers = (isServer = false) => async () => {
  const response = await clientApi.get(`${baseUrl}/drivers-list`);

  return response.data;
};
export const createRunsheet = (isServer = false) => async (values: runsheetFormType) => {
  const response = await clientApi.post(baseUrl, {
    ...values,
    type: DeliveringPackageTypeEnum.DELIVERING,
  });

  return response.data;
};
export const createReturnRunsheet = (isServer = false) => async (values: runsheetFormType) => {
  const response = await clientApi.post(baseUrl, {
    ...values,
    type: DeliveringPackageTypeEnum.RETURN,
  });

  return response.data;
};
export const updateRunsheet = (isServer = false) => async (
  values: runsheetFormType,
  id?: string,
) => {
  const response = await clientApi.patch(`${baseUrl}/${id}`, values);

  return response.data;
};
export const affectDriverToManifest = (isServer = false) => async (
  driver: string,
  _id: string,
) => {
  await clientApi.patch(`${baseUrl}/affect-driver-to-manifest`, {
    driver,
    _id,
  });
};
export const deleteRunsheet = (isServer = false) => async (id?: string) => {
  const response = await clientApi.delete(`${baseUrl}/${id}`);

  return response.data;
};
export const deleteAdminRunsheet = (isServer = false) => async (id?: string) => {
  const response = await clientApi.patch(
    `dashboard/delete-runsheet`,
    {},
    {
      params: { _id: id },
    },
  );

  return response.data;
};
export const getRunsheetById = (isServer = false) => async (id: string) => {
  const response = await clientApi.get(`${baseUrl}/${id}`);

  return response.data;
};
export const getRunsheetPackagesId = (isServer = false) => async (_id: string) => {
  const response = await clientApi.get(`${baseUrl}/get-runsheet-packages`, {
    params: { _id },
  });

  return response.data;
};
export const scanBackToWarehouse = (isServer = false) => async (code: number, _id: string) => {
  const response = await clientApi.get(`${baseUrl}/scan-back-to-warehouse`, {
    params: { code, _id },
  });

  return response.data;
};
export const scanBackToExpeditor = (isServer = false) => async (code: number, _id: string) => {
  const response = await clientApi.get(`${baseUrl}/scan-back-to-expeditor`, {
    params: { code, _id },
  });

  return response.data;
};
export const scanExchangePackage = (isServer = false) => async (code: number, _id: string) => {
  const response = await clientApi.get(`${baseUrl}/scan-exchange-package`, {
    params: { code, _id },
  });

  return response.data;
};
export const validateRunsheet = (isServer = false) => async (_id: string) => {
  const response = await clientApi.get(`${baseUrl}/validate-runsheet`, {
    params: { _id },
  });

  return response.data;
};
export const getExchangePackage = (isServer = false) => async (_id: string) => {
  const response = await clientApi.get(`${baseUrl}/exchange-package-from-package`, {
    params: { _id },
  });

  return response.data;
};
export const changePackageState = async (
  _id: string,
  state: PackageStateEnum,
  runsheetId: string,
  returnCuz?: PackageReturnCuzEnum,
) => {
  const response = await clientApi.get(`${baseUrl}/update-package-state`, {
    params: { _id, state, runsheetId, returnCuz },
  });

  return response.data;
};
export const adminChangePackageState = async (_id: string, state: PackageStateEnum) => {
  const response = await clientApi.get(`/package/update-package-state`, {
    params: { _id, state },
  });

  return response.data;
};
