import Input from 'client/components/Form/Input';
import * as React from 'react';
import Validators from 'client/helpers/formValidator';
import { SubmitHandler, FormProvider } from 'react-hook-form';
import { ResetPasswordFormValues, usePasswordForm } from './usePasswordForm';

export interface UserFormProps {
  onSubmit: SubmitHandler<ResetPasswordFormValues>;
}

const PasswordForm = ({ onSubmit }: UserFormProps) => {
  const { formMethods, _onSubmit, handleSubmit } = usePasswordForm({
    onSubmit,
  });

  const { watch } = formMethods;

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(_onSubmit)}>
        <div className="row">
          <div className="col">
            <Input
              type="password"
              name="password"
              label="Mot de passe"
              placeholder="Mot de passe"
              validate={Validators([
                { validation: 'required' },
                { validation: 'minLength', minParams: 8 },
              ])}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Input
              type="password"
              name="passwordConfrim"
              label="confirmPassword"
              placeholder="confirmPassword"
              validate={Validators([
                { validation: 'required' },
                {
                  validation: 'passwordConfirm',
                  passwordConfirm: watch().password,
                },
              ])}
            />
          </div>
        </div>
        <div className="row justify-content-center">
          <button className="btn btn-primary mb-1" type="submit">
            Modifié
          </button>
        </div>
      </form>
    </FormProvider>
  );
};

export default PasswordForm;
