import { getExpeditorsList } from 'client/_redux/actions/user';
import React from 'react';
import DataTable from 'client/containers/DataTableContainer';
import { useExpeditorReturn } from './useExpeditorReturn';

const ExpeditorReturn = () => {
  const { columns, expeditorsList, dataTableRef } = useExpeditorReturn();

  return (
    <DataTable
      myRef={dataTableRef}
      data={expeditorsList}
      title="liste des epéditeur"
      columns={columns}
      fetchDataFn={getExpeditorsList}
    />
  );
};

export default ExpeditorReturn;
