import { login, signUp } from 'client/_redux/actions/auth';
import { SubmitHandler } from 'react-hook-form';
import { ILoginFormValues } from 'client/containers/LoginForm/useLoginForm';
import { IRegisterFormValues } from 'client/containers/RegisterForm/useRegisterForm';
import { useLoadingDispatch } from 'client/hooks/useLoadingDispatch';

export const useAuthModal = (setIsAuthModal: (isAuthModal: boolean) => void) => {
  const { enhancedDispatch, isLoading } = useLoadingDispatch();
  const onSubmitLogin: SubmitHandler<ILoginFormValues> = (values) => {
    enhancedDispatch(login(values, setIsAuthModal));
  };
  const onSubmitRegister: SubmitHandler<IRegisterFormValues> = (values) => {
    enhancedDispatch(signUp(values, setIsAuthModal));
  };
  const handleAuthModal = () => {
    setIsAuthModal(false);
  };

  return {
    onSubmitLogin,
    onSubmitRegister,
    handleAuthModal,
    isLoading,
  };
};
