import { cities, states } from './data';

export const useNewAddress = (selectedState: string) => {
  const stateOptions = states.map((state) => ({ label: state.name, value: state.name }));
  const cityOptions = cities
    .filter((city) => city.state_code === selectedState)
    .map((selectedCities) => ({ label: selectedCities.name, value: selectedCities.name }));

  return { stateOptions, cityOptions };
};
