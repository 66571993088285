export type navBarLinksProperties = {
  title: string;
  src: string;
  icon: string;
};
export const expeditorNavBarLinks: navBarLinksProperties[] = [
  {
    icon: 'fas fa-chart-bar',
    src: '/statistique',
    title: 'Statistique',
  },
  {
    title: 'Pickup',
    src: '/pickup',
    icon: 'fas fa-cube',
  },
  {
    title: 'Mes colis',
    src: '/mes-colis',
    icon: 'fas fa-cubes',
  },
  {
    title: 'Mon adresse',
    src: '/mon-adresse',
    icon: 'fas fa-map-marker-alt',
  },
];
export const managerNavBarLinks: navBarLinksProperties[] = [
  {
    icon: 'fas fa-chart-bar',
    src: '/statistique',
    title: 'Statistique',
  },
  {
    title: 'Pickup',
    src: '/pickup',
    icon: 'fas fa-cubes',
  },
  {
    title: 'Colis',
    src: '/colis',
    icon: 'fas fa-cube',
  },
  {
    title: 'Runsheet',
    src: '/runsheet',
    icon: 'fas fa-truck',
  },
  {
    title: 'Accepter magasin',
    src: '/accepter-magasin',
    icon: 'fas fa-warehouse',
  },
  {
    title: 'Retour expediteur',
    src: '/retour-expediteur',
    icon: 'fas fa-undo-alt',
  },
];

export const adminNavBarLinks: navBarLinksProperties[] = [
  {
    icon: 'fas fa-chart-bar',
    src: '/statistique',
    title: 'Statistique',
  },

  {
    title: 'Utilisateurs',
    src: '/utilisateurs',
    icon: 'fas fa-users',
  },
  {
    title: 'Colis',
    src: '/colis',
    icon: 'fas fa-cube',
  },
  {
    title: 'Runsheet',
    src: '/runsheet',
    icon: 'fas fa-truck',
  },
  {
    title: 'Payment',
    src: '/payment',
    icon: 'fas fa-money-check',
  },
];
