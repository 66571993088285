/* eslint-disable max-lines */
import { IThunkAction } from 'types/IThunkAction';
import { toast } from 'react-toastify';
import { errorHandler } from 'client/helpers/errorHandler';
import * as UserService from 'client/_redux/services/user';
import { SearchQuery } from 'client/containers/DataTableContainer/types';
import { RootState } from 'client/_redux/reducers';
import { NavigateFunction } from 'react-router';
import { IUser } from 'server/models/User/types';
import { AdminUserTypes } from 'client/_redux/types/AdminUserTypes';
import { startLoading, stopLoading } from '../loading';

export type ResetPasswordFormValues = {
  password: string;
  newPassword: string;
  confirmPassword: string;
};

export const addUser = (values: IUser, navigate: NavigateFunction): IThunkAction => async (
  dispatch,
) => {
  dispatch(startLoading());
  try {
    const { data } = await UserService.addUser()(values);

    dispatch({ type: AdminUserTypes.ADD_USER_SUCCESS, payload: data });
    toast('ajouter avec success', { type: 'success' });
    navigate('/utilisateurs');
  } catch (error) {
    dispatch({ type: AdminUserTypes.ADD_USER_FAILURE });
    toast('E-mail already Exist', { type: 'error' });

    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const getAllUsers = (searchQuery: SearchQuery<IUser>): IThunkAction => async (
  dispatch,
) => {
  dispatch(startLoading());
  try {
    const data = await UserService.getAllUsers()(searchQuery);

    dispatch({ type: AdminUserTypes.GET_USERS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: AdminUserTypes.GET_USERS_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const getExpeditorsList = (searchQuery?: SearchQuery<IUser>): IThunkAction => async (
  dispatch,
) => {
  dispatch(startLoading());
  try {
    const data = await UserService.getAllExpeditors()(searchQuery);

    dispatch({ type: AdminUserTypes.GET_USERS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: AdminUserTypes.GET_USERS_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const updateUser = (
  values: IUser,
  userId: string,
  navigate: NavigateFunction,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const { data } = await UserService.updateUser()(values, userId);

    dispatch({ type: AdminUserTypes.UPDATE_USER_SUCCES, payload: data });
    toast('modifier avec success', { type: 'success' });
    navigate('/utilisateurs');
  } catch (error) {
    errorHandler(error);
    dispatch({ type: AdminUserTypes.UPDATE_USER_FAILURE });
  }
  dispatch(stopLoading());
};
export const updateUserPassword = (
  values: ResetPasswordFormValues,
  userId: string,
  navigate: NavigateFunction,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const { data } = await UserService.updateUserPassword()(values, userId);

    dispatch({ type: AdminUserTypes.UPDATE_USER_SUCCES, payload: data });
    toast('modifier avec success', { type: 'success' });
    navigate('/utilisateurs');
  } catch (error) {
    errorHandler(error);
    dispatch({ type: AdminUserTypes.UPDATE_USER_FAILURE });
  }
  dispatch(stopLoading());
};
export const deleteUserById = (userId: string): IThunkAction => async (dispatch, state) => {
  dispatch(startLoading());
  try {
    const { data } = await UserService.deleteUserById()(userId);
    const { user } = state() as RootState;

    const items = user.userList.items.filter((el) => el._id !== data._id);

    dispatch({
      type: AdminUserTypes.DELETE_USER_SUCCESS,
      payload: { ...user.userList, items },
    });
    toast('supprimer avec success', { type: 'success' });
  } catch (error) {
    dispatch({ type: AdminUserTypes.DELETE_USER_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};
