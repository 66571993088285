import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'client/_redux/reducers';
import { useTableColumnsWithActions } from 'client/containers/DataTableContainer/useTableColumnsWithActions';
import { useRef, useState } from 'react';
import { DataTableHandle } from 'client/containers/DataTableContainer/types';
import { IUser } from 'server/models/User/types';
import { managerCreatePickup } from 'client/_redux/actions/packages';
import { PickupTableColumns } from './PickupTableColumns';

export const usePickup = () => {
  const dispatch = useDispatch();
  const expeditorsList = useSelector(
    ({ user: { expeditorsList } }: RootState) => expeditorsList,
  );

  const dataTableRef = useRef<DataTableHandle>(null);

  const [togglePickupModal, setTogglePickupModal] = useState(false);
  const [selectedExpeditor, setSelectedExpeditor] = useState<IUser | undefined>(undefined);
  const columns = useTableColumnsWithActions({
    columns: PickupTableColumns(),
    buttons: [
      {
        className: () => `fa fa-plus`,
        // eslint-disable-next-line no-console
        fn: (item) => {
          setSelectedExpeditor(item);
          setTogglePickupModal(true);
        },
        color: 'black',
      },
    ],
  });
  const [driver, setDriver] = useState('');

  const onSubmit = () => {
    if (selectedExpeditor && driver) {
      const { packages } = selectedExpeditor;
      const packagesIds = packages.map((pack) => pack._id);
      const pickupValues = {
        driver,
        packagesIds,
      };

      dispatch(managerCreatePickup(pickupValues));
    }
    setTogglePickupModal(false);
  };

  return {
    expeditorsList,
    columns,
    dataTableRef,
    onSubmit,
    togglePickupModal,
    setTogglePickupModal,
    setDriver,
  };
};
