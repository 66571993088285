import { groupBy } from 'lodash';
import { TableColumn } from 'react-data-table-component';
import { IRunsheetDocument } from 'server/models/Runsheet/types';

export const pickupColumns = (): TableColumn<IRunsheetDocument>[] => [
  {
    name: 'Nom du livreur',
    selector: (e) => `${e?.driver?.firstName}`,
    center: true,
    sortable: true,
    sortField: 'firstName',
    style: {
      fontWeight: 'bold',
    },
  },
  {
    name: 'Telephone du livreur',
    selector: (e) => `${e?.driver?.phoneNumber}`,
    center: true,
    sortable: true,
    sortField: 'phoneNumber',
    style: {
      fontWeight: 'bold',
    },
  },
  {
    name: 'matricule de livruer',
    selector: (e) => `${e?.driver?.carId}`,
    center: true,
    sortable: true,
    sortField: 'carSerialNumber',
    style: {
      fontWeight: 'bold',
    },
  },
  {
    name: 'Nombre des expéditeur',
    selector: (e) => `${Object.keys(groupBy(e.packagesList, 'expeditor.phoneNumber')).length}`,
    center: true,
    sortable: true,
    sortField: 'packageList',
    style: {
      fontWeight: 'bold',
    },
  },
  {
    name: 'Nombre de colis',
    selector: (e) => `${e.packagesList.length}`,
    center: true,
    sortable: true,
    sortField: 'packageList',
    style: {
      fontWeight: 'bold',
    },
  },
];
