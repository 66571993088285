/* eslint-disable max-lines */
import AuthModal from 'client/containers/AuthModal';
import { RootState } from 'client/_redux/reducers';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const HomePage: React.FC = () => {
  const [authModalOpen, setAuthModalOpen] = useState(false);

  const inViewport: IntersectionObserverCallback = (entries, observer) => {
    entries.forEach((entry) => {
      entry.target.classList.toggle('is-inViewport', entry.isIntersecting);
    });
  };
  const isLoggedIn = useSelector(({ auth }: RootState) => auth.isLoggedIn);

  useEffect(() => {
    const Obs = new IntersectionObserver(inViewport);

    const ELs_inViewport = document.querySelectorAll('[data-inviewport]');

    ELs_inViewport.forEach((EL) => {
      Obs.observe(EL);
    });
  }, []);
  const [loginModal, setLoginModal] = useState(true);

  return (
    <>
      <AuthModal
        loginModal={loginModal}
        setLoginModal={setLoginModal}
        isAuthModal={authModalOpen}
        setIsAuthModal={setAuthModalOpen}
      />

      <div className="main">
        <section id="home" className="section welcome-area bg-inherit h-100vh overflow-hidden">
          <div className="shapes-container">
            <div className="bg-shape" />
          </div>
          {!isLoggedIn && (
            <div className="row position-absolute top-0 right-0 p-4 z-index-50">
              <button
                onClick={() => {
                  setAuthModalOpen(true);
                  setLoginModal(true);
                }}
                type="button"
                className="add-fast-package add-fast-package-button mr-2"
              >
                Connexion
              </button>
              <button
                type="button"
                onClick={() => {
                  setAuthModalOpen(true);
                  setLoginModal(false);
                }}
                className="add-fast-package add-fast-package-button transition-3d-hover shadow-soft"
              >
                Devenir expéditeur
              </button>
            </div>
          )}
          <div className="container h-100">
            <div className="row align-items-center h-100">
              <div className="col-12 col-md-7">
                <img
                  style={{ width: '20rem', marginBottom: 60 }}
                  className="navbar-brand-regular transition-3d-hover"
                  src="/assets/images/nextPackLogoBlue.png"
                  alt="brand-logo"
                />
                <div className="welcome-intro">
                  <h1>Le meilleure service de la livraison express en grand tunis</h1>
                  <p className="my-4">
                    Nous offrons une logistique nationale avec une qualité de distribution
                    optimale, et ce grâce à nos bureaux locaux et nos équipes dédiés de haut
                    niveau.
                  </p>
                </div>
              </div>
              <div data-inviewport="fade-left" className="col-12 col-md-5">
                <div
                  className="welcome-thumb"
                  data-aos="fade-right"
                  data-aos-delay="500"
                  data-aos-duration="1000"
                >
                  <img src="/assets/images/img4.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="features" className="section features-area pb-10">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-8">
                <div className="section-heading text-center">
                  <h2>Courrier classique Des livraisons de courrier classique (B2B),</h2>
                  <p className="d-none d-sm-block mt-4">
                    B2B sont de différents secteurs d’activité : pharmaceutique et
                    parapharmaceutique (médicaments et instruments de chirurgie générale et
                    spécialisée), optique (verrerie médicale et lentilles), industries
                    (transport d’échantillons), cahiers de charges, dépôts de soumissions et
                    documents divers, etc.
                  </p>
                  <p className="d-block d-sm-none mt-4">
                    B2B sont de différents secteurs d’activité : pharmaceutique et
                    parapharmaceutique (médicaments et instruments de chirurgie générale et
                    spécialisée), optique (verrerie médicale et lentilles), industries
                    (transport d’échantillons), cahiers de charges, dépôts de soumissions et
                    documents divers, etc.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div data-inviewport="scale-in" className="col-12 col-md-6 col-lg-4">
                <div className="icon-box text-center p-4 wow fadeInUp" data-wow-duration="2s">
                  <div className="featured-icon mb-3">
                    <span className="fas fa-users" />
                  </div>
                  <div className="icon-text">
                    <h3 className="mb-2">Rapide</h3>
                    <p>
                      Nextpack est le service de livraison le plus rapide en tunisie. vos
                      envois sont livrés en un temps record!
                    </p>
                  </div>
                </div>
              </div>
              <div data-inviewport="scale-in" className="col-12 col-md-6 col-lg-4">
                <div
                  className="icon-box text-center p-4 wow fadeInUp"
                  data-wow-duration="2s"
                  data-wow-delay="0.2s"
                >
                  <div className="featured-icon mb-3">
                    <span className="fas fa-users" />
                  </div>
                  <div className="icon-text">
                    <h3 className="mb-2">Sécurisée</h3>
                    <p>
                      Avec Nextpack, vos envois sont sécurisés dès le ramassage, pendant leur
                      acheminement et jusqu’à leur livraison.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <div
                  className="icon-box text-center p-4 wow fadeInUp"
                  data-inviewport="scale-in"
                >
                  <div className="featured-icon mb-3">
                    <span className="fas fa-users" />
                  </div>
                  <div className="icon-text">
                    <h3 className="mb-2">Temps réel</h3>
                    <p>
                      Vous pouvez suivre, vous et votre client, toutes les étapes de la
                      livraison de votre colis en temps réel et à tout moment, sur la
                      plateforme.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <div
                  className="icon-box text-center p-4 wow fadeInUp"
                  data-inviewport="scale-in"
                >
                  <div className="featured-icon mb-3">
                    <span className="fas fa-users" />
                  </div>
                  <div className="icon-text">
                    <h3 className="mb-2">Paiement à la livraison</h3>
                    <p>
                      On propose des services de remboursement à tous les clients souhaitant
                      vendre leurs produits
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <div
                  className="icon-box text-center p-4 wow fadeInUp"
                  data-inviewport="scale-in"
                >
                  <div className="featured-icon mb-3">
                    <span className="fas fa-users" />
                  </div>
                  <div className="icon-text">
                    <h3 className="mb-2">Livraison Rapide</h3>
                    <p>Livraison dans toutes les grandes villes du grand tunis le meme jour</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <div
                  className="icon-box text-center p-4 wow fadeInUp"
                  data-inviewport="scale-in"
                >
                  <div className="featured-icon mb-3">
                    <span className="fas fa-users" />
                  </div>
                  <div className="icon-text">
                    <h3 className="mb-2">Transparence</h3>
                    <p>Un centre d'appel est mis à votre disposition.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section discover-area bg-gray overflow-hidden ptb_100">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-12 col-lg-6 order-2 order-lg-1">
                <div className="service-thumb discover-thumb mx-auto text-center pt-5 pt-lg-0">
                  <img data-inviewport="fade-left" src="/assets/images/img1.png" alt="" />
                </div>
              </div>
              <div className="col-12 col-lg-6 order-1 order-lg-2">
                <div className="discover-text px-0 px-lg-4 pt-4 pt-lg-0">
                  <h2 className="pb-4">Nextpack</h2>
                  <ul className="check-list">
                    <li className="py-1">
                      <div className="list-box media">
                        <span className="icon align-self-center">
                          <i className="fas fa-check" />
                        </span>
                        <span className="media-body pl-3">
                          Opérateur de courrier Express en régime domestique. Notre mission
                          principale est de nous assurer de transporter vos courriers et vos
                          colis.
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section download-area overlay-dark ptb_100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-9">
                <div className="download-text text-center">
                  <h2 className="text-white">
                    Nextpack le 1er service de livraison en grand tunis
                  </h2>
                  <p className="text-white my-3 d-none d-sm-block">
                    Nextpack Service est une société de transport express engagée dans la
                    satisfaction de ses clients. Sa première préoccupation est de proposer un
                    service répondant aux besoins de ses partenaires en termes de délais et de
                    qualité. Le savoir-faire , le professionnalisme et l’expérience de nos
                    collaborateurs constituent une des clés majeures de notre réussite. Nos
                    différents services sont et demeurent à votre disposition pour accélérer la
                    livraison à domicile de vos colis en provenance
                  </p>
                  <p className="text-white my-3 d-block d-sm-none">
                    sApp is available for all devices, consectetur adipisicing elit. Vel neque,
                    cumque. Temporibus eligendi veniam, necessitatibus aut id labore nisi
                    quisquam.
                  </p>
                  <div className="button-group store-buttons d-flex justify-content-center">
                    <a href="#">
                      <img src="assets/img/icon/google-play.png" alt="" />
                    </a>
                    <a href="#">
                      <img src="assets/img/icon/app-store.png" alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="contact" className="contact-area bg-gray ptb_100">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-12 col-md-5">
                <div className="contact-us">
                  <ul>
                    <li className="py-2">
                      <a className="media" href="#">
                        <div className="social-icon mr-3">
                          <i className="fas fa-home" />
                        </div>
                        <span className="media-body align-self-center">
                          Ariana Route de bizerte
                        </span>
                      </a>
                    </li>
                    <li className="py-2">
                      <a className="media" href="#">
                        <div className="social-icon mr-3">
                          <i className="fas fa-phone-alt" />
                        </div>
                        <span className="media-body align-self-center">
                          +216 27 772 808 / +216 29 772 480
                        </span>
                      </a>
                    </li>
                    <li className="py-2">
                      <a className="media" href="#">
                        <div className="social-icon mr-3">
                          <i className="fas fa-envelope" />
                        </div>
                        <span className="media-body align-self-center">
                          support@nextpack.tn
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-6 pt-4 pt-md-0">
                <div className="contact-box text-center">
                  <form>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            placeholder="Name"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            placeholder="Email"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            name="subject"
                            placeholder="Subject"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            name="message"
                            placeholder="Message"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <button
                          style={{ color: 'white' }}
                          type="submit"
                          className="btn btn-lg btn-block mt-3"
                        >
                          <span className="text-white pr-3">
                            <i className="fas fa-paper-plane" />
                          </span>
                          Envoyer un message
                        </button>
                      </div>
                    </div>
                  </form>
                  <p className="form-message" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="height-emulator d-none d-lg-block" />

        <footer className="footer-area footer-fixed">
          <div className="footer-bottom">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                    <div className="copyright-left">
                      &copy; Copyrights 2022 All rights reserved.
                    </div>
                    <div className="copyright-right">
                      Made with <i className="fas fa-heart" />
                      By
                      <a href="#"> US</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default HomePage;
