/* eslint-disable no-nested-ternary */
import React, { ReactNode, useEffect, useState } from 'react';

export interface IModalProps {
  isOpen?: boolean;
  modalHead?: string;
  modalContent?: string;
  footer?: ReactNode;
  closeModal: () => void;
  childrenClassName?: string;
}

const Modal: React.FC<IModalProps> = ({
  isOpen = false,
  closeModal,
  modalHead,
  footer,
  children,
  childrenClassName,
}) => {
  const [isDelayedChange, setIsDelayedChange] = useState<boolean>(false);
  const [isOpening, setIsOpening] = useState<boolean>(true);

  useEffect(() => {
    if (isOpen === true) setIsOpening(true);
    else setIsOpening(false);
    setTimeout(() => {
      setIsDelayedChange(isOpen);
    }, 150);
  }, [isOpen]);

  return (
    <div className={isOpening ? 'modal-open' : ''}>
      <div
        style={{ display: isOpening ? 'block' : isDelayedChange ? 'block' : 'none' }}
        className={
          !isOpening ? 'modal fade' : isDelayedChange ? 'modal fade show' : 'modal fade'
        }
      >
        <div
          role="button"
          onClick={closeModal}
          onKeyDown={closeModal}
          tabIndex={0}
          className="modal-bg"
        >
          {' '}
        </div>
        <div className="modal-container">
          <div className="modal-header p-0 pborder-bottom">
            <h5>{modalHead}</h5>
            <button type="button" className="close" aria-label="Close" onClick={closeModal}>
              <span className="font-size-4 font-weight-light " aria-hidden="true">
                ×
              </span>
            </button>
          </div>

          <div className={childrenClassName || ''}>{children}</div>
          <div className="">{footer}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
