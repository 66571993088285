import { useTableColumnsWithActions } from 'client/containers/DataTableContainer/useTableColumnsWithActions';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { IUserPackageDocument } from 'server/models/Package/types';
import { RunsheetValidation } from 'types/RunsheetValidationType';
import { getRunsheetPackagesId, validateRunsheet } from 'client/_redux/actions/runsheet';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { RunsheetPackagesColumns } from './RunsheetPackagesColumns';
export const useConfirmRunsheet = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [packagesList, setPackagesList] = useState<RunsheetValidation>({
    ce: 0,
    payedByCheck: 0,
    payedCash: 0,
    rd: 0,
    re: 0,
    runsheet: undefined,
    total: 0,
    deliveredPackages: [],
    failedPackages: [],
  });

  const [toggleExchangePackageModal, setToggleExchangePackageModal] = useState(false);
  const [toggleDriverInvoice, setToggleDriverInvoice] = useState(false);
  const [showCuzSelect, setShowCuzSelect] = useState('');
  const [exchangePackagesScanned, setExchangePackagesScanned] = useState<string[]>([]);

  useEffect(() => {
    if (id) {
      getRunsheetPackagesId(id).then((packagesList) => {
        setPackagesList(packagesList);
      });
    }
  }, [id]);

  const formMethods = useForm();

  const [exchangePackage, setExchangePackage] = useState<IUserPackageDocument>();
  const deliveredColumns = useTableColumnsWithActions({
    columns: RunsheetPackagesColumns(
      id,
      setPackagesList,
      undefined,
      setExchangePackage,
      setToggleExchangePackageModal,
      exchangePackagesScanned,
      showCuzSelect,
      setShowCuzSelect,
    ),
    buttons: [],
  });

  const failedColumns = useTableColumnsWithActions({
    columns: RunsheetPackagesColumns(
      id,
      setPackagesList,
      true,
      undefined,
      undefined,
      undefined,
      showCuzSelect,
      setShowCuzSelect,
    ),
    buttons: [],
  });

  const { failedPackages, deliveredPackages, runsheet, total } = packagesList;

  const onSubmit = () => {
    if (id) dispatch(validateRunsheet(id));
    setToggleDriverInvoice(true);
  };

  const invalidButton = !(
    deliveredPackages.filter((pack) => pack.exchangePackage).length ===
      exchangePackagesScanned.length && failedPackages.length === 0
  );

  return {
    runsheet,
    formMethods,
    id,
    setExchangePackagesScanned,
    setPackagesList,
    failedPackages,
    failedColumns,
    total,
    deliveredPackages,
    deliveredColumns,
    invalidButton,
    onSubmit,
    toggleExchangePackageModal,
    setToggleExchangePackageModal,
    exchangePackage,
    setToggleDriverInvoice,
    packagesList,
    toggleDriverInvoice,
  };
};
