import { checkLoggingStatus } from 'client/_redux/actions/auth';
import { RootState } from 'client/_redux/reducers';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes } from 'react-router-dom';
import { UserRolesEnum } from 'types/Enums/UserRolesEnum';
import { renderClassicRotes } from './Routes/DefaultRoutes';
import { renderExpeditorRoutes } from './Routes/ExpeditorRoutes';
import { renderManagerRoutes } from './Routes/ManagerRoutes';
import { renderAdminRoutes } from './Routes/AdminRoutes';

export const useAppRouter = () => {
  const initiated = useSelector(({ loading: { initiated } }: RootState) => initiated);
  const user = useSelector(({ user: { user } }: RootState) => user);
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(({ auth }: RootState) => auth.isLoggedIn);

  const renderRoutes = () => {
    switch (user?.role) {
      case UserRolesEnum.ROLE_EXPEDITOR:
        return <Routes>{renderExpeditorRoutes(isLoggedIn, user?.role)}</Routes>;
      case UserRolesEnum.ROLE_MANAGER:
        return <Routes>{renderManagerRoutes(isLoggedIn, user?.role)}</Routes>;
      case UserRolesEnum.ROLE_ADMIN:
        return <Routes>{renderAdminRoutes(isLoggedIn, user?.role)}</Routes>;

      default:
        return <Routes>{renderClassicRotes()}</Routes>;
    }
  };

  useEffect(() => {
    dispatch(checkLoggingStatus());
  }, [dispatch]);

  return {
    initiated,
    role: user?.role,
    renderRoutes,
    isLoggedIn,
  };
};
