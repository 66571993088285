import * as React from 'react';
import Loader from 'client/components/Loader';
import { useAppRouter } from './useAppRouter';
import NavBar from '../NavBar';
import NotificationContainer from './NotificationContainer';
const Root = () => {
  const { initiated, role, isLoggedIn, renderRoutes } = useAppRouter();

  return initiated ? (
    <div>
      {isLoggedIn && <NavBar className="mb-5" userRole={role} />}
      {isLoggedIn && <NotificationContainer />}

      {renderRoutes()}
    </div>
  ) : (
    <Loader />
  );
};

export default Root;
