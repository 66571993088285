import { Link } from 'react-router-dom';
import * as React from 'react';
import { ValidationRules, useFormContext } from 'react-hook-form';
import { get } from 'lodash';

type InputOrAreaProps = React.HTMLProps<HTMLInputElement | HTMLTextAreaElement>;

export interface IInputProps extends InputOrAreaProps {
  toggleShowPassword?: () => void;
  type?: string;
  isPasswordShown?: boolean;
  id?: string;
  label?: string;
  forgotPasswordMessage?: string;
  forgotPasswordLink?: string;
  rightIcon?: JSX.Element;
  leftIcon?: JSX.Element;
  name: string;
  validate?: ValidationRules;
  className?: string;
}

const Input: React.FC<IInputProps> = ({
  id,
  name,
  label,
  type,
  forgotPasswordLink,
  rightIcon,
  leftIcon,
  forgotPasswordMessage,
  validate,
  className,
  ...props
}) => {
  const isPassword = type === 'password';
  const [isPasswordShown, toggleShowPassword] = React.useState(false);
  const handletoggleShowPassword = (): void => {
    toggleShowPassword(!isPasswordShown);
  };
  const passwordFieldType = isPasswordShown ? 'text' : 'password';

  const formContext = useFormContext();

  const fieldError = get(formContext?.errors, name);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    event.preventDefault();
  };

  return (
    <div className={`form-group ${fieldError ? 'u-has-error' : ''} ${className || ''}`}>
      <label className="form-label w-100" htmlFor={id}>
        <span className="d-flex flex-wrap justify-content-between align-items-center">
          {label}
          {isPassword && forgotPasswordMessage && (
            <Link className="link-muted  font-weight-normal" to={forgotPasswordLink || ''}>
              {forgotPasswordMessage}
            </Link>
          )}
        </span>
      </label>
      <div className="input-group">
        {leftIcon && (
          <div className="input-group-prepend">
            <span
              className={`input-group-text 
            ${fieldError ? 'border-danger' : ''}
            `}
              id="basic-addon2"
            >
              {leftIcon}
            </span>
          </div>
        )}
        {type === 'textarea' ? (
          <textarea
            {...props}
            ref={validate ? formContext?.register(validate) : formContext?.register}
            name={name}
            className={`form-control ${fieldError ? 'is-invalid' : ''}`}
          />
        ) : (
          <input
            id={id}
            name={name}
            {...props}
            ref={validate ? formContext?.register(validate) : formContext?.register}
            type={!isPassword ? type : passwordFieldType}
            className={`form-control ${fieldError ? 'is-invalid' : ''}`}
            style={{
              borderColor: fieldError ? 'red' : 'transparent',
              borderWidth: 1,
              borderStyle: 'solid',
            }}
          />
        )}
        {rightIcon && (
          <div className="input-group-append">
            <span
              className={`input-group-text 
            ${fieldError ? 'border-danger' : ''}
            `}
              id="basic-addon2"
            >
              {rightIcon}
            </span>
          </div>
        )}
        {isPassword && (
          <div className="input-group-append">
            <button
              type="button"
              className="input-group-text"
              aria-label="toggle password visibility"
              onClick={handletoggleShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {!isPasswordShown ? (
                <i className="far fa-eye" />
              ) : (
                <i className="far fa-eye-slash" />
              )}
            </button>
          </div>
        )}
      </div>
      {fieldError && (
        <div className="invalid-feedback text-left" style={{ display: 'block' }}>
          {fieldError.message}
        </div>
      )}
    </div>
  );
};

export default Input;
