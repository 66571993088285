import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { RootState } from 'client/_redux/reducers';
import { createReturnRunsheet } from 'client/_redux/actions/runsheet';
import { runsheetFormType } from 'types/forms/runsheetFormType';
import { scannedPackagesTableColumns } from 'client/pages/Manager/AcceptPackages/scannedPackagesTableColumns';
import { useParams } from 'react-router-dom';
import { IUser } from 'server/models/User/types';
import { getUserById } from 'client/_redux/services/user';
import { PackagesExpTypes } from 'client/_redux/types/packagesExpTypes';
import { useTableColumnsWithActions } from '../DataTableContainer/useTableColumnsWithActions';

export const useManifestForm = () => {
  const dispatch = useDispatch();

  const [expeditor, setExpeditor] = useState<IUser>();
  const { id } = useParams();

  useEffect(() => {
    dispatch({ type: PackagesExpTypes.CLEAR_MANIFEST_PACKAGES_LIST });
  }, [dispatch]);
  useEffect(() => {
    if (id) getUserById()(id).then((data) => setExpeditor(data));
  }, [id]);

  const { scannedManifestPackages, scannedReturnedPackages, loading } = useSelector(
    ({
      packages: { scannedManifestPackages, scannedReturnedPackages },
      loading: { loading },
    }: RootState) => ({
      scannedManifestPackages,
      scannedReturnedPackages,
      loading,
    }),
  );

  const formMethods = useForm<runsheetFormType>();
  const { handleSubmit } = formMethods;

  const manifest = useSelector(({ runsheet: { manifest } }: RootState) => manifest);

  const [toggleManifestModal, setToggleManifestModal] = useState(false);

  const packagesList = scannedManifestPackages?.map((pack) => pack._id);
  const returnedPackagesList = scannedReturnedPackages?.map((pack) => pack._id);
  const onSubmit = () => {
    if (id) {
      dispatch(createReturnRunsheet({ expeditor: id, packagesList, returnedPackagesList }));
      setToggleManifestModal(true);
    }
  };

  const columns = useTableColumnsWithActions({
    columns: scannedPackagesTableColumns(),
    buttons: [],
  });

  return {
    handleSubmit,
    onSubmit,
    formMethods,
    scannedManifestPackages: [...scannedManifestPackages, ...scannedReturnedPackages],
    columns,
    toggleManifestModal,
    setToggleManifestModal,
    manifest,
    packagesList: [...packagesList, ...returnedPackagesList],
    id,
    expeditor,
    loading,
  };
};
