import * as React from 'react';
import SEO from 'client/components/SEO';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';
import * as animationData from './bot-error-404.json';

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <>
      <SEO title="Not Found page" description="Next pack page not found" />
      <div className="d-flex justify-content-center align-content-center">
        <Lottie options={defaultOptions} height={600} width={600} />
      </div>
      <div className="d-flex justify-content-center align-content-center">
        <button onClick={() => navigate('/')} className="btn btn-primary" type="button">
          Page d'accuil
        </button>
      </div>
    </>
  );
};

export default NotFound;
