import AnimatedCard from 'client/components/AnimatedCard';
import FormSelect from 'client/components/Form/FormSelect';
import { packagesStatesOptions } from 'client/constants/SelectOptions';
import { expeditorStats, managerStats } from 'client/_redux/actions/packages';
import { RootState } from 'client/_redux/reducers';
import { packagesStatsByDate } from 'client/_redux/services/packages';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { UserRolesEnum } from 'types/Enums/UserRolesEnum';
import { useTableColumnsWithActions } from 'client/containers/DataTableContainer/useTableColumnsWithActions';
import DataTable, { TableColumn } from 'react-data-table-component';
import { statsByDayObject } from 'types/PackageExternalTypes';
import { PackageStateEnum } from 'types/Enums/PackageStateEnum';
import FormDatePicker from 'client/components/Form/FormDatePicker';
type PackagesStatisticPageProps = {
  role?: UserRolesEnum;
};
const PackagesStatisticPage: React.FC<PackagesStatisticPageProps> = ({ role }) => {
  const dispatch = useDispatch();

  const { expeditorStats: expeditorStatsPayload, expeditorStatsByDay } = useSelector(
    ({ packages }: RootState) => packages,
  );
  const formMethods = useForm();
  const { handleSubmit } = formMethods;

  const _onSubmit = (values: { from: string; to: string; state: PackageStateEnum }) => {
    dispatch(packagesStatsByDate(values));
  };

  useEffect(() => {
    switch (role) {
      case UserRolesEnum.ROLE_EXPEDITOR:
        dispatch(expeditorStats());
        break;
      case UserRolesEnum.ROLE_MANAGER:
      case UserRolesEnum.ROLE_ADMIN:
        dispatch(managerStats());
        dispatch(packagesStatsByDate({}));
        break;

      default:
        break;
    }
  }, [dispatch, role]);

  const ExpeditorPackageTableColumns = (): TableColumn<statsByDayObject>[] => [
    {
      name: 'Etat',
      selector: (e) => `${e.state}`,
      center: true,
    },
    {
      name: 'Nombre des colis',
      selector: (e) => `${e.number}`,
      center: true,
    },
    {
      name: 'Montant Total',
      selector: (e) => `${e.price} DT`,
      center: true,
    },
  ];
  const columns = useTableColumnsWithActions({
    columns: ExpeditorPackageTableColumns(),
    buttons: [],
  });

  return (
    <>
      {[UserRolesEnum.ROLE_ADMIN, UserRolesEnum.ROLE_MANAGER].includes(role) && (
        <>
          <div className="d-flex justify-content-center">
            <div className="page-header w-90">
              <div className="page-header-text my-2 pl-4">Filtre</div>
            </div>
          </div>
          <FormProvider {...formMethods}>
            <form className="pt-5" onSubmit={handleSubmit(_onSubmit)}>
              <div className="d-flex justify-content-center align-items-center flex-wrap">
                <FormDatePicker withFullScreenPortal={false} label="de:" name="from" />
                <div className="mx-3">
                  <FormDatePicker withFullScreenPortal={false} label="a:" name="to" />
                </div>
                <FormSelect
                  name="state"
                  placeholder="recherche par état"
                  options={packagesStatesOptions}
                  className="mb-0"
                />
              </div>
              <div className="d-flex justify-content-center align-items-center flex-wrap">
                <button type="submit" className="btn btn-primary">
                  Recherche
                </button>
              </div>
            </form>
          </FormProvider>
          <div className="d-flex justify-content-center align-items-center flex-wrap pt-5">
            <div className="col-8">
              <div className="d-flex justify-content-center flex-wrap">
                <DataTable data={expeditorStatsByDay} columns={columns} />
              </div>
            </div>
          </div>
        </>
      )}
      <div className="d-flex justify-content-center">
        <div className="page-header w-90">
          <div className="page-header-text my-2 pl-4">Statistique</div>
        </div>
      </div>
      <div className="d-flex justify-content-center flex-wrap">
        {expeditorStatsPayload?.map((stat) => (
          <AnimatedCard
            key={stat.title}
            image={stat.image}
            title={stat.title}
            stats={{
              friday: stat.friday,
              monday: stat.monday,
              sunday: stat.sunday,
              saturday: stat.saturday,
              thursday: stat.thursday,
              tuesday: stat.tuesday,
              wednesday: stat.wednesday,
              total: stat.total,
            }}
          />
        ))}
      </div>
    </>
  );
};

export default PackagesStatisticPage;
