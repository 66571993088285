const CLIENT_API =
  process.env.NODE_ENV === 'production'
    ? `${process.env.RAZZLE_HOST}`
    : `http://localhost:3000`;

export const getImageUrl = (path?: string) => {
  if (path && path.startsWith('assets')) return `${CLIENT_API}/${path}`;
  if (path && (path.startsWith('http') || !path.startsWith('uploads'))) return path;
  if (!path) return `${CLIENT_API}/`;

  return `${CLIENT_API}/${path}`;
};
