export enum PackagesExpTypes {
  ADD_TO_PACKAGES_LIST = 'ADD_TO_PACKAGES_LIST',
  FETCH_PACKAGES_SUCCESS = 'FETCH_PACKAGES_SUCCESS',
  FETCH_EXPEDITOR_STATS_BY_DAY_SUCCESS = 'FETCH_EXPEDITOR_STATS_BY_DAY_SUCCESS',
  FETCH_EXPEDITOR_STATS_SUCCESS = 'FETCH_EXPEDITOR_STATS_SUCCESS',
  FETCH_EXPEDITOR_STATS_FAILURE = 'FETCH_EXPEDITOR_STATS_FAILURE',
  FETCH_EXPEDITOR_CLIENTS = 'FETCH_EXPEDITOR_CLIENTS',
  FETCH_EXPEDITOR_CLIENTS_FAILURE = 'FETCH_EXPEDITOR_CLIENTS_FAILURE',
  CLEAR_SCANNED_PACKAGES_LIST = 'CLEAR_SCANNED_PACKAGES_LIST',
  ADD_TO_RUNSHEET_PACKAGES_LIST = 'ADD_TO_RUNSHEET_PACKAGES_LIST',
  CLEAR_ACCEPTED_PACKAGES_LIST = 'CLEAR_ACCEPTED_PACKAGES_LIST',
  ADD_TO_MANIFEST_PACKAGES_LIST = 'ADD_TO_MANIFEST_PACKAGES_LIST',
  CLEAR_MANIFEST_PACKAGES_LIST = 'CLEAR_MANIFEST_PACKAGES_LIST',
}
