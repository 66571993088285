import Invoice from 'client/components/Invoice';
import Modal from 'client/components/Modal';
import DataTable from 'client/containers/DataTableContainer';
import PackageForm from 'client/containers/PackageForm';
import ExpandedRows from 'client/pages/Dashboard/AdminPackages/ExpandableRow';
import { getAllPackagesExp } from 'client/_redux/actions/packages';
import * as React from 'react';
import PackageFilter from './PackageFilter';
import { useExpeditorPackagesPage } from './useExpeditorPackages';

const ExpeditorPackagesPage: React.FC = () => {
  const {
    columns,
    dataTableRef,
    expeditorPackages,
    isUpdateModalVisible,
    handleCloseUpdateModal,
    itemToUpdateOrDelete,
    isPrintModalVisible,
    handleClosePrintModal,
  } = useExpeditorPackagesPage();

  return (
    <div className="d-flex justify-content-around flex-wrap">
      <DataTable
        myRef={dataTableRef}
        data={expeditorPackages}
        title="Mes colis"
        columns={columns}
        fetchDataFn={getAllPackagesExp}
        subHeaderComponent={<PackageFilter />}
        expandableRows
        expandableIcon={{
          collapsed: <i className="fas fa-chevron-circle-down m-auto" />,
          expanded: <i className="fas fa-chevron-circle-up m-auto" />,
        }}
        ExpandedComponent={ExpandedRows}
      />

      {itemToUpdateOrDelete && (
        <>
          <Modal
            isOpen={isUpdateModalVisible}
            closeModal={handleCloseUpdateModal}
            modalHead="Modifier votre colis"
          >
            <PackageForm
              isUpdate
              itemToUpdateOrDelete={itemToUpdateOrDelete}
              setIsUpdateModalVisible={handleCloseUpdateModal}
            />
          </Modal>
          <Modal
            isOpen={isPrintModalVisible}
            closeModal={handleClosePrintModal}
            modalHead="Imprimer votre colis"
          >
            <Invoice parcel={itemToUpdateOrDelete} />
          </Modal>
        </>
      )}
    </div>
  );
};

export default ExpeditorPackagesPage;
