import Input from 'client/components/Form/Input';
import Select from 'client/components/Form/Select';
import Invoice from 'client/components/Invoice';
import Modal from 'client/components/Modal';
import ModalFooter from 'client/components/Modal/ModalFooter';
import { packagesStatesOptions } from 'client/constants/SelectOptions';
import useSearchParamsFilter from 'client/hooks/useSearchParamsFilter';
import {
  deleteManyPackage,
  getAllPackagesExp,
  getPickupPackages,
} from 'client/_redux/actions/packages';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IUserPackageDocument } from 'server/models/Package/types';

type PackageFilterProps = {
  selectedRows?: IUserPackageDocument[];
  isPickup?: boolean;
};
const PackageFilter: React.FC<PackageFilterProps> = ({ selectedRows, isPickup }) => {
  const { handleFilterSearch, searchParams, resetFilter } = useSearchParamsFilter({});
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isPrintModalVisible, setIsPrintModalVisible] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('all');

  const dispatch = useDispatch();
  const packagesIds = useMemo(() => selectedRows?.map((row) => row._id), [selectedRows]);
  const handlDeleteManyPackages = () => {
    packagesIds && dispatch(deleteManyPackage(packagesIds));
    setIsDeleteModalVisible(false);
  };

  return (
    <div className="d-flex justify-content-between align-items-center w-100 flex-wrap pt-4 flex-wrap no-gutters">
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <Input
          type="text"
          placeholder="Rechercher..."
          value={searchParams.get('keyword') || ''}
          onChange={(e) => handleFilterSearch('keyword', e.currentTarget.value)}
          name="keyword"
          className="mx-3"
        />
        <Select
          className="mb-0"
          placeholder="recherche par état"
          onChange={(e) => handleFilterSearch('state', e as string)}
          options={packagesStatesOptions}
        />
        <button
          type="button"
          className={`btn-sm btn  m-1 mx-3 ${
            selectedFilter === 'fast' ? 'btn-primary' : 'btn-outline-primary'
          }`}
          onClick={() => {
            dispatch(
              isPickup
                ? getPickupPackages({ isFastPackage: true })
                : getAllPackagesExp({ isFastPackage: true }),
            );
            setSelectedFilter('fast');
          }}
        >
          colis rapide
        </button>
        <button
          type="button"
          className={`btn-sm btn  m-1 ${
            selectedFilter === 'all' ? 'btn-primary' : 'btn-outline-primary'
          }`}
          onClick={() => {
            dispatch(isPickup ? getPickupPackages() : getAllPackagesExp());
            setSelectedFilter('all');
          }}
        >
          Tous les colis
        </button>
      </div>

      <div>
        <button
          type="button"
          className="btn-sm btn btn-outline-primary m-1"
          onClick={() => resetFilter()}
        >
          Reset
        </button>
        {!!selectedRows?.length && (
          <>
            <button
              type="button"
              className="btn-sm btn btn-outline-info m-1"
              onClick={() => setIsPrintModalVisible(true)}
            >
              Imprimer
            </button>

            <button
              type="button"
              className="btn-sm btn btn-outline-danger m-1"
              onClick={() => setIsDeleteModalVisible(true)}
            >
              Supprimer
            </button>
            <Modal
              childrenClassName="py-4"
              isOpen={isDeleteModalVisible}
              closeModal={() => setIsDeleteModalVisible(false)}
              modalHead="Supprimer votre colis"
              footer={ModalFooter({
                handleSubmitModal: handlDeleteManyPackages,
                cancelModal: () => setIsDeleteModalVisible(false),
                modalButtonText: 'Confirmer',
              })}
            >
              <span>êtes-vous sûr de supprimer les {selectedRows.length} colis</span>
            </Modal>
            <Modal
              childrenClassName="py-4"
              isOpen={isPrintModalVisible}
              closeModal={() => setIsPrintModalVisible(false)}
              modalHead="Supprimer votre colis"
            >
              <Invoice isMultiPackage parcels={selectedRows} />
            </Modal>
          </>
        )}
      </div>
    </div>
  );
};

export default PackageFilter;
