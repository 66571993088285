/* eslint-disable react/no-unescaped-entities */
import LoginForm from 'client/containers/LoginForm';
import React from 'react';
import SEO from 'client/components/SEO';
import RegisterForm from 'client/containers/RegisterForm';
import { useAuthModal } from './useAuthModal';
import Modal from '../../components/Modal';
export interface AuthModalProps {
  isAuthModal?: boolean;
  setIsAuthModal: (isAuthModal: boolean) => void;
  loginModal?: boolean;
  setLoginModal: (isAuthModal: boolean) => void;
}
const AuthModal = ({
  setIsAuthModal,
  isAuthModal,
  loginModal,
  setLoginModal,
}: AuthModalProps) => {
  const { onSubmitLogin, onSubmitRegister, isLoading } = useAuthModal(setIsAuthModal);
  const renderModal = (loginModal: boolean) => {
    switch (loginModal) {
      case true:
        return <LoginForm onSubmit={onSubmitLogin} isLoading={isLoading} />;
      case false:
        return <RegisterForm onSubmit={onSubmitRegister} isLoading={isLoading} />;
      default:
        return <LoginForm onSubmit={onSubmitLogin} isLoading={isLoading} />;
    }
  };

  return (
    <>
      <SEO title="NextPack delivery" description="la page d'acceuil du site" />
      <Modal
        childrenClassName="row"
        closeModal={() => setIsAuthModal(false)}
        isOpen={isAuthModal}
      >
        <div className={`${loginModal ? 'col-sm-6 left-side p-4' : 'col-sm-8 left-side p-4'}`}>
          <div className="d-flex">
            <div className="p-1">
              <button
                onClick={() => setLoginModal(true)}
                className={`btn btn-sm ${
                  loginModal
                    ? 'btn-primary transition-3d-hover'
                    : 'btn-outline-primary transition-3d-hover'
                }`}
                type="button"
              >
                Connexion
              </button>
            </div>
            <div className="p-1">
              <button
                onClick={() => setLoginModal(false)}
                className={`btn btn-sm ${
                  !loginModal
                    ? 'btn-primary transition-3d-hover'
                    : 'btn-outline-primary transition-3d-hover'
                }`}
                type="button"
              >
                Devenir expéditeur
              </button>
            </div>
          </div>
          <hr className="border-primary transition-3d-hover" />
          <div className="form-show login-form-container">{renderModal(loginModal)}</div>
        </div>
        <div
          className={`${
            loginModal ? 'col-sm-6' : 'col-sm-4'
          } d-flex justify-content-center align-items-center rounded right-side position-relative p-1`}
        >
          <div
            className="d-flex align-items-center justify-content-center w-100 h-100 m-1 rounded"
            style={{ backgroundColor: '#006aff' }}
          >
            <img
              alt="Next-pack"
              width="200"
              src="/assets/images/nextPackLogo.png"
              height="auto"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AuthModal;
