import { clientApi } from 'api';
import { ILoginFormValues } from 'client/containers/LoginForm/useLoginForm';
import { ISignInResponse } from 'types/Auth';

export const login = (isServer = false) => async (values: ILoginFormValues) => {
  const response = await clientApi.post<ISignInResponse>('/users/sign-in', values);

  return response;
};

export const notificationToken = (isServer = false) => async (fcmToken: string) => {
  const response = await clientApi.patch('/notification', { fcmToken });

  return response;
};
