import React from 'react';
import { IUserPackageDocument } from 'server/models/Package/types';
import { TableColumn } from 'react-data-table-component';
import { format } from 'date-fns';
import BarCode from 'react-barcode';

export const scannedPackagesTableColumns = (): TableColumn<IUserPackageDocument>[] => [
  {
    name: 'code a bare',
    cell: (e) => <BarCode width={1} height={40} value={e.barCode || e.exchangeBarCode} />,
    center: true,
  },
  {
    name: 'nom du client',
    selector: (e) => `${e?.client?.name || e.mainPackage?.client?.name}`,
    center: true,
    sortable: true,
    sortField: 'client.clientPhoneNumber',
  },
  {
    name: 'Telephone du client',
    selector: (e) =>
      `${e?.client?.clientPhoneNumber || e.mainPackage?.client?.clientPhoneNumber}`,
    center: true,
    sortable: true,
    sortField: 'client.clientPhoneNumber',
  },
  {
    name: 'Fragile',
    selector: (e) => `${e?.isDelicate ? 'Oui' : 'Non'}`,
    center: true,
    sortable: true,
    sortField: 'isDelicate',
    style: {
      fontWeight: 'bold',
    },
  },
  {
    name: 'Status',
    selector: (e) => `${e?.state}`,
    center: true,
    sortable: true,
    sortField: 'state',
  },
  {
    name: "Nom d'article",
    selector: (e) => `${e?.articleName || ''}`,
    center: true,
    sortable: true,
    sortField: 'articleName',
    style: {
      fontWeight: 'bold',
    },
  },
  {
    name: 'Prix',
    selector: (e) => `${e?.price || 0} DT`,
    center: true,
    sortable: true,
    sortField: 'price',
    style: {
      fontWeight: 'bold',
    },
  },

  {
    name: 'Destination',
    selector: (e) =>
      `${e?.destination?.city || e.mainPackage?.destination?.city}-${
        e?.destination?.address || e.mainPackage?.destination?.address
      }`,
    center: true,
    sortable: true,
    sortField: 'destination.country',
    style: {
      fontWeight: 'bold',
    },
  },

  {
    sortField: 'createdAt',
    name: 'Date de creation',
    cell: (e) => e?.createdAt && format(new Date(e?.createdAt), 'MM/dd/yyyy HH:mm'),
    sortable: true,
    center: true,
  },
];
