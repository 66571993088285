import { errorHandler } from 'client/helpers/errorHandler';
import {
  addUser,
  ResetPasswordFormValues,
  updateUser,
  updateUserPassword,
} from 'client/_redux/actions/user';
import { getUserById } from 'client/_redux/services/user';
import { useCallback, useState, useEffect } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { IUser } from 'server/models/User/types';

export const useUserFormPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams<string>();
  const [defaultValues, setDefaultValues] = useState<IUser>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) return;
    getUserById()(id)
      .then((data) => setDefaultValues(data))
      .catch(errorHandler);
  }, [id]);
  const onSubmit: SubmitHandler<IUser> = useCallback(
    (values) => {
      dispatch(
        defaultValues ? updateUser(values, id as string, navigate) : addUser(values, navigate),
      );
    },

    [defaultValues, dispatch, id, navigate],
  );
  const onSubmitPassword: SubmitHandler<ResetPasswordFormValues> = useCallback(
    (values) => {
      dispatch(updateUserPassword(values, id as string, navigate));
    },

    [dispatch, id, navigate],
  );

  return { onSubmit, onSubmitPassword, defaultValues };
};
