import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { IPaymentHistory } from 'server/models/PaymentHistory/types';
import PaymentInvoiceContent from './PaymentInvoiceContent';

type PaymentInvoiceProps = {
  paymentInvoice: IPaymentHistory;
};
const PaymentInvoice: React.FC<PaymentInvoiceProps> = ({ paymentInvoice }) => {
  const componentRef = useRef(null);

  console.log({ paymentInvoice });

  return (
    <>
      <div className="container overflow-auto pt-4 mt-5">
        <PaymentInvoiceContent paymentInvoice={paymentInvoice} invoiceRef={componentRef} />
      </div>
      <ReactToPrint
        trigger={() => (
          <div className="col-12 d-flex justify-content-center">
            <button className="btn btn-soft-dark mt-6  mb-6" type="button">
              Imprimer le facture de payment ↓ <i className="fa fa-print" />
            </button>
          </div>
        )}
        content={() => componentRef.current}
      />
    </>
  );
};

export default PaymentInvoice;
