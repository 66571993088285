import React from 'react';

type LoaderProps = {
  className?: string;
};
const Loader: React.FC<LoaderProps> = ({ className }) => (
  <div className={`d-flex justify-content-center align-items-center ${className}`}>
    <div className="lds-circle">
      <div className="circle" />
    </div>
  </div>
);

export default Loader;
