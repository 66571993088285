import { TableColumn } from 'react-data-table-component';
import { IRunsheetDocument } from 'server/models/Runsheet/types';
import { DeliveringPackageTypeEnum } from 'types/Enums/DeliveringPackagesEnum';
import { pickupColumns } from './tableColumns/pickupColumns';
import { deliveryColumns } from './tableColumns/deliveryColumns';
import { manifestColumns } from './tableColumns/manifestColumns';

export const RunsheetTableColumns = (
  type: DeliveringPackageTypeEnum,
): TableColumn<IRunsheetDocument>[] => {
  switch (type) {
    case DeliveringPackageTypeEnum.PICKUP:
      return pickupColumns();
    case DeliveringPackageTypeEnum.DELIVERING:
      return deliveryColumns();
    case DeliveringPackageTypeEnum.RETURN:
      return manifestColumns();
    default:
      return deliveryColumns();
  }
};
