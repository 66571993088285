import { useCallback } from 'react';
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';

const useSearchParamsFilter = (initParams: URLSearchParamsInit) => {
  const [searchParams, setSearchParam] = useSearchParams(initParams);

  const handleFilterSearch = useCallback(
    (key: string, searchValue: string, type?: string) => {
      const value = typeof searchValue === 'undefined' ? '' : searchValue;

      searchParams.set(key, value);
      type && searchParams.set('type', type);
      setSearchParam(searchParams);
    },
    [searchParams, setSearchParam],
  );
  const resetFilter = () => setSearchParam(initParams);

  return {
    handleFilterSearch,
    searchParams,
    setSearchParam,
    resetFilter,
  };
};

export default useSearchParamsFilter;
