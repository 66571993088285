import Validators from 'client/helpers/formValidator';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import DataTable from 'react-data-table-component';
import Modal from 'client/components/Modal';
import ScanInput from 'client/components/Form/ScanInput';
import { scanManifestPackages } from 'client/_redux/actions/packages';
import Manifest from 'client/components/Manifest';
import { useManifestForm } from './useManifestForm';

const ManifestForm: React.FC = () => {
  const {
    handleSubmit,
    onSubmit,
    formMethods,
    scannedManifestPackages,
    columns,
    toggleManifestModal,
    setToggleManifestModal,
    manifest,
    packagesList,
    id,
    expeditor,
    loading,
  } = useManifestForm();

  return !loading ? (
    <div className="row">
      <div className="col-md-4 col-sm-12 mt-3">
        <span className="h3 ">
          Retour expéditeur pour {expeditor?.firstName} {expeditor?.lastName}
        </span>
        <FormProvider {...formMethods}>
          <form className="mt-5" onSubmit={handleSubmit(onSubmit)}>
            <ScanInput
              label={`scanner le code-barres (${scannedManifestPackages?.length} colis scannés)`}
              name="barCode"
              validate={Validators([{ validation: 'packageListNotEmpty', packagesList }])}
              action={(code) => scanManifestPackages(Number(code), id)}
            />

            <div className="d-flex justify-content-center">
              <button className="btn btn-success m-2" type="submit">
                Creé et imprimer le manifest
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
      <div className="col-md-8 col-sm-12">
        <div className="d-flex justify-content-around flex-wrap side-table">
          <DataTable data={scannedManifestPackages} columns={columns} />
        </div>
      </div>
      <Modal
        isOpen={toggleManifestModal}
        closeModal={() => setToggleManifestModal(false)}
        modalHead="Manifast"
      >
        {expeditor && manifest && <Manifest manifest={manifest} expeditor={expeditor} />}
      </Modal>
    </div>
  ) : (
    <>loading</>
  );
};

export default ManifestForm;
