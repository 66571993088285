import { useTableColumnsWithActions } from 'client/containers/DataTableContainer/useTableColumnsWithActions';
import { RootState } from 'client/_redux/reducers';
import { PackagesExpTypes } from 'client/_redux/types/packagesExpTypes';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { scannedPackagesTableColumns } from './scannedPackagesTableColumns';
export const useAcceptPackages = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: PackagesExpTypes.CLEAR_ACCEPTED_PACKAGES_LIST });
  }, [dispatch]);

  const columns = useTableColumnsWithActions({
    columns: scannedPackagesTableColumns(),
    buttons: [],
  });
  const scannedPackages = useSelector(
    ({ packages: { scannedPackages } }: RootState) => scannedPackages,
  );

  useEffect(() => {
    if (scannedPackages?.length)
      dispatch({ type: PackagesExpTypes.CLEAR_SCANNED_PACKAGES_LIST });
  }, [dispatch, scannedPackages?.length]);

  return { scannedPackages, columns };
};
