/* eslint-disable max-lines */
import { SearchQuery } from 'client/containers/DataTableContainer/types';
import { errorHandler } from 'client/helpers/errorHandler';
import { IRunsheetDocument } from 'server/models/Runsheet/types';
import { IThunkAction } from 'types/IThunkAction';
import * as runsheetService from 'client/_redux/services/runsheet';
import { RunsheetTypes } from 'client/_redux/types/RunsheetTypes';
import { returnRunsheetFormType, runsheetFormType } from 'types/forms/runsheetFormType';
import { toast } from 'react-toastify';
import { NavigateFunction } from 'react-router-dom';
import { playAudioSound } from 'client/helpers/effectSound';
import { RunsheetValidation } from 'types/RunsheetValidationType';
import { PackageStateEnum } from 'types/Enums/PackageStateEnum';
import { PackageReturnCuzEnum } from 'types/Enums/PackageReturnCuzEnum';
import { DeliveringPackageTypeEnum } from 'types/Enums/DeliveringPackagesEnum';
import { startLoading, stopLoading } from '../loading';

export const getManagerRunsheet = (
  searchQuery?: SearchQuery<IRunsheetDocument>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const data = await runsheetService.getManagerRunsheet()(searchQuery);

    dispatch({ type: RunsheetTypes.FETCH_RUNSHEET_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: RunsheetTypes.FETCH_RUNSHEET_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const getDrivers = async () => {
  try {
    return await runsheetService.getDrivers()();
  } catch (error) {
    return [];
  }
};
export const getRunsheetById = async (id: string) => {
  try {
    return await runsheetService.getRunsheetById()(id);
  } catch (error) {
    return undefined;
  }
};
export const getRunsheetPackagesId = async (id: string) => {
  try {
    return await runsheetService.getRunsheetPackagesId()(id);
  } catch (error) {
    return undefined;
  }
};
export const scanBackToWarehouse = (
  code: number,
  runsheetId: string,
  setPackagesList: React.Dispatch<React.SetStateAction<RunsheetValidation>>,
): IThunkAction => async () => {
  try {
    const { runsheetList, isValidCode } = await runsheetService.scanBackToWarehouse()(
      code,
      runsheetId,
    );

    setPackagesList(runsheetList);

    playAudioSound(isValidCode);
    toast(isValidCode ? 'scanner avec sucssé' : 'error', {
      type: isValidCode ? 'info' : 'error',
    });
  } catch (error) {
    toast('error', { type: 'error' });
  }
};
export const scanBackToExpeditor = (
  code: number,
  runsheetId: string,
  setPackagesList: React.Dispatch<React.SetStateAction<RunsheetValidation>>,
): IThunkAction => async () => {
  try {
    const { runsheetList, isValidCode } = await runsheetService.scanBackToExpeditor()(
      code,
      runsheetId,
    );

    setPackagesList(runsheetList);

    playAudioSound(isValidCode);
    toast(isValidCode ? 'scanner avec sucssé' : 'error', {
      type: isValidCode ? 'info' : 'error',
    });
  } catch (error) {
    toast('error', { type: 'error' });
  }
};
export const scanExchangePackage = (
  code: number,
  runsheetId: string,
  setPackagesList: React.Dispatch<React.SetStateAction<RunsheetValidation>>,
  setScannedExchange: React.Dispatch<React.SetStateAction<string[]>>,
): IThunkAction => async () => {
  try {
    const {
      runsheetList,
      isValidCode,
      mainPackage,
    } = await runsheetService.scanExchangePackage()(code, runsheetId);

    setPackagesList(runsheetList);

    playAudioSound(isValidCode);
    isValidCode && setScannedExchange((prev) => [...prev, mainPackage]);

    toast(isValidCode ? 'scanner avec sucssé' : 'error', {
      type: isValidCode ? 'info' : 'error',
    });
  } catch (error) {
    toast('error', { type: 'error' });
  }
};
export const validateRunsheet = (id: string): IThunkAction => async () => {
  try {
    await runsheetService.validateRunsheet()(id);

    toast('Runsheet valider avec sucssé', { type: 'info' });
  } catch (error) {
    toast('error', { type: 'error' });
  }
};
export const deleteRunsheet = async (id: string, navigate: NavigateFunction) => {
  try {
    const data = await runsheetService.deleteRunsheet()(id);

    toast(data ? 'runsheet supprimer avec success' : 'error', {
      type: data ? 'success' : 'error',
    });

    navigate('/runsheet');
  } catch (error) {
    toast('error', {
      type: 'error',
    });
  }
};
export const deleteAdminRunsheet = (
  id: string,
  type: DeliveringPackageTypeEnum,
): IThunkAction => async (dispatch) => {
  try {
    const data = await runsheetService.deleteAdminRunsheet()(id);

    dispatch(getManagerRunsheet({ type }));
    toast(data ? 'runsheet supprimer avec success' : 'error', {
      type: data ? 'success' : 'error',
    });
  } catch (error) {
    toast('error', {
      type: 'error',
    });
  }
};
export const createRunsheet = (values: runsheetFormType): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const data = await runsheetService.createRunsheet()(values);

    dispatch({ type: RunsheetTypes.CREATE_RUNSHEET_SUCCESS, payload: data });

    toast('runsheet créé avec succès', {
      type: 'success',
    });
    dispatch(getManagerRunsheet());
  } catch (error) {
    errorHandler(error);
    dispatch({ type: RunsheetTypes.CREATE_RUNSHEET_FAILURE, payload: undefined });
  }
  dispatch(stopLoading());
};
export const createReturnRunsheet = (values: returnRunsheetFormType): IThunkAction => async (
  dispatch,
) => {
  dispatch(startLoading());
  try {
    const data = await runsheetService.createReturnRunsheet()(values);

    dispatch({ type: RunsheetTypes.CREATE_MANIFEST_SUCCESS, payload: data });

    toast('Manifest créé avec succès', {
      type: 'success',
    });
    dispatch(getManagerRunsheet());
  } catch (error) {
    errorHandler(error);
    dispatch({ type: RunsheetTypes.CREATE_MANIFEST_FAILURE, payload: undefined });
  }
  dispatch(stopLoading());
};
export const updateRunsheet = (values: runsheetFormType, id?: string): IThunkAction => async (
  dispatch,
) => {
  dispatch(startLoading());
  try {
    const data = await runsheetService.updateRunsheet()(values, id);

    dispatch({ type: RunsheetTypes.CREATE_RUNSHEET_SUCCESS, payload: data });

    toast('runsheet créé avec succès', {
      type: 'success',
    });
    dispatch(getManagerRunsheet());
  } catch (error) {
    errorHandler(error);
    dispatch({ type: RunsheetTypes.CREATE_RUNSHEET_FAILURE, payload: undefined });
  }
  dispatch(stopLoading());
};
export const affectDriverToManifest = (driver: string, id: string): IThunkAction => async (
  dispatch,
) => {
  dispatch(startLoading());
  try {
    await runsheetService.affectDriverToManifest()(driver, id);

    toast('livreur affecter avec succès', {
      type: 'success',
    });
    dispatch(getManagerRunsheet({ type: DeliveringPackageTypeEnum.RETURN }));
  } catch (error) {
    errorHandler(error);
    dispatch({ type: RunsheetTypes.CREATE_RUNSHEET_FAILURE, payload: undefined });
  }
  dispatch(stopLoading());
};

export const getExchangePackage = async (id: string) => {
  try {
    return await runsheetService.getExchangePackage()(id);
  } catch (error) {
    return undefined;
  }
};

export const changePackageState = async (
  id: string,
  state: PackageStateEnum,
  runsheetId: string,
  returnCuz?: PackageReturnCuzEnum,
) => {
  try {
    return await runsheetService.changePackageState(id, state, runsheetId, returnCuz);
  } catch (error) {
    return undefined;
  }
};
export const adminChangePackageState = async (id: string, state: PackageStateEnum) => {
  try {
    return await runsheetService.adminChangePackageState(id, state);
  } catch (error) {
    return undefined;
  }
};
