import Balance from 'client/pages/Balance';
import PackagesStatisticPage from 'client/pages/Expeditor/Home';
import Pickup from 'client/pages/Expeditor/Packages/Pickup';
import { Route } from 'react-router-dom';
import React from 'react';
import ExpeditorPackagesPage from 'client/pages/Expeditor/Packages';
import Address from 'client/pages/Expeditor/Address';
import { UserRolesEnum } from 'types/Enums/UserRolesEnum';
import { renderClassicRotes } from './DefaultRoutes';

export const renderExpeditorRoutes = (isLoggedIn: boolean, role: UserRolesEnum) => {
  if (isLoggedIn)
    return (
      <>
        {renderClassicRotes()}
        <Route
          path="/pickup"
          element={
            <div className="pt-10">
              <Pickup />
            </div>
          }
        />
        <Route
          path="/mes-colis"
          element={
            <div className="pt-10">
              <ExpeditorPackagesPage />
            </div>
          }
        />
        <Route
          path="/mon-adresse"
          element={
            <div className="pt-10">
              <Address />
            </div>
          }
        />
        <Route
          path="/balance"
          element={
            <div className="pt-10">
              <Balance />
            </div>
          }
        />
        <Route
          path="/statistique"
          element={
            <div className="pt-10">
              <PackagesStatisticPage role={role} />
            </div>
          }
        />
      </>
    );

  return renderClassicRotes();
};
