import DriverInvoice from 'client/components/DriverInvoice';
import ExchangePackageInvoice from 'client/components/ExchangePackageInvoice';
import ScanInput from 'client/components/Form/ScanInput';
import Modal from 'client/components/Modal';
import {
  scanBackToExpeditor,
  scanBackToWarehouse,
  scanExchangePackage,
} from 'client/_redux/actions/runsheet';
import React from 'react';
import DataTable from 'react-data-table-component';
import { FormProvider } from 'react-hook-form';
import { useConfirmRunsheet } from './useConfirmRunsheet';

const ConfirmRunsheet: React.FC = () => {
  const {
    runsheet,
    formMethods,
    id,
    setExchangePackagesScanned,
    setPackagesList,
    failedPackages,
    failedColumns,
    total,
    deliveredPackages,
    deliveredColumns,
    invalidButton,
    onSubmit,
    toggleExchangePackageModal,
    setToggleExchangePackageModal,
    exchangePackage,
    setToggleDriverInvoice,
    packagesList,
    toggleDriverInvoice,
  } = useConfirmRunsheet();

  return (
    <div>
      <div className="d-flex justify-content-center">
        <span className="h1">{`Valider runsheet N° ${runsheet?.codeRunsheet}`}</span>
      </div>
      <FormProvider {...formMethods}>
        <div className="d-flex justify-content-center">
          <ScanInput
            label="Colis echange"
            className="p-3"
            name="ce"
            id="ce"
            action={(code) =>
              id && scanExchangePackage(code, id, setPackagesList, setExchangePackagesScanned)
            }
          />
          <ScanInput
            label="Reteur définitive"
            className="p-3"
            name="re"
            id="re"
            action={(code) => id && scanBackToExpeditor(code, id, setPackagesList)}
          />
          <ScanInput
            label="Reteur Dépo"
            className="p-3"
            name="rd"
            id="rd"
            action={(code) => id && scanBackToWarehouse(code, id, setPackagesList)}
          />
        </div>
      </FormProvider>
      <div className="row justify-content-center">
        <div style={{ border: 'solid' }} className="col-md-5 col-sm-12 mr-1 overflow-scroll">
          <DataTable
            className="overflow-scroll"
            title="List des colis non livrée"
            data={failedPackages}
            columns={failedColumns}
          />
        </div>
        <div style={{ border: 'solid' }} className="col-md-5 col-sm-12 ml-1">
          <DataTable
            title={`List des colis livrée | Montant total :${total} DT`}
            data={deliveredPackages}
            columns={deliveredColumns}
          />
        </div>
      </div>
      <div className="d-flex justify-content-center py-4">
        <button
          disabled={invalidButton}
          onClick={onSubmit}
          className="btn btn-primary"
          type="button"
        >
          Valider runsheet N° {runsheet?.codeRunsheet}
        </button>
      </div>
      <Modal
        isOpen={toggleExchangePackageModal}
        closeModal={() => setToggleExchangePackageModal(false)}
        modalHead="Colis echange"
      >
        {exchangePackage && <ExchangePackageInvoice mainPackage={exchangePackage} />}
      </Modal>
      <Modal
        isOpen={toggleDriverInvoice}
        closeModal={() => setToggleDriverInvoice(false)}
        modalHead="Facture livruer"
      >
        {packagesList.runsheet && <DriverInvoice runsheet={packagesList} />}
      </Modal>
    </div>
  );
};

export default ConfirmRunsheet;
