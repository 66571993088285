import FormSelect from 'client/components/Form/FormSelect';
import Input from 'client/components/Form/Input';
import Validators from 'client/helpers/formValidator';
import { useNewAddress } from 'client/hooks/NewAddressHoock/useNewAddress';
import { createAddress, updateAddress } from 'client/_redux/actions/Address';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { IAddress } from 'server/models/Address/types';
import { AddressFormType } from 'types/forms/AddressFormType';

type AddressFormProps = {
  address?: IAddress;
};
const AddressForm: React.FC<AddressFormProps> = ({ address }) => {
  const dispatch = useDispatch();
  const [selectedState, setSelectedState] = useState('');
  const { cityOptions, stateOptions } = useNewAddress(selectedState);

  const formMethods = useForm<AddressFormType>({
    shouldFocusError: true,
  });
  const { handleSubmit, watch } = formMethods;

  useEffect(() => {
    const state = watch('state');

    if (state) setSelectedState(state);
  }, [selectedState, watch]);
  const onSubmit = (values: AddressFormType) => {
    dispatch(
      address
        ? updateAddress({ _id: (address as IAddress & { _id: string })._id, ...values })
        : createAddress(values),
    );
  };

  return (
    <FormProvider {...formMethods}>
      <form className="mt-5" onSubmit={handleSubmit(onSubmit)}>
        <div className="text-center">
          <span className="h4 pb-2 text-center">
            {address ? 'Modifier votre adresse ' : 'Creé votre adresse '}
            <i className="fas fa-map-marker-alt" />
          </span>
          <div className="row mt-4">
            <div className="col-md-6 col-sm-12">
              <Input
                validate={Validators([{ validation: 'required' }])}
                label="Votre pays"
                value="tunis"
                type="text"
                name="country"
                placeholder="Votre Pays"
                readOnly
              />
            </div>
            <div className="col-md-6 col-sm-12 my-auto text-left">
              <FormSelect
                validate={Validators([{ validation: 'required' }])}
                label="Votre Gouvernemet"
                placeholder="Votre Gouvernemet"
                isSearchable
                name="state"
                options={stateOptions}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12 my-auto text-left">
              <FormSelect
                validate={Validators([{ validation: 'required' }])}
                label="Votre Ville"
                placeholder="Votre Ville"
                isSearchable
                name="city"
                options={cityOptions}
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <Input
                validate={Validators([{ validation: 'required' }])}
                label="Votre Adresse"
                type="text"
                name="address"
                placeholder="Votre Adresse"
              />
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <button className="btn btn-success" type="submit">
            {address ? 'Modifier' : 'Ajouter'}
          </button>
        </div>
      </form>
    </FormProvider>
  );
};

export default AddressForm;
