import { PackagesExpTypes } from 'client/_redux/types/packagesExpTypes';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { IRunsheetDocument } from 'server/models/Runsheet/types';
import { IUser } from 'server/models/User/types';
import ManifestContent from './ManifestContent';

type InvoiceProps = {
  manifest: IRunsheetDocument;
  expeditor: IUser;
};
const Manifest: React.FC<InvoiceProps> = ({ manifest, expeditor }) => {
  const componentRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <>
      <div className="container overflow-auto pt-4 mt-5">
        <ManifestContent expeditor={expeditor} manifest={manifest} invoiceRef={componentRef} />
      </div>
      <ReactToPrint
        trigger={() => (
          <div className="col-12 d-flex justify-content-center">
            <button className="btn btn-soft-dark mt-6  mb-6" type="button">
              Imprimer le runsheet ↓ <i className="fa fa-print" />
            </button>
          </div>
        )}
        content={() => componentRef.current}
        onAfterPrint={() => {
          navigate('/retour-expediteur');
          dispatch({ type: PackagesExpTypes.CLEAR_SCANNED_PACKAGES_LIST });
        }}
      />
    </>
  );
};

export default Manifest;
