import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { IUser } from 'server/models/User/types';
import { UserFormProps } from '.';

export const useUserForm = ({ onSubmit, defaultValues }: UserFormProps) => {
  const [mainPictureIndex, setMainPictureIndex] = useState(0);

  const formMethods = useForm<IUser>({
    shouldFocusError: true,
  });
  const { handleSubmit, reset } = formMethods;
  const _onSubmit: SubmitHandler<IUser> = (data) => {
    if (!data.fastPackageLeveringFee) delete data.fastPackageLeveringFee;
    if (!data.fastPackageReturnedPackageFee) delete data.fastPackageReturnedPackageFee;
    if (!data.leveringFee) delete data.leveringFee;
    if (!data.returnedPackageFee) delete data.returnedPackageFee;
    if (onSubmit) {
      onSubmit(data);
    }
  };

  useEffect(() => {
    if (!defaultValues) return;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    reset(defaultValues);
  }, [defaultValues, reset]);

  return {
    _onSubmit,
    handleSubmit,
    formMethods,
    mainPictureIndex,
    setMainPictureIndex,
  };
};
