import React, { FunctionComponent, useEffect } from 'react';
import QRCode from 'qrcode';

export interface QrCodeProps {
  url: string;
}

export const QrCode: FunctionComponent<QrCodeProps> = ({ url }: QrCodeProps) => {
  let qrRendered = false;

  const writeQRtoCanvas = () => {
    try {
      QRCode.toCanvas(document.getElementById('qr-code'), url || '', {
        width: 200,
      });
    } catch {
      console.error('The QR code failed to write to the canvas');
    }
  };

  useEffect(() => {
    if (!qrRendered) {
      writeQRtoCanvas();
      qrRendered = true;
    }
  }, [writeQRtoCanvas]);

  return (
    <div id="qr-box">
      <canvas id="qr-code" />
    </div>
  );
};
