import 'react-toastify/dist/ReactToastify.css';
import * as React from 'react';

type INotificationToast = {
  title?: string;
  body?: string;
};
const NotificationToast: React.FC<INotificationToast> = ({ title, body }) => (
  <div className="">
    <h4 className="font-weight-bold text-primary">{title}</h4>
    <p>{body}</p>
  </div>
);

export default NotificationToast;
