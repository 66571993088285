import Input from 'client/components/Form/Input';
import * as React from 'react';
import Validators from 'client/helpers/formValidator';
import { SubmitHandler, FormProvider } from 'react-hook-form';

import Loader from 'client/components/Loader';
import { IRegisterFormValues, useRegisterForm } from './useRegisterForm';

interface RegisterFormProps {
  onSubmit: SubmitHandler<IRegisterFormValues>;
  isLoading: boolean;
}

const RegisterForm: React.FC<RegisterFormProps> = ({ onSubmit, isLoading }) => {
  const { formMethods, handleSubmit, _onSubmit, password } = useRegisterForm(onSubmit);

  return (
    <FormProvider {...formMethods}>
      <form className="mt-5" onSubmit={handleSubmit(_onSubmit)}>
        <div className="row">
          <div className="col-md-6">
            <Input
              type="text"
              name="firstName"
              placeholder="Nom"
              validate={Validators([{ validation: 'required' }])}
            />
            <Input
              type="password"
              name="password"
              placeholder="Mot de pass"
              validate={Validators([
                { validation: 'required' },
                { minParams: 8, validation: 'minLength' },
              ])}
            />
          </div>
          <div className="col-md-6">
            <Input
              type="text"
              name="lastName"
              placeholder="Prénom"
              validate={Validators([{ validation: 'required' }])}
            />
            <Input
              type="password"
              name="passwordConfirm"
              placeholder="Confirmer votre mot de pass"
              validate={Validators([
                { validation: 'required' },
                { minParams: 8, validation: 'minLength' },
                { validation: 'passwordConfirm', passwordConfirm: password },
              ])}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Input
              type="email"
              name="email"
              placeholder="E-mail"
              validate={Validators([{ validation: 'required' }, { validation: 'email' }])}
            />
            <Input
              type="number"
              name="phoneNumber"
              placeholder="Telephone"
              validate={Validators([
                { validation: 'required' },
                { minParams: 8, validation: 'minLength' },
              ])}
            />
          </div>
          <div className="col-md-6">
            <Input
              type="text"
              name="taxNumber"
              placeholder="Matricule fiscale"
              validate={Validators([{ validation: 'required' }])}
            />
            <Input
              type="text"
              name="idNumber"
              placeholder="carte d'identité"
              validate={Validators([{ validation: 'required' }])}
            />
          </div>
        </div>

        <div className="align-items-center mb-5">
          <div className="text-center d-flex justify-content-center">
            {isLoading ? (
              <Loader />
            ) : (
              <button
                className="btn btn-md transition-3d-hover btn-outline-primary btn-block mb-1 col-md-8"
                type="submit"
                disabled={isLoading}
              >
                Soumettre
              </button>
            )}
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default RegisterForm;
