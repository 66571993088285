import { clientApi } from 'api';
import { SearchQuery } from 'client/containers/DataTableContainer/types';
import { PackagesExpTypes } from 'client/_redux/types/packagesExpTypes';
import { IUserPackageDocument } from 'server/models/Package/types';
import { PackageStateEnum } from 'types/Enums/PackageStateEnum';
import { PackageFormType } from 'types/forms/PackageFormType';
import { IThunkAction } from 'types/IThunkAction';
import { packageCsvObject } from 'types/PackageExternalTypes';

const baseUrl = '/package';

export const getAllPackagesExp = (isServer = false) => async (
  searchQuery?: SearchQuery<IUserPackageDocument>,
) => {
  const response = await clientApi.get(`${baseUrl}/user-packages`, { params: searchQuery });

  return response.data;
};

export const getAllPackagesManager = (isServer = false) => async (
  searchQuery?: SearchQuery<IUserPackageDocument>,
) => {
  const response = await clientApi.get(`${baseUrl}/manager/get-expeditors`, {
    params: searchQuery,
  });

  return response.data;
};
export const packagesStatsByDate = (
  searchQuery: SearchQuery<{ from: string; to: string; state: PackageStateEnum }>,
): IThunkAction => async (dispatch) => {
  try {
    const { data } = await clientApi.get(`${baseUrl}/get-package-stats-by-date`, {
      params: searchQuery,
    });

    dispatch({ type: PackagesExpTypes.FETCH_EXPEDITOR_STATS_BY_DAY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PackagesExpTypes.FETCH_EXPEDITOR_STATS_BY_DAY_SUCCESS,
      payload: undefined,
    });
  }
};
export const getExpeditorClients = (isServer = false) => async () => {
  const response = await clientApi.get(`${baseUrl}/expeditor-clients`);

  return response.data;
};
export const getPickupPackages = (isServer = false) => async (
  searchQuery?: SearchQuery<IUserPackageDocument>,
) => {
  const response = await clientApi.get(`${baseUrl}/user-pickup-packages`, {
    params: searchQuery,
  });

  return response.data;
};
export const expeditorStats = (isServer = false) => () =>
  clientApi.get(`${baseUrl}/expeditor-stats`);
export const managerStats = (isServer = false) => () =>
  clientApi.get(`${baseUrl}/manager-stats`);

export const deletePackage = (isServer = false) => async (id: string) => {
  await clientApi.delete<IUserPackageDocument>(`${baseUrl}/${id}`);
};
export const deleteManyPackage = (isServer = false) => async (packages: string[]) => {
  await clientApi.post<IUserPackageDocument>(`${baseUrl}/delete-many-packages`, { packages });
};
export const updatePackage = (isServer = false) => async (
  id: string,
  data: PackageFormType,
) => {
  await clientApi.patch<IUserPackageDocument>(`${baseUrl}/${id}`, data);
};
export const createPackages = (isServer = false) => async (
  data: PackageFormType,
  isFastPackage?: boolean,
) => {
  await clientApi.post<IUserPackageDocument>(baseUrl, {
    ...data,
    isFastPackage: isFastPackage || false,
  });

  return data;
};
export const createMultiplePackages = (isServer = false) => async (
  csvRows: packageCsvObject[],
  isFastPackage?: boolean,
) => {
  await clientApi.post(`${baseUrl}/create-multi-packages`, {
    csvRows,
    isFastPackage,
  });
};
export const managerScanPackages = (isServer = false) => (barCode: number) =>
  clientApi.post(`${baseUrl}/accept-warehouse-package`, {
    barCode,
  });
export const scanRunsheetPackages = (isServer = false) => (
  barCode: number,
  isUpdate?: boolean,
  id?: string,
) =>
  clientApi.post(`${baseUrl}/scan-run-sheet-package`, {
    barCode,
    isUpdate,
    id,
  });
export const scanManifestPackages = (isServer = false) => (barCode: number, id?: string) =>
  clientApi.post(`${baseUrl}/scan-manifest-package`, {
    barCode,
    id,
  });
export const managerCreatePickup = (isServer = false) => (values: {
  driver: string;
  packagesIds: string[];
}) => clientApi.post(`${baseUrl}/manager/create-pickup`, values);
