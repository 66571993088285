import React from 'react';
import Select from 'client/components/Form/Select';
import Modal from 'client/components/Modal';
import { useSelectDriverModal } from './useSelectDriverModal';
type SelectDriverModalProps = {
  toggleModal: boolean;
  setToggleModal: React.Dispatch<React.SetStateAction<boolean>>;
  setDriver: React.Dispatch<React.SetStateAction<string>>;
  onSubmit: () => void;
};
const SelectDriverModal: React.FC<SelectDriverModalProps> = ({
  toggleModal,
  setToggleModal,
  setDriver,
  onSubmit,
}) => {
  const { options, handleDriverSelect } = useSelectDriverModal(setDriver);

  return (
    <Modal isOpen={toggleModal} closeModal={() => setToggleModal(false)} modalHead="">
      {options && (
        <Select
          name="driver"
          label="sélectionner le livreur"
          isSearchable
          isClearable
          options={options}
          onChange={(value) => handleDriverSelect(value)}
        />
      )}
      <div className="d-flex justify-content-center">
        <button className="btn btn-success m-2" type="button" onClick={onSubmit}>
          creé pickup
        </button>
      </div>
    </Modal>
  );
};

export default SelectDriverModal;
