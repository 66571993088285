/* eslint-disable max-lines */
import Input from 'client/components/Form/Input';
import * as React from 'react';
import Validators from 'client/helpers/formValidator';
import { SubmitHandler, FormProvider } from 'react-hook-form';
import FormSelect from 'client/components/Form/FormSelect';
import { USER_ROLES_OPTIONS } from 'client/constants/userRolesOptions';
import { IUser } from 'server/models/User/types';
import FormSwitchBox from 'client/components/Form/FormSwitchBox';
import Collapsible from 'client/components/Collapsible';
import { useUserForm } from './useUserForm';

export interface UserFormProps {
  onSubmit?: SubmitHandler<IUser>;
  defaultValues?: IUser;
  isUpdate?: boolean;
}

const UserForm = ({ onSubmit, defaultValues, isUpdate }: UserFormProps) => {
  const { formMethods, _onSubmit, handleSubmit } = useUserForm({
    defaultValues,
    onSubmit,
  });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(_onSubmit)}>
        <Collapsible open title="Information general">
          <div className="row vw-50">
            <div className="col">
              <Input
                type="text"
                name="email"
                label="Email"
                validate={Validators([{ validation: 'required' }, { validation: 'email' }])}
                default
                defaultValue=""
              />
            </div>
            <div className="col">
              <Input
                type="text"
                name="phoneNumber"
                label="Phone"
                validate={Validators([{ validation: 'required' }, { validation: 'phone' }])}
              />
            </div>
          </div>
          <div className="row vw-50">
            <div className="col">
              <Input
                type="text"
                name="firstName"
                label="firstName"
                validate={Validators([{ validation: 'required' }])}
              />
            </div>
            <div className="col">
              <Input
                type="text"
                name="lastName"
                label="lastName"
                validate={Validators([{ validation: 'required' }])}
              />
            </div>
          </div>
          <div className="row vw-50">
            <div className="col">
              <Input
                type="text"
                name="idNumber"
                label="Carte d'etentiter"
                validate={Validators([{ validation: 'required' }])}
              />
            </div>
            <div className="col">
              <Input type="text" name="taxNumber" label="Matricule fiscale" />
            </div>
          </div>
        </Collapsible>
        <Collapsible className="my-3" title="Parametre du compte">
          <div className="row vw-50">
            <div className="col">
              <Input type="text" name="leveringFee" label="Frais de livraison" />
            </div>
            <div className="col">
              <Input
                type="text"
                name="fastPackageLeveringFee"
                label="Frais de livraison (colis rapide)"
              />
            </div>
          </div>
          <div className="row vw-50">
            <div className="col">
              <Input type="text" name="returnedPackageFee" label="Frais de reteur" />
            </div>
            <div className="col">
              <Input
                type="text"
                name="fastPackageReturnedPackageFee"
                label="Frais de retour (colis rapide)"
              />
            </div>
          </div>
          <div className="row vw-50">
            <div className="col">
              <Input type="text" name="carId" label="Serie de voituer" />
            </div>
            <div className="col">
              <FormSelect
                label="role"
                name="role"
                options={USER_ROLES_OPTIONS}
                validate={Validators([{ validation: 'required' }])}
              />
            </div>
          </div>

          <div className="row justify-content-center py-3">
            <FormSwitchBox name="isVIP" label="VIP" />
          </div>
        </Collapsible>
        {!isUpdate && (
          <Collapsible title="Mot de pass">
            <div className="row">
              <div className="col">
                <Input
                  type="password"
                  name="password"
                  label="password"
                  validate={Validators([
                    { validation: 'required' },
                    { validation: 'minLength', minParams: 8 },
                  ])}
                />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <Input
                  type="password"
                  name="passwordConfrim"
                  label="confirmPassword"
                  validate={Validators([
                    { validation: 'required' },
                    { validation: 'passwordConfirm' },
                  ])}
                />
              </div>
            </div>
          </Collapsible>
        )}

        <div className="row justify-content-center">
          <button className="btn btn-primary mb-1" type="submit">
            {isUpdate ? 'Modifier' : 'Ajouter'}
          </button>
        </div>
      </form>
    </FormProvider>
  );
};

export default UserForm;
