import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactToPrint from 'react-to-print';

import { RunsheetValidation } from 'types/RunsheetValidationType';
import DriverInvoiceContent from './Content';

type DriverInvoiceProps = {
  runsheet: RunsheetValidation;
};
const DriverInvoice: React.FC<DriverInvoiceProps> = ({ runsheet }) => {
  const componentRef = useRef(null);
  const navigate = useNavigate();

  return (
    <>
      <div className="container overflow-auto pt-4 mt-5">
        <DriverInvoiceContent runsheet={runsheet} invoiceRef={componentRef} />
      </div>
      <ReactToPrint
        trigger={() => (
          <div className="col-12 d-flex justify-content-center">
            <button className="btn btn-soft-dark mt-6  mb-6" type="button">
              Imprimer le facture ↓ <i className="fa fa-print" />
            </button>
          </div>
        )}
        content={() => componentRef.current}
        onAfterPrint={() => navigate('/runsheet')}
      />
    </>
  );
};

export default DriverInvoice;
