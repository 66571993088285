import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { IUserPackageDocument } from 'server/models/Package/types';
import InvoiceContent from './InvoiceContent';
import MultiPackageInvoiceContent from './multiPackageInvoiceContent';

type InvoiceProps = {
  parcel?: IUserPackageDocument;
  parcels?: IUserPackageDocument[];
  isMultiPackage?: boolean;
};
const Invoice: React.FC<InvoiceProps> = ({ parcel, isMultiPackage, parcels }) => {
  const componentRef = useRef(null);

  return (
    <>
      <div className="container overflow-auto pt-4 mt-5">
        {isMultiPackage
          ? parcels && (
              <MultiPackageInvoiceContent parcels={parcels} invoiceRef={componentRef} />
            )
          : parcel && <InvoiceContent parcel={parcel} invoiceRef={componentRef} />}
      </div>
      <ReactToPrint
        trigger={() => (
          <div className="col-12 d-flex justify-content-center">
            <button className="btn btn-soft-dark mt-6  mb-6" type="button">
              Imprimer la facture ↓ <i className="fa fa-print" />
            </button>
          </div>
        )}
        content={() => componentRef.current}
      />
    </>
  );
};

export default Invoice;
