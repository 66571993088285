import React, { useState } from 'react';
import { getPaymentHistory } from 'client/_redux/actions/user/expiditeur';
import DataTable from 'client/containers/DataTableContainer';
import { useSelector } from 'react-redux';
import { RootState } from 'client/_redux/reducers';
import { useTableColumnsWithActions } from 'client/containers/DataTableContainer/useTableColumnsWithActions';
import { TableColumn } from 'react-data-table-component';
import { IPaymentHistory } from 'server/models/PaymentHistory/types';
import { format } from 'date-fns';
import Modal from 'client/components/Modal';
import PaymentInvoice from 'client/components/Invoice/PaymentInvoice';
import ExpandedRows from './ExpandedRows';

type BalanceProps = {
  isAdmin?: boolean;
};
const Balance: React.FC<BalanceProps> = ({ isAdmin }) => {
  const { paymentHistory } = useSelector(({ user }: RootState) => user);

  const [printModal, setPrintModal] = useState(false);
  const [itemToPrint, setItemToPrint] = useState<IPaymentHistory>();
  const ExpeditorPackageTableColumns = (): TableColumn<IPaymentHistory>[] => [
    {
      name: 'Nombre des colis livrée',
      selector: (e) => `${e.packages.length}`,
      center: true,
    },
    {
      name: 'Expeditor',
      selector: (e) => `${e.expeditor.lastName} ${e.expeditor.firstName}`,
      center: true,
    },
    {
      name: 'Nombre des colis retuer',
      selector: (e) => `${e.returnedPackages.length}`,
      center: true,
    },
    {
      name: 'Montant recue',
      selector: (e) => `${e.paymentAmount} DT`,
      center: true,
    },
    {
      name: 'Frais de livraison',
      selector: (e) => `${e.deliveryFee} DT`,
      center: true,
    },
    {
      name: 'Frais de retuer',
      selector: (e) => `${e.returnedPackagesFee} DT`,
      center: true,
    },
    {
      name: 'Total',
      selector: (e) => `${e.total} DT`,
      center: true,
    },
    {
      name: 'date de payment',
      cell: (e) => e.createdAt && format(new Date(e.createdAt), 'MM/dd/yyyy HH:mm'),
      center: true,
    },
  ];
  const columns = useTableColumnsWithActions({
    columns: ExpeditorPackageTableColumns(),
    buttons: isAdmin
      ? [
          {
            className: () => 'fas fa-print cursor-pointer',
            fn: (item) => {
              setItemToPrint(item);
              setPrintModal(true);
            },
            color: 'black',
          },
        ]
      : [],
  });

  return (
    <div className="d-flex justify-content-around flex-wrap">
      <DataTable
        data={paymentHistory}
        title="historique de payment"
        columns={columns}
        fetchDataFn={getPaymentHistory}
        ExpandedComponent={({ data }) => <ExpandedRows data={data} />}
        expandableRows
        expandableIcon={{
          collapsed: <i className="fas fa-chevron-circle-down m-auto" />,
          expanded: <i className="fas fa-chevron-circle-up m-auto" />,
        }}
      />
      <Modal
        isOpen={printModal}
        closeModal={() => setPrintModal(false)}
        modalHead="Facture de payment"
      >
        {itemToPrint && <PaymentInvoice paymentInvoice={itemToPrint} />}
      </Modal>
    </div>
  );
};

export default Balance;
