import { PackageFormType } from 'types/forms/PackageFormType';
import { useDispatch, useSelector } from 'react-redux';
import {
  createPackages,
  getExpeditorClients,
  updatePackage,
} from 'client/_redux/actions/packages';
import { PackageStateEnum } from 'types/Enums/PackageStateEnum';
import { useForm } from 'react-hook-form';
import { IUserPackageDocument } from 'server/models/Package/types';
import { useLocation } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { RootState } from 'client/_redux/reducers';
import { useNewAddress } from 'client/hooks/NewAddressHoock/useNewAddress';

export const useFormPackage = (
  setIsUpdateModalVisible: (value: React.SetStateAction<boolean>) => void,
  isUpdate?: boolean,
  itemToUpdateOrDelete?: IUserPackageDocument,
  isFastPackage?: boolean,
) => {
  const formMethods = useForm<PackageFormType>({
    shouldFocusError: true,
    defaultValues: {
      price: itemToUpdateOrDelete?.price,
      articleName: itemToUpdateOrDelete?.articleName,
      client: itemToUpdateOrDelete?.client,
      articlesNumber: itemToUpdateOrDelete?.articlesNumber,
      destination: itemToUpdateOrDelete?.destination,
      isDelicate: itemToUpdateOrDelete?.isDelicate,
      exchangePackage: itemToUpdateOrDelete?.exchangePackage,
    },
  });
  const dispatch = useDispatch();
  const { handleSubmit, setValue, watch } = formMethods;
  const { pathname } = useLocation();
  const [selectedState, setSelectedState] = useState('');
  const { cityOptions, stateOptions } = useNewAddress(selectedState);

  useEffect(() => {
    const state = watch('destination.state');

    if (state) setSelectedState(state as string);
  }, [watch]);
  useEffect(() => {
    !isUpdate && dispatch(getExpeditorClients());
  }, [dispatch, isUpdate]);

  const expeditorClients = useSelector(
    ({ packages: { expeditorClients } }: RootState) => expeditorClients,
  );

  const uniqueExpeditorClients = expeditorClients?.filter(
    (v, i, a) =>
      a.findIndex((t) => t.name === v.name && t.phoneNumber === v.phoneNumber) === i,
  );

  const options = uniqueExpeditorClients?.map((client) => ({
    label: client.name,
    value: client.phoneNumber,
  }));
  const handleClientSelect = useCallback(
    (selectedOption) => {
      const client = uniqueExpeditorClients?.find(
        (client) => client.phoneNumber === selectedOption,
      );

      setSelectedState(client?.destination?.state);
      setValue('client', { name: client?.name, clientPhoneNumber: client?.phoneNumber });
      setValue('destination', {
        city: client?.destination?.city,
        address: client?.destination?.address,
        state: client?.destination?.state,
      });
    },
    [setValue, uniqueExpeditorClients],
  );
  const onSubmit = (values: PackageFormType) => {
    dispatch(
      isUpdate
        ? updatePackage(
            {
              _id: itemToUpdateOrDelete?._id,
              ...values,
            },
            pathname as '/pickup' | '/mes-colis',
          )
        : createPackages(
            {
              ...values,
              isDelicate: values.isDelicate ? values.isDelicate : false,
              exchangePackage: values.exchangePackage ? values.exchangePackage : false,
            },
            isFastPackage,
          ),
    );
    setIsUpdateModalVisible(false);
    formMethods.reset();
  };

  const availablePackageStateToUpdate = [PackageStateEnum.PACKAGE_CREATED];

  return {
    availablePackageStateToUpdate,
    handleSubmit,
    onSubmit,
    formMethods,
    options,
    handleClientSelect,
    cityOptions,
    stateOptions,
  };
};
