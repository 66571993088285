import { format } from 'date-fns';
import { TableColumn } from 'react-data-table-component';
import { IAddress } from 'server/models/Address/types';
import { IRunsheetDocument } from 'server/models/Runsheet/types';

export const manifestColumns = (): TableColumn<IRunsheetDocument>[] => [
  {
    name: 'code',
    selector: (e) => `${e.codeRunsheet}`,
    center: true,
    sortable: true,
    sortField: 'code',
    style: {
      fontWeight: 'bold',
    },
  },
  {
    name: 'Nom de lexpéditeur',
    selector: (e) => `${e?.expeditor?.firstName} ${e?.expeditor?.lastName}`,
    center: true,
    sortable: true,
  },
  {
    name: 'Telephone',
    selector: (e) => `${e?.expeditor?.phoneNumber}`,
    center: true,
  },
  {
    name: 'Address',
    selector: (e) =>
      `${(e?.expeditor?.address as IAddress)?.city} ${
        (e?.expeditor?.address as IAddress)?.state
      } ${(e?.expeditor?.address as IAddress)?.address}`,
    center: true,
  },

  {
    name: 'Nombre de colis',
    selector: (e) => e.packagesList.length,
    center: true,
    sortable: true,
    sortField: 'packageList',
    style: {
      fontWeight: 'bold',
    },
  },
  {
    sortField: 'createdAt',
    name: 'Date de creation',
    cell: (e) => e.createdAt && format(new Date(e.createdAt), 'MM/dd/yyyy HH:mm'),
    sortable: true,
    center: true,
  },
];
