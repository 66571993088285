import { SelectorStyleProperties } from 'client/containers/NavBar';
import { navBarLinksProperties } from 'client/containers/NavBar/navBarLinks';
import { useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export const useNavBarItem = (
  setSelectorStyle: React.Dispatch<React.SetStateAction<SelectorStyleProperties>>,
  link: navBarLinksProperties,
  width: number,
) => {
  const ref = useRef<HTMLLIElement>(null);
  const { pathname } = useLocation();

  const topOffset = useMemo(() => {
    switch (link.title) {
      case 'Accueil':
        return 0;
      case 'Pickup':
      case 'Utilisateurs':
        return 48;
      case 'Mes colis':
      case 'Colis':
        return 96;
      case 'Runsheet':
      case 'Mon adresse':
        return 144;
      case 'Accepter magasin':
      case 'Payment':
        return 192;
      case 'Retour expéditeur':
        return 240;
      case 'Balance':
        return 186;
      default:
        return 0;
    }
  }, [link.title]);

  useEffect(() => {
    pathname.includes(link.title.toLocaleLowerCase().replaceAll(' ', '-')) &&
      setSelectorStyle({
        top: width > 991 ? 2 : topOffset,
        height: width > 991 ? 62.5 : 46.5,
        left: width > 991 ? ref?.current?.offsetLeft || 1 : 0,
        width: ref?.current?.offsetWidth || width,
      });
  }, [link.title, pathname, setSelectorStyle, topOffset, width]);

  return {
    ref,
    pathname,
  };
};
