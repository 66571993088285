import React from 'react';

type IProps = {
  title?: string;
  onAddButtonClick?: () => void;
};
const DataTableHeaderContainer = ({ title, onAddButtonClick }: IProps) => (
  <div className="d-flex justify-content-between align-items-center">
    <div
      style={{
        fontSize: '40px',
        minHeight: '56px',
      }}
    >
      {title}
    </div>
    {onAddButtonClick && (
      <button onClick={onAddButtonClick} type="button" className="btn btn-primary btn-sm">
        <i className="fas fa-plus" /> Ajouter
      </button>
    )}
  </div>
);

export default DataTableHeaderContainer;
