import { SubmitHandler, useForm } from 'react-hook-form';
import { UserFormProps } from '.';
export type ResetPasswordFormValues = {
  password: string;
  newPassword: string;
  confirmPassword: string;
};
export const usePasswordForm = ({ onSubmit }: UserFormProps) => {
  const formMethods = useForm<ResetPasswordFormValues>({
    shouldFocusError: true,
  });
  const _onSubmit: SubmitHandler<ResetPasswordFormValues> = (data) => {
    onSubmit(data);
  };

  return {
    _onSubmit,
    handleSubmit: formMethods.handleSubmit,
    formMethods,
  };
};
