import Invoice from 'client/components/Invoice';
import Modal from 'client/components/Modal';
import ModalFooter from 'client/components/Modal/ModalFooter';
import DataTable from 'client/containers/DataTableContainer';
import PackageForm from 'client/containers/PackageForm';
import { getPickupPackages } from 'client/_redux/actions/packages';
import * as React from 'react';
import { IUserPackageDocument } from 'server/models/Package/types';
import PackageFilter from './PackageFilter';
import { usePickup } from './usePickup';

const Pickup: React.FC = () => {
  const {
    columns,
    dataTableRef,
    expeditorPackages,
    isUpdateModalVisible,
    isDeleteModalVisible,
    handleCloseModal,
    setIsDeleteModalVisible,
    onDelete,
    itemToUpdateOrDelete,
    addPackageModal,
    setAddPackageModal,
    isPrintModalVisible,
    handleClosePrintModal,
  } = usePickup();
  const [selectedRows, setSelectedRows] = React.useState<IUserPackageDocument[]>([]);

  const handleSelectedRows = React.useCallback((rows) => {
    setSelectedRows(rows.selectedRows);
  }, []);

  return (
    <div className="d-flex justify-content-around flex-wrap">
      <DataTable
        selectableRows
        myRef={dataTableRef}
        data={expeditorPackages}
        title="Mes colis"
        columns={columns}
        fetchDataFn={getPickupPackages}
        handleRowSelected={handleSelectedRows}
        subHeaderComponent={<PackageFilter selectedRows={selectedRows} isPickup />}
        onAddButtonClick={() => setAddPackageModal(true)}
      />
      <Modal
        isOpen={addPackageModal}
        closeModal={() => setAddPackageModal(false)}
        modalHead="Ajouter des nouvelles colis"
      >
        <PackageForm
          setIsUpdateModalVisible={setAddPackageModal}
          setAddPackageModal={setAddPackageModal}
        />
      </Modal>
      {itemToUpdateOrDelete && (
        <>
          <Modal
            isOpen={isUpdateModalVisible}
            closeModal={handleCloseModal}
            modalHead="Modifier votre colis"
          >
            <PackageForm
              isUpdate
              itemToUpdateOrDelete={itemToUpdateOrDelete}
              setIsUpdateModalVisible={handleCloseModal}
            />
          </Modal>
          <Modal
            isOpen={isPrintModalVisible}
            closeModal={handleClosePrintModal}
            modalHead="Imprimer votre colis"
          >
            <Invoice parcel={itemToUpdateOrDelete} />
          </Modal>
        </>
      )}

      <Modal
        childrenClassName="py-4"
        isOpen={isDeleteModalVisible}
        closeModal={() => setIsDeleteModalVisible(false)}
        modalHead="Supprimer votre colis"
        footer={ModalFooter({
          handleSubmitModal: onDelete,
          cancelModal: () => setIsDeleteModalVisible(false),
          modalButtonText: 'Confirmer',
        })}
      >
        <span>
          êtes-vous sûr de supprimer le colis de {itemToUpdateOrDelete?.client?.name}
        </span>
      </Modal>
    </div>
  );
};

export default Pickup;
