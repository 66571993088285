export enum RunsheetTypes {
  FETCH_RUNSHEET_SUCCESS = 'FETCH_RUNSHEET_SUCCESS',
  FETCH_RUNSHEET_FAILURE = 'FETCH_RUNSHEET_FAILURE',
  FETCH_DRIVERS_SUCCESS = 'FETCH_DRIVERS_SUCCESS',
  FETCH_DRIVERS_FAILURE = 'FETCH_DRIVERS_FAILURE',
  CREATE_RUNSHEET_SUCCESS = 'CREATE_RUNSHEET_SUCCESS',
  CREATE_MANIFEST_SUCCESS = 'CREATE_MANIFEST_SUCCESS',
  CREATE_RUNSHEET_FAILURE = 'CREATE_RUNSHEET_FAILURE',
  CREATE_MANIFEST_FAILURE = 'CREATE_MANIFEST_FAILURE',
}
