import React, { useState } from 'react';
import { IRunsheetDocument } from 'server/models/Runsheet/types';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTableColumnsWithActions } from 'client/containers/DataTableContainer/useTableColumnsWithActions';
import { IUserPackage, IUserPackageDocument } from 'server/models/Package/types';
import { format } from 'date-fns';
import Modal from 'client/components/Modal';
import Invoice from 'client/components/Invoice';
import BarCode from 'react-barcode';

export interface PackagesRowsProps {
  data: IRunsheetDocument;
}
const PackagesRows: React.FC<PackagesRowsProps> = ({ data }) => {
  const [isPrintModalVisible, setIsPrintModalVisible] = useState<boolean>(false);
  const [itemToPrint, setItemToPrint] = useState<IUserPackage>();
  const { packagesList, driver } = data;

  const PackagesTableColumns = (): TableColumn<IUserPackage>[] => [
    {
      name: 'code a bare',
      cell: (e: IUserPackage) => <BarCode width={1} height={40} value={e.barCode} />,
      center: true,
    },
    {
      name: 'Nom du client',
      selector: (e: IUserPackage) => `${e.client.name}`,
      center: true,
    },
    {
      name: 'Etat',
      selector: (e: IUserPackage) => `${e.state}`,
      center: true,
    },
    {
      name: 'Echange',
      selector: (e: IUserPackage) => `${e.exchangePackage ? 'Oui' : 'Non'}`,
      center: true,
    },
    {
      name: 'Cuz',
      selector: (e: IUserPackage) => `${e.returnCuz ? e.returnCuz : '---'}`,
      center: true,
    },
    {
      name: 'Expediteur',
      selector: (e: IUserPackage) => `${e.expeditor.firstName}`,
      center: true,
    },
    {
      name: <i className="fa fa-phone" />,
      selector: (e: IUserPackage) =>
        `${
          e.deliveringDetails.filter((detail) => detail.transporter === driver?._id).length
        }/${e.deliveringDetails.length}`,
      center: true,
    },
    {
      sortField: 'createdAt',
      name: 'Date de creation',
      cell: (e: IUserPackage) =>
        e.createdAt && format(new Date(e.createdAt), 'MM/dd/yyyy HH:mm'),
      sortable: true,
      center: true,
    },
  ];
  const columns = useTableColumnsWithActions({
    columns: PackagesTableColumns(),
    buttons: [
      {
        className: () => `fa fa-print`,
        // eslint-disable-next-line no-console
        fn: (item) => {
          setItemToPrint(item);
          setIsPrintModalVisible(true);
        },
        color: 'black',
      },
    ],
  });
  const handleClosePrintModal = () => {
    setIsPrintModalVisible(false);
    setItemToPrint(undefined);
  };

  return (
    <div className="container my-5">
      <div className="row d-flex justify-content-center">
        <DataTable data={packagesList as IUserPackage[]} columns={columns} />
      </div>
      <Modal
        isOpen={isPrintModalVisible}
        closeModal={handleClosePrintModal}
        modalHead="Imprimer votre colis"
      >
        <Invoice parcel={itemToPrint as IUserPackageDocument} />
      </Modal>
    </div>
  );
};

export default PackagesRows;
