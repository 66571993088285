import { PackagesExpTypes } from 'client/_redux/types/packagesExpTypes';
import { emptyPaginationPayload } from 'server/constants/emptyPaginationPayload';
import { IUserPackageDocument } from 'server/models/Package/types';
import { PaginatedResponse } from 'types/IPaginationResponse';
import { IReduxAction } from 'types/IReduxAction';
import { clientObject, statsByDayObject, statsObject } from 'types/PackageExternalTypes';
export interface IPackagesState {
  loading: boolean;
  loaded: boolean;
  expeditorPackages: PaginatedResponse<IUserPackageDocument>;
  selectedExpeditorPackage?: IUserPackageDocument;
  expeditorStats?: statsObject[];
  expeditorStatsByDay: statsByDayObject[];
  expeditorClients?: clientObject[];
  scannedPackages: IUserPackageDocument[];
  scannedRunsheetPackages: IUserPackageDocument[];
  scannedManifestPackages: IUserPackageDocument[];
  scannedReturnedPackages: IUserPackageDocument[];
}

const initialState: IPackagesState = {
  loading: false,
  loaded: false,
  expeditorPackages: emptyPaginationPayload,
  scannedPackages: [],
  scannedRunsheetPackages: [],
  scannedManifestPackages: [],
  scannedReturnedPackages: [],
  expeditorStatsByDay: [],
};

export default (state = initialState, { type, payload }: IReduxAction): IPackagesState => {
  switch (type) {
    case PackagesExpTypes.FETCH_PACKAGES_SUCCESS:
      return { ...state, loaded: true, expeditorPackages: payload };
    case PackagesExpTypes.FETCH_EXPEDITOR_STATS_SUCCESS:
      return { ...state, loaded: true, expeditorStats: payload };
    case PackagesExpTypes.FETCH_EXPEDITOR_STATS_BY_DAY_SUCCESS:
      return { ...state, loaded: true, expeditorStatsByDay: payload };
    case PackagesExpTypes.FETCH_EXPEDITOR_CLIENTS:
      return { ...state, loaded: true, expeditorClients: payload };
    case PackagesExpTypes.CLEAR_SCANNED_PACKAGES_LIST:
      return { ...state, loaded: true, scannedRunsheetPackages: [] };
    case PackagesExpTypes.CLEAR_ACCEPTED_PACKAGES_LIST:
      return { ...state, loaded: true, scannedPackages: [] };
    case PackagesExpTypes.ADD_TO_PACKAGES_LIST:
      return { ...state, loaded: true, scannedPackages: payload };
    case PackagesExpTypes.ADD_TO_RUNSHEET_PACKAGES_LIST:
      return { ...state, loaded: true, scannedRunsheetPackages: payload };
    case PackagesExpTypes.ADD_TO_MANIFEST_PACKAGES_LIST:
      return { ...state, loaded: true, scannedManifestPackages: payload };
    case PackagesExpTypes.CLEAR_MANIFEST_PACKAGES_LIST:
      return { ...state, loaded: true, scannedManifestPackages: [] };

    default:
      return state;
  }
};
