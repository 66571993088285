/* eslint-disable react-hooks/exhaustive-deps */
import 'react-dates/initialize';
import React, { useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { SingleDatePicker } from 'react-dates';
export interface IProps {
  value: Date;
  name: string;
  label?: string;
  withFullScreenPortal?: boolean;
  errors?: FieldError;

  placeholder?: string;

  onChange: (value: unknown) => void;
}
const DatePicker: React.FC<IProps> = ({
  name,
  label,
  errors,
  value: initialValue,
  onChange,
  placeholder,
  withFullScreenPortal = false,
}) => {
  const [date, setDate] = useState<Date | null>(initialValue || new Date());

  useEffect(() => {
    onChange(date);

    return () => onChange(undefined);
  }, []);

  const handleDateChange = (newDate: Date | null) => {
    setDate(newDate);
    onChange(newDate);
  };
  const isOutsideRange = () => false;
  const id = name;
  const [isActive, setIsActive] = useState(false);

  return (
    <div className={`form-group ${errors ? 'is-invalid' : ''}`}>
      <label className="form-label" htmlFor={id}>
        <span className="d-flex justify-content-between align-items-center">{label}</span>
      </label>

      <SingleDatePicker
        placeholder={placeholder}
        date={date}
        onDateChange={handleDateChange}
        focused={isActive}
        onFocusChange={({ focused }) => setIsActive(focused)}
        withFullScreenPortal={withFullScreenPortal}
        numberOfMonths={1}
        hideKeyboardShortcutsPanel
        isOutsideRange={isOutsideRange}
        customCloseIcon={<div>DONE</div>}
        id={id}
      />
      {errors && (
        <div className="invalid-feedback" style={{ display: 'block' }}>
          {errors.message}
        </div>
      )}
    </div>
  );
};

export default DatePicker;
