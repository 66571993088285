/* eslint-disable react/no-unescaped-entities */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { format } from 'date-fns';
import React from 'react';
import BarCode from 'react-barcode';
import { IUserPackageDocument } from 'server/models/Package/types';

type SingleParcelContentProps = {
  parcel: IUserPackageDocument;
};
const SingleParcelContent: React.FC<SingleParcelContentProps> = ({ parcel }) => (
  <>
    <span className="h4">Informations sur le client</span>
    <div className="table2-container mt-2 rounded d-flex justify-content-center">
      <table className="table table-bordered" style={{ border: 'solid' }}>
        <thead>
          <tr style={{ textAlign: 'center' }}>
            <th>Nom de destinataire</th>
            <th>Adresse</th>
            <th>Telephone</th>
          </tr>
        </thead>
        <tbody>
          <tr style={{ textAlign: 'center' }}>
            <td className="h3">{parcel?.client?.name}</td>
            <td className="h3">
              {parcel?.destination?.city},{parcel?.destination?.state},
              {parcel?.destination?.address}
            </td>
            <td className="h3">{parcel?.client?.clientPhoneNumber}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <span className="h4">Informations sur le colis</span>
    <div className="table2-container mt-2 rounded d-flex justify-content-center">
      <table className="table table-bordered" style={{ border: 'solid' }}>
        <thead>
          <tr style={{ textAlign: 'center' }}>
            <th>Code a barre</th>
            <th>Nom d'article</th>
            <th>Quantité</th>
            <th>Montant total HT</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          <tr style={{ textAlign: 'center' }}>
            <BarCode width={2} height={40} value={parcel.barCode} />
            <td className="h3">{parcel?.articleName}</td>
            <td className="h3">{parcel?.articlesNumber}</td>
            <td className="h3">{parcel?.price} DT</td>
            <td className="h3">{format(new Date(parcel?.createdAt), 'dd-MM-yyyy')}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </>
);

export default SingleParcelContent;
