export enum AdminUserTypes {
  SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS',
  RESET_USER = 'RESET_USER',
  USER_LOGGED_OUT = 'USER_LOGGED_OUT',
  GET_USERS_SUCCESS = 'GET_USERS_SUCCESS',
  GET_USERS_FAILURE = 'GET_USERS_FAILURE',
  DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE = 'DELETE_USER_FAILURE',
  BAN_USER_SUCCESS = 'BAN_USER_SUCCESS',
  BAN_USER_FAILURE = 'BAN_USER_FAILURE',
  ADD_USER_SUCCESS = 'ADD_USER_SUCCESS',
  ADD_USER_FAILURE = 'ADD_USER_FAILURE',
  UPDATE_USER_SUCCES = 'UPDATE_USER_SUCCES',
  UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE',
  GET_EXPEDITORS_SUCCESS = 'GET_EXPEDITORS_SUCCESS',
  GET_EXPEDITORS_FAILURE = 'GET_EXPEDITORS_FAILURE',
}
